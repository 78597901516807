import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import useApi from '../../../common/useApi'

const Mcq = ( {
  question,
  isPreview,
  survey_response,
  handleAnswerSubmitted,
} ) => {
  const [ selected, setSelected ] = useState( null )

  const { mutate, isSuccess } = useApi( 'post', '/api/respondent/choice-answer' )

  useEffect(
    () => {
      if ( !!selected ) {
        mutate( {
          choice: selected,
          question: question.uid,
          survey_response,
        } )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ selected ],
  )

  useEffect( () => {
    if ( isSuccess ) {
      handleAnswerSubmitted( question.uid )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isSuccess ] )

  return (
    <>
      {question.choices.map( ( choice ) => (
        <label key={ choice.uid } className='radio-btn-container'>
          {choice.label}
          <input
            type='radio'
            name={ `radio-${ question.uid }` }
            disabled={ isPreview }
            value={ choice.uid }
            onChange={ ( e ) => setSelected( e.target.value ) }
            checked={ selected === choice.uid }
          />
          <span
            className={ classNames( {
              checkmark: true,
              disabled: isPreview,
            } ) }
          ></span>
        </label>
      ) )}
    </>
  )
}

export default Mcq

import { Switch, Route, useRouteMatch } from 'react-router-dom'

import ContactAdd from './ContactsTab/ContactAdd'
import EmailManagerTabs from './EmailManagerTabs'
import ContactAddForm from './ContactsTab/ContactAdd/ContactForms/ContactAddForm'
import ContactImport from './ContactsTab/ContactImport'

const EmailManager = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={ `${ match.path }/add` }>
        <ContactAdd />
      </Route>
      <Route path={ `${ match.path }/add-manual` }>
        <ContactAddForm />
      </Route>
      <Route path={ `${ match.path }/import-csv` }>
        <ContactImport />
      </Route>
      <Route path={ match.path }>
        <EmailManagerTabs />
      </Route>
    </Switch>
  )
}

export default EmailManager

import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import './Password.scss'

import Icon from '../Icon'
import {
  ShowIcon as ShowPasswordIcon,
  HideIcon as HidePasswordIcon,
} from '../../assets/icons'
import FormLabel from '../Forms/Helpers/FormLabel/FormLabel'
import classNames from 'classnames'

const Password = ( {
  placeholder = 'Password',
  field = 'Password',
  name = 'password',
  isRequired = true,
} ) => {
  const { register, formState } = useFormContext()

  const [ showPassword, setShowPassword ] = useState( false )

  const toggle = () => {
    setShowPassword( !showPassword )
  }

  return (
    <FormLabel name={ name } field={ field } required={ isRequired }>
      <div className='password-field-container'>
        <input
          className={ classNames( {
            error: formState?.errors[name],
            'full-width': true,
          } ) }
          { ...register( name, { required: isRequired && 'This field is required' } ) }
          placeholder={ placeholder }
          type={ !showPassword ? 'password' : 'text' }
        />
        {!showPassword ? (
          <Icon
            className='right-inline-icon size-1'
            IconComponent={ ShowPasswordIcon }
            onClick={ toggle }
            fill='grey'
          />
        ) : (
          <Icon
            className='right-inline-icon size-1'
            IconComponent={ HidePasswordIcon }
            onClick={ toggle }
            fill='grey'
          />
        )}
      </div>
    </FormLabel>
  )
}

export default Password

import React, { useEffect, useState } from 'react'

import './Share.scss'

import SelectOption from './SelectOption'
import ShareByEmail from './ShareByEmail/ShareByEmail'
import useApi from '../../../../common/useApi'
import { useParams } from 'react-router'

const Share = () => {
  const { uid } = useParams()

  const [ isPublished, setIsPublished ] = useState( false )

  // have to check if the survey is published or not
  const { data: survey, isSuccess } = useApi( 'get', `/api/survey/${ uid }` )

  useEffect( () => {
    if ( isSuccess ) {
      setIsPublished( survey.is_published )
    }
  }, [ isSuccess, survey ] )

  const [ currentComponent, setCurrentComponent ] = useState( 'SelectOption' )

  if ( !isPublished ) {
    return (
      <h3 className='share-survey-container centered-text'>
        Survey has to be published first to start sharing
      </h3>
    )
  }

  const getComponent = () => {
    if ( 'SelectOption' === currentComponent ) {
      return (
        <SelectOption goToNext={ () => setCurrentComponent( 'ShareByEmail' ) } />
      )
    }
    if ( 'ShareByEmail' === currentComponent ) {
      return (
        <ShareByEmail goToNext={ () => setCurrentComponent( 'ShareByEmail' ) } />
      )
    }
  }

  return <div className='share-survey-container'>{getComponent()}</div>
}

export default Share

import React, { useEffect, useState } from 'react'

import useApi from '../../../common/useApi'
import useDebounce from '../../../common/useDebounce/useDebounce'

const Oneliner = ( {
  question,
  isPreview,
  survey_response,
  handleAnswerSubmitted,
} ) => {
  const [ answer, setAnswer ] = useState( '' )

  const { mutate, isSuccess } = useApi(
    'post',
    '/api/respondent/oneliner-answer',
  )

  const debouncedSave = useDebounce( mutate )

  useEffect(
    () => {
      if ( !!answer ) {
        debouncedSave( {
          answer,
          question: question.uid,
          survey_response,
        } )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ answer ],
  )

  useEffect( () => {
    if ( isSuccess ) {
      handleAnswerSubmitted( question.uid )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isSuccess ] )

  return (
    <input
      className='full-width'
      type='text'
      value={ answer }
      onChange={ ( e ) => setAnswer( e.target.value ) }
      placeholder='Type Here'
      disabled={ isPreview }
    />
  )
}

export default Oneliner

import React, { useContext, useEffect, useState } from 'react'

import UiContext from '../../../../../common/UiContext'
import useApi from '../../../../../common/useApi'
import FormLabel from '../../../../../components/Forms/Helpers/FormLabel/FormLabel'
import MultipleChoiceFilterFromApi from '../../../../../components/MultipleChoiceFilter/MultipleChoiceFilterFromApi'

const ShareBySegments = ( { survey_uid } ) => {
  const { setToast, closeModal } = useContext( UiContext )

  const { mutate, isLoading, isSuccess } = useApi( 'post', '/api/survey/invite' )

  const [ selectedSegments, setSelectedSegments ] = useState( '' )

  useEffect( () => {
    if ( isSuccess ) {
      setToast( {
        message: 'Survey shared with the segments',
        type: 'info',
      } )
      closeModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isSuccess ] )

  const share = () => {
    mutate( {
      segment_uids: selectedSegments?.split( ',' ) ?? [],
      survey_uid,
    } )
  }

  return (
    <>
      <h4>Choose a Segment</h4>

      <FormLabel name='segments' field='Segments'>
        <MultipleChoiceFilterFromApi
          endpoint='/api/respondent/segment'
          selected={ selectedSegments }
          setChoice={ ( val ) => setSelectedSegments( val ) }
          placeholder='Segments'
          labelFields={ [ 'name' ] }
        />
      </FormLabel>

      <button
        className='primary full-width'
        onClick={ share }
        disabled={ isLoading }
      >
        Share
      </button>
    </>
  )
}

export default ShareBySegments

import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router'

import AuthContext from '../../../common/auth'
import UiContext from '../../../common/UiContext'
import Form from '../../Forms/Form/Form'
import LoginForm from '../../Forms/LoginForm'

const Login = ( { goToSuccessPage, goToForgotPassword, goToSignUp } ) => {
  const history = useHistory()
  const { setToast } = useContext( UiContext )
  const { setUserData } = useContext( AuthContext )

  const onSuccess = ( responseData ) => {
    setUserData( responseData )
    goToSuccessPage()
  }

  useEffect( () => {
    history.push( '/login' )
  }, [ history ] )

  const handleLoginError = ( error ) => {
    try {
      if ( 403 === error.status ) {
        setToast( {
          message: error.data.message,
          type: 'error',
        } )
      }
      if ( 400 === error.status ) {
        setToast( {
          message: error.data?.non_field_errors[0] ?? 'An error occurred',
          type: 'error',
        } )
      }
    } catch ( error ) {
      setToast( {
        message: 'An error occurred',
        type: 'error',
      } )
    }
  }

  return (
    <Form
      method='post'
      endpoint='/api/auth/login'
      onSuccess={ onSuccess }
      onError={ handleLoginError }
      FormBody={ ( props ) =>
        LoginForm( {
          ...props,
          goToForgotPassword,
          goToSignUp,
          goToSuccessPage,
        } )
      }
    />
  )
}

export default Login

import React from 'react'

const ForgotPasswordSuccess = ( { goToLogin } ) => (
  <section>
    <h1>Check your email</h1>
    <h5 className='subtle'>
      We have send you instructions to reset your password.
    </h5>

    <button className='tertiary' onClick={ goToLogin }>
      Back To Login
    </button>
  </section>
)

export default ForgotPasswordSuccess

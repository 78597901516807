import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form'

import AuthContext from '../../common/auth'
import Checkbox from '../Checkbox/Checkbox'
import Password from '../Password'
import FormLabel from './Helpers/FormLabel/FormLabel'

const LoginForm = ( { goToForgotPassword, isLoading, goToSignUp } ) => {
  const { register, formState } = useFormContext()

  const { setRememberMe, rememberMe } = useContext( AuthContext )

  return (
    <>
      <h1>Welcome Back!</h1>
      <h5 className='subtle'>Enter your credentials to access your account.</h5>

      <FormLabel
        name='email'
        field='Email Address'
        error={ formState.errors?.email }
        required
      >
        <input
          type='email'
          { ...register( 'email', { required: 'This field is required' } ) }
          placeholder='Email Address'
          className={ formState.errors?.email && 'error' }
        />
      </FormLabel>

      <Password />

      <div className='row'>
        <Checkbox
          name='remember_me'
          checked={ rememberMe }
          onChange={ ( e ) => setRememberMe( e.target.checked ) }
          label='Remember Me'
        />

        <button className='tertiary' onClick={ goToForgotPassword } type='button'>
          Forgot Password ?
        </button>
      </div>

      <button className='primary' disabled={ isLoading || !formState.isValid }>
        Login
      </button>

      <button className='tertiary' onClick={ goToSignUp } type='button'>
        Don't have an account? Sign Up
      </button>
    </>
  )
}

export default LoginForm

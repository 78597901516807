import { useFormContext } from 'react-hook-form'
import { useLocation } from 'react-router'
import Password from '../Password'

const ChangePasswordForm = ( { goToLogin, isLoading } ) => {
  const location = useLocation()

  const { setValue, register, getValues, formState } = useFormContext()

  const queryParams = new URLSearchParams( location.search )

  setValue( 'token', queryParams.get( 'token' ) ?? '' )
  setValue( 'uid', queryParams.get( 'uid' ) ?? '' )

  return (
    <>
      <h1>Create New Password</h1>
      <h5 className='subtle'>
        Your new password must be different from previous used passwords.
      </h5>

      <Password
        placeholder='New Password'
        field='New Password'
        { ...register( 'new_password1', { required: 'This field is required' } ) }
      />
      <Password
        placeholder='Confirm Password'
        field='Confirm Password'
        { ...register( 'new_password2', {
          required: 'This field is required',
          validate: {
            same: ( val ) =>
              val === getValues( 'new_password1' ) || "Passwords doesn't match",
          },
        } ) }
      />

      <button className='primary' disabled={ isLoading || !formState.isValid }>
        Submit
      </button>

      <button type='button' className='tertiary' onClick={ goToLogin }>
        Back To Login
      </button>
    </>
  )
}

export default ChangePasswordForm

import React, { useContext, useState } from 'react'
import DatePicker from 'react-datepicker'

import countries from '../../../common/Formatters/countries'
import states from '../../../common/Formatters/states'
import UiContext from '../../../common/UiContext'
import FormLabel from '../../../components/Forms/Helpers/FormLabel/FormLabel'
import MultipleChoiceFilterFromApi from '../../../components/MultipleChoiceFilter/MultipleChoiceFilterFromApi'

const ContactFilters = ( { apply, filters, resetFilters } ) => {
  const { filterModal: { closeFilterModal } } = useContext( UiContext )

  const [ selectedSegments, setSelectedSegments ] = useState(
    filters.segments__uid__in ?? '',
  )
  const [ selectedSectors, setSelectedSectors ] = useState(
    filters.sectors__uid__in ?? '',
  )
  const [ dateRange, setDateRange ] = useState( [
    filters?.created__gte ?? null,
    filters?.created__lte ?? null,
  ] )
  const [ startDate, endDate ] = dateRange
  const [ city, setCity ] = useState( filters.city__iexact ?? null )
  const [ state, setState ] = useState( filters.state__iexact ?? null )
  const [ country, setCountry ] = useState( filters.country__iexact ?? null )

  const handleClick = () => {
    apply( {
      ...( '' !== selectedSegments
        ? { segments__uid__in: selectedSegments }
        : {} ),
      ...( '' !== selectedSectors ? { sectors__uid__in: selectedSectors } : {} ),
      ...( null !== startDate ? { created__gte: startDate } : {} ),
      ...( null !== endDate ? { created__lte: endDate } : {} ),
      ...( null !== city ? { city__iexact: city } : {} ),
      ...( null !== state ? { state__iexact: state } : {} ),
      ...( null !== country ? { country__iexact: country } : {} ),
    } )
    closeFilterModal()
  }

  const handleReset = () => {
    resetFilters()
    closeFilterModal()
  }

  return (
    <>
      <div className='filters'>
        <FormLabel name='segment' field='Choose Segments'>
          <MultipleChoiceFilterFromApi
            endpoint='/api/respondent/segment'
            selected={ selectedSegments }
            setChoice={ ( val ) => setSelectedSegments( val ) }
            placeholder='Segments'
            labelFields={ [ 'name' ] }
          />
        </FormLabel>

        <FormLabel name='sector' field='Choose Sectors'>
          <MultipleChoiceFilterFromApi
            endpoint='/api/respondent/sector'
            selected={ selectedSectors }
            setChoice={ ( val ) => setSelectedSectors( val ) }
            placeholder='Sectors'
            labelFields={ [ 'name' ] }
          />
        </FormLabel>

        <FormLabel name='x' field='Created Range'>
          <DatePicker
            autoComplete='date-filter'
            className='full-width'
            name='date-filter'
            selectsRange={ true }
            startDate={ startDate }
            endDate={ endDate }
            onChange={ ( update ) => {
              setDateRange( update )
            } }
            isClearable={ true }
            clearButtonClassName='clear-datepicker'
          />
        </FormLabel>

        <FormLabel name='country' field='Country'>
          <select
            name='country'
            value={ country }
            onChange={ ( e ) => setCountry( e.target.value ) }
          >
            <option value=''>Select Country</option>
            {Object.entries( countries ).map( ( [ key, value ] ) => (
              <option value={ key } key={ key }>
                {value}
              </option>
            ) )}
          </select>
        </FormLabel>

        <FormLabel name='state' field='State'>
          <select
            name='state'
            value={ state }
            onChange={ ( e ) => setState( e.target.value ) }
          >
            <option value=''>
              {!!country ? 'Select State' : 'No country selected'}
            </option>
            {Object.entries( states[country] ?? {} )?.map( ( [ key, value ] ) => (
              <option value={ key } key={ key }>
                {value}
              </option>
            ) )}
          </select>
        </FormLabel>

        <FormLabel name='city' field='City'>
          <input
            type='text'
            placeholder='City'
            value={ city }
            onChange={ ( e ) => {
              setCity( e.target.value )
            } }
          />
        </FormLabel>
      </div>

      <div className='filter-modal-buttons'>
        <button className='secondary full-width' onClick={ handleReset }>
          Reset Filters
        </button>
        <button className='primary full-width' onClick={ handleClick }>
          Apply Filters
        </button>
      </div>
    </>
  )
}

export default ContactFilters

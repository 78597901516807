import { useContext, useEffect } from 'react'
import { useParams } from 'react-router'

import { ReactComponent as PreviewIcon } from '../../../assets/icons/tab-icons/preview.svg'
import { ReactComponent as ShareIcon } from '../../../assets/icons/tab-icons/share.svg'
import { ReactComponent as EditorIcon } from '../../../assets/icons/tab-icons/edit.svg'
import { ReactComponent as ActivityIcon } from '../../../assets/icons/tab-icons/activity.svg'

import UiContext from '../../../common/UiContext/UiContext'
import Preview from '../../../components/SurveyResponse'
import useApi from '../../../common/useApi'
import Loader from '../../../components/Loader'

import Share from './Share'
import SurveyForm from './SurveyForm'
import PublishSurveyButton from './PublishSurveyButton'
import Activity from './Activity'

const View = () => {
  const { uid } = useParams()

  const {
    data: survey,
    isLoading,
    isSuccess,
  } = useApi( 'get', `/api/survey/${ uid }/` )

  const { setPageTabsData, activePageTab, removePageTabs, setPageTabButton }
    = useContext( UiContext )

  useEffect( () => {
    if ( isSuccess ) {
      const pageTabsData = [
        {
          Component: () => Preview( { isPreview: true } ),
          Icon: PreviewIcon,
          label: 'Preview',
        },
        {
          Component: SurveyForm,
          Icon: EditorIcon,
          label: 'Editor',
        },
        ...( !survey?.is_master_survey
          ? [
            {
              Component: Share,
              Icon: ShareIcon,
              label: 'Share',
            },
            {
              Component: Activity,
              Icon: ActivityIcon,
              label: 'Activity',
            },
          ]
          : [] ),
      ]

      setPageTabsData( pageTabsData )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setPageTabsData, isSuccess ] )

  useEffect( () => {
    if ( isSuccess && !!uid ) {
      const _pageTabButton = {
        Component: () => PublishSurveyButton( { surveyUid: uid } ),
        color: 'primary',
      }

      setPageTabButton( _pageTabButton )
    }
  }, [ setPageTabButton, uid, isSuccess ] )

  useEffect(
    () => () => {
      removePageTabs()
    },
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [],
  )

  if ( isLoading ) {
    return <Loader />
  }

  if ( activePageTab ) {
    return <activePageTab.Component />
  }

  return null
}

export default View

import { useContext, useEffect } from 'react'
import { useParams } from 'react-router'

import { ReactComponent as SummariesIcon } from '../../../assets/icons/tab-icons/question-summaries.svg'
import { ReactComponent as InsightsIcon } from '../../../assets/icons/tab-icons/insights.svg'
import { ReactComponent as ResponsesIcon } from '../../../assets/icons/tab-icons/individual-responses.svg'
import { ReactComponent as ReportsIcon } from '../../../assets/icons/tab-icons/reports.svg'

import UiContext from '../../../common/UiContext/UiContext'
import IndividualResponses from './IndividualResponses'
import Insights from './Insights'
import QuestionSummaries from './QuestionSummaries'
import ExportSurveyButton from './ExportSurveyButton'
import LeaderContext from '../LeaderContext'
import SelectLeader from './QuestionSummaries/SelectLeader'
import GeneratedReports from './GeneratedReports'

const View = () => {
  const { uid } = useParams()

  const { setPageTabsData, activePageTab, removePageTabs, setPageTabButton }
    = useContext( UiContext )

  const { leader, setLeader } = useContext( LeaderContext )

  useEffect( () => {
    const pageTabsData = [
      {
        Component: Insights,
        Icon: InsightsIcon,
        label: 'Insights',
      },
      {
        Component: QuestionSummaries,
        Icon: SummariesIcon,
        label: 'Question Summaries',
      },
      {
        Component: IndividualResponses,
        Icon: ResponsesIcon,
        label: 'Individual Responses',
      },
      {
        Component: GeneratedReports,
        Icon: ReportsIcon,
        label: 'Reports',
      },
    ]

    setPageTabsData( pageTabsData )
  }, [ setPageTabsData ] )

  useEffect( () => {
    if ( !!uid ) {
      const _pageTabButton = {
        Component: () =>
          ExportSurveyButton( {
            leader,
            setLeader,
            surveyUid: uid,
          } ),
      }

      setPageTabButton( _pageTabButton )
    }
  }, [ setPageTabButton, uid, leader, setLeader ] )

  useEffect(
    () => () => {
      removePageTabs()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  if ( !leader?.uid ) {
    return <SelectLeader />
  }

  if ( activePageTab ) {
    return <activePageTab.Component />
  }

  return null
}

export default View

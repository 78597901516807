import React, { useContext } from 'react'

import './UserProfile.scss'

import sideImg from '../../assets/images/edit-profile.svg'

import AuthContext from '../../common/auth'
import Form from '../../components/Forms/Form/Form'
import UserProfileForm from '../../components/Forms/UserProfileForm'
import useApi from '../../common/useApi'
import UiContext from '../../common/UiContext/UiContext'
import ClientInfo from './ClientInfo'
import Loader from '../../components/Loader/Loader'

const UserProfile = () => {
  const { setToast } = useContext( UiContext )

  const { userData } = useContext( AuthContext )

  const { data, isLoading } = useApi(
    'get',
    `/api/auth/client/${ userData?.client_uid }`,
    null,
    { enabled: !!userData?.client_uid },
  )

  const onSuccess = () => {
    setToast( {
      message: 'Profile Update',
      type: 'success',
    } )
  }

  if ( !userData || isLoading ) {
    return <Loader />
  }

  return (
    <div className='user-profile-container'>
      <div className='user-profile-form'>
        <h2>Edit Profile</h2>

        <ClientInfo data={ data } />

        <Form
          method='put'
          endpoint={ `/api/auth/profile/${ userData.uid }` }
          onSuccess={ onSuccess }
          entries={ userData }
          FormBody={ ( props ) => UserProfileForm( { ...props } ) }
        />
      </div>

      <img src={ sideImg } alt='edit-profile' />
    </div>
  )
}

export default UserProfile

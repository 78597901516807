import React, { useEffect, useState } from 'react'

import useApi from '../../../common/useApi'

const MatrixRow = ( {
  isPreview,
  matrixUid,
  questionUid,
  survey_response,
  handleAnswerSubmitted,
} ) => {
  const [ selected, setSelected ] = useState( null ) // this is rating

  const { mutate, isSuccess } = useApi( 'post', '/api/respondent/matrix-answer' )

  useEffect(
    () => {
      if ( null !== selected ) {
        mutate( {
          matrix: matrixUid,
          question: questionUid,
          rating: selected,
          survey_response,
        } )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ selected ],
  )

  useEffect( () => {
    if ( isSuccess ) {
      handleAnswerSubmitted( matrixUid )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isSuccess ] )

  return Array.from( Array( 5 ).keys() ).map( ( rating ) => (
    <td key={ rating }>
      <div className='rating'>
        <label className='radio-btn-container'>
          <input
            type='radio'
            name={ `radio-${ matrixUid }` }
            disabled={ isPreview }
            value={ rating + 1 }
            onChange={ ( e ) => setSelected( parseInt( e.target.value ) ) }
            checked={ selected === rating + 1 }
          />
          <span className='checkmark'></span>
        </label>
      </div>
    </td>
  ) )
}

export default MatrixRow

import React from 'react'
import { useHistory } from 'react-router'

import useApi from '../../common/useApi'
import DoughnutChart from '../../components/Charts/DoughnutChart/DoughnutChart'
import DataTable from '../../components/DataTable/DataTable'
import Loader from '../../components/Loader'

import './Dashboard.scss'

const Dashboard = () => {
  const history = useHistory()

  const { data, isLoading } = useApi( 'get', '/api/client/dashboard' )

  const columns = [
    { key: 'name' },
    {
      extractData: ( obj ) => obj.length,
      key: 'respondents',
    },
    {
      extractData: ( obj ) => obj.length,
      key: 'questions',
    },
  ]

  if ( isLoading ) {
    return <Loader />
  }

  return (
    <div className='dashboard-container'>
      <h1>Dashboard</h1>

      <div className='responsive'>
        <div className='stat-card'>
          <DoughnutChart
            data={ [
              {
                color: '#5dc744',
                percent:
                  ( data.in_progress_count / ( data.total_surveys || 1 ) ) * 100,
              },
              {
                color: 'rgba(64, 153, 94, 0.25)',
                percent:
                  100
                  - ( data.in_progress_count / ( data.total_surveys || 1 ) ) * 100,
              },
            ] }
            centerNumber={ `${ data.in_progress_count }` }
            width='130px'
            height='130px'
            strokeWidth='5'
            viewBoxDimension='40'
          />
          <div>
            <h3 className='subtle'>Surveys</h3>
            <h3>In Progress</h3>
          </div>
        </div>

        <div className='stat-card'>
          <DoughnutChart
            data={ [
              {
                color: '#f89828',
                percent:
                  ( data.completed_count / ( data.total_surveys || 1 ) ) * 100,
              },
              {
                color: 'rgba(248, 152, 40, 0.25)',
                percent:
                  100
                  - ( data.completed_count / ( data.total_surveys || 1 ) ) * 100,
              },
            ] }
            centerNumber={ `${ data.completed_count }` }
            width='130px'
            height='130px'
            strokeWidth='5'
            viewBoxDimension='40'
          />
          <div>
            <h3 className='subtle'>Surveys</h3>
            <h3>Completed</h3>
          </div>
        </div>
      </div>

      <DataTable
        endpoint='/api/survey'
        heading='In Progress'
        columns={ columns }
        small={ true }
        filters={ { is_completed: false } }
        onRowClick={ ( row ) => history.push( `/survey-builder/edit/${ row.uid }` ) }
        onViewAllClick={ () =>
          history.push( '/survey-builder/?is_completed=false' )
        }
      />

      <DataTable
        endpoint='/api/survey'
        heading='Completed'
        columns={ columns }
        small={ true }
        filters={ { is_completed: true } }
        onRowClick={ ( row ) => history.push( `/survey-builder/edit/${ row.uid }` ) }
        onViewAllClick={ () =>
          history.push( '/survey-builder/?is_completed=true' )
        }
      />
    </div>
  )
}

export default Dashboard

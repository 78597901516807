/* eslint-disable max-len */
import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router'

import './Activity.scss'

import BooleanFieldFormatter from '../../../../common/Formatters/BooleanFieldFormatter'
import DataTable from '../../../../components/DataTable/DataTable'
import ConfirmModal from '../../../../components/ConfirmModal'
import useApi from '../../../../common/useApi'
import UiContext from '../../../../common/UiContext'

const Activity = () => {
  const { uid } = useParams()

  const { setToast } = useContext( UiContext )

  const { refetch, isLoading, isSuccess, isError } = useApi(
    'get',
    `/api/survey/${ uid }/reminder`,
    null,
    { enabled: false },
  )

  // TODO: add filters in backend for survey activity filters is completed / opened
  // const [ filters, setFilters ] = useState( {} )

  useEffect( () => {
    if ( isSuccess ) {
      setToast( {
        message: 'Reminders sent successfully!',
        type: 'success',
      } )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isSuccess ] )

  useEffect( () => {
    if ( isError ) {
      setToast( {
        message: 'An error occured',
        type: 'error',
      } )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isError ] )

  const columns = [
    { key: 'first_name' },
    { key: 'last_name' },
    { key: 'email' },
    {
      extractData: ( val ) => BooleanFieldFormatter( val ),
      key: 'is_opened',
      title: 'Opened',
    },
    {
      extractData: ( val ) => BooleanFieldFormatter( val ),
      key: 'is_completed',
      title: 'Completed',
    },
  ]

  const sendReminders = () => {
    ConfirmModal( {
      message: 'Are you sure you want to send reminder emails?',
      subtext:
        'This will send emails to all respondents who have not finished this survey.',
      successCallback: () => {
        refetch()
      },
    } )
  }

  const Buttons = () => (
    <>
      <button className='primary' onClick={ sendReminders } disabled={ isLoading }>
        Send Reminders
      </button>
    </>
  )

  return (
    <div className='survey-activity-container'>
      <DataTable
        endpoint={ `/api/survey/${ uid }/activity` }
        heading='Shared With'
        columns={ columns }
        onRowClick={ () => {} }
        defaultSorting='-modified'
        Buttons={ Buttons }
      />
    </div>
  )
}

export default Activity

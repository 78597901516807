import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { isEmpty } from 'lodash'

import './SurveyResponse.scss'

import logo from '../../assets/images/logo.svg'
import useApi from '../../common/useApi'

import SuccessPage from './SuccessPage'
import SingleQuestion from './SingleQuestion'
import Loader from '../Loader'

const SurveyResponse = ( { isPreview = false } ) => {
  const { survey_uid, respondent_uid, uid } = useParams()
  const location = useLocation()

  // this is to protect from spam / bots
  const canCallApi = location.state?.referrer ?? isPreview

  const { data: survey, isLoading } = useApi(
    'get',
    `/api/survey/${ survey_uid ?? uid }/questions`,
    null,
    { enabled: canCallApi },
  )

  const {
    mutate: initResponse,
    data,
    isError,
    error,
    isSuccess,
  } = useApi( 'post', '/api/respondent/survey-response' )

  const [ isNotPublished, setIsNotPublished ] = useState( false )
  const [ requiredQuestions, setRequiredQuestions ] = useState( [] )
  const [ showRequiredErrors, setShowRequiredErrors ] = useState( false )

  useEffect( () => {
    if ( !isPreview && canCallApi ) {
      initResponse( {
        respondent: respondent_uid,
        survey: survey_uid,
      } )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  useEffect(
    () => {
      if ( isSuccess ) {
        // reset the state in history
        // without causing a re-render
        window.history.replaceState( {}, document.title )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ isSuccess ],
  )

  useEffect( () => {
    if ( !isEmpty( survey ) ) {
      // taking out all the required questions uids
      // for matrix questions taking out all the uids of matrix rows

      // HACKAROUND: using concat.apply to spread the matrix_row array into simple values
      // and append into the array
      const requiredUids = [].concat.apply(
        [],
        survey.questions
          .filter( ( { is_required } ) => is_required )
          .map( ( { uid, question_type, matrix_row } ) => {
            if ( 'Matrix' !== question_type ) {
              return uid
            }
            return matrix_row.map( ( { uid } ) => uid )
          } ),
      )

      setRequiredQuestions( requiredUids )
    }
  }, [ survey ] )

  useEffect( () => {
    if ( isError ) {
      if ( 403 === error.status ) {
        // 403 means the respondent has already submitted the survey
        setShowSuccessPage( true )
      }
      if ( 423 === error.status ) {
        // 404 means the survey is not published yet
        setIsNotPublished( true )
      }
    }
  }, [ isError, error ] )

  const [ showSuccessPage, setShowSuccessPage ] = useState( false )

  const { mutate: submitResponse, isSuccess: responseSubmitted } = useApi(
    'post',
    '/api/respondent/survey-response',
  )

  useEffect( () => {
    if ( responseSubmitted ) {
      setShowSuccessPage( true )
    }
  }, [ responseSubmitted ] )

  const submit = () => {
    if ( requiredQuestions && requiredQuestions.length ) {
      setShowRequiredErrors( true )
      const questionUid = requiredQuestions[0]

      document.getElementById( questionUid ).scrollIntoView( { block: 'center' } )
      return
    }
    submitResponse( {
      is_completed: true,
      respondent: respondent_uid,
      survey: survey_uid,
    } )
  }

  const handleAnswerSubmitted = ( questionUid ) => {
    if ( requiredQuestions.length ) {
      setRequiredQuestions( ( old ) => old.filter( ( uid ) => uid !== questionUid ) )
    }
  }

  if ( !location.state?.referrer && !isPreview ) {
    return (
      <div className='centered-text'>
        <h3>You can only visit this using your invite link</h3>
      </div>
    )
  }

  if ( isLoading ) {
    return <Loader fullPage={ true } />
  }

  if ( showSuccessPage ) {
    return <SuccessPage clientLogo={ survey.client_logo } />
  }

  if ( isNotPublished ) {
    return (
      <div className='centered-text'>
        <h3>This Survey is not yet published or un-published</h3>
      </div>
    )
  }

  const getShowErrorStatus = ( question ) => {
    if ( !showRequiredErrors ) return false

    if ( 'Matrix' !== question.question_type ) {
      return 0 <= requiredQuestions.indexOf( question.uid )
    }

    // for matrix row we'll have to check if any of the matrix row uid exists
    return requiredQuestions.some(
      ( uid ) => 0 <= question.matrix_row.map( ( { uid } ) => uid ).indexOf( uid ),
    )
  }

  return (
    <div className='survey-response-container'>
      <div className='logo-container'>
        {survey.client_logo && (
          <img
            src={ survey.client_logo }
            className='client-logo'
            alt='client-logo'
          />
        )}

        <img src={ logo } width='150px' alt='apricot-logo' />
      </div>

      <h2>{survey.name}</h2>

      <p className='survey-description'>{survey.description}</p>

      <hr className='divider' />

      {survey.questions.map( ( q, questionNumber ) => (
        <SingleQuestion
          key={ q.uid }
          question={ q }
          questionNumber={ questionNumber + 1 }
          isPreview={ isPreview }
          survey_response={ data?.survey_response }
          showRequiredError={ getShowErrorStatus( q ) }
          handleAnswerSubmitted={ handleAnswerSubmitted }
        />
      ) )}

      {/* <div className='buttons'>
        <button className='secondary'>Previous</button>
        <button className='primary'>Next</button>
      </div> */}

      {!isPreview && (
        <button className='primary submit full-width' onClick={ submit }>
          Submit
        </button>
      )}
    </div>
  )
}

export default SurveyResponse

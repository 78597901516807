import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router'

import './ShareByEmail.scss'

import useApi from '../../../../../common/useApi'
import useCheckboxes from '../../../../../common/useCheckboxes/useCheckboxes'
import DataTable from '../../../../../components/DataTable/DataTable'
import UiContext from '../../../../../common/UiContext'
import ShareBySegments from './ShareBySegments'

const ShareByEmail = () => {
  const { uid } = useParams()

  const { openModal, setToast } = useContext( UiContext )

  const { mutate, isLoading, isSuccess, isError, error } = useApi(
    'post',
    '/api/survey/invite',
  )

  const withCheckboxes = useCheckboxes( 'uid' )

  useEffect( () => {
    if ( isSuccess ) {
      setToast( {
        message: 'Survey shared',
        type: 'success',
      } )
      withCheckboxes.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isSuccess, setToast ] )

  useEffect( () => {
    if ( isError ) {
      if ( 400 === error.status ) {
        setToast( {
          message: error.data.message,
          type: 'info',
        } )
      } else {
        setToast( {
          message: 'An error occured',
          type: 'error',
        } )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isError ] )

  const columns = [
    { key: 'first_name' },
    { key: 'last_name' },
    { key: 'email' },
    {
      extractData: ( field ) => field.map( ( segment ) => segment.name ).join( ', ' ),
      key: 'respondent_segments',
      title: 'Segments',
    },
  ]

  const shareSurvey = () => {
    mutate( {
      respondent_uids: withCheckboxes.selectedRows.map( ( r ) => r.uid ),
      survey_uid: uid,
    } )
  }

  const open = () => {
    openModal( () => () => <ShareBySegments survey_uid={ uid } /> )
  }

  const Buttons = () => (
    <>
      <button className='accent' onClick={ open }>
        Share with segments
      </button>
    </>
  )

  return (
    <div className='share-by-email-container'>
      <DataTable
        endpoint='/api/respondent'
        heading='Contacts'
        columns={ columns }
        Buttons={ Buttons }
        onRowClick={ () => {} }
        withCheckboxes={ withCheckboxes }
      />

      <button className='primary' onClick={ shareSurvey } disabled={ isLoading }>
        Share Survey
      </button>
    </div>
  )
}

export default ShareByEmail

import React, { useEffect, useState } from 'react'

import useApi from '../../../common/useApi'
import useDebounce from '../../../common/useDebounce/useDebounce'

const Descriptive = ( {
  question,
  isPreview,
  survey_response,
  handleAnswerSubmitted,
} ) => {
  const [ answer, setAnswer ] = useState( '' )

  const { mutate, isSuccess } = useApi(
    'post',
    '/api/respondent/descriptive-answer',
  )

  const debouncedSave = useDebounce( mutate )

  useEffect(
    () => {
      if ( !!answer ) {
        debouncedSave( {
          answer,
          question: question.uid,
          survey_response,
        } )
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ answer ],
  )

  useEffect( () => {
    if ( isSuccess ) {
      handleAnswerSubmitted( question.uid )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isSuccess ] )

  return (
    <>
      <textarea
        rows='8'
        cols='50'
        className='full-width'
        disabled={ isPreview }
        value={ answer }
        onChange={ ( e ) => setAnswer( e.target.value ) }
      ></textarea>
    </>
  )
}

export default Descriptive

import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { ReturnIcon } from '../../assets/icons'
import countries from '../../common/Formatters/countries'
import states from '../../common/Formatters/states'

import useApi, { UseInvalidateEndpoint } from '../../common/useApi'
import Icon from '../Icon'
import MultipleChoiceFilterFromApi from '../MultipleChoiceFilter/MultipleChoiceFilterFromApi'

import FormLabel from './Helpers/FormLabel/FormLabel'

const ContactForm = ( {
  isLoading,
  heading,
  goBack,
  type = 'create', // create | edit
  isContactCreated,
  entries,
  contact,
} ) => {
  const { register, formState, watch } = useFormContext()

  const country = watch( 'country' )

  const { mutate: addSegment } = useApi(
    'post',
    `/api/respondent/${ contact?.uid }/add-segment`,
  )

  const { mutate: removeSegment } = useApi(
    'post',
    `/api/respondent/${ contact?.uid }/remove-segment`,
  )

  const [ selectedSegments, setSelectedSegments ] = useState(
    entries?.respondent_segments?.map( ( { uid } ) => uid ).join() ?? '',
  )

  const {
    mutate: createSector,
    data,
    isSuccess: newSectorCreated,
  } = useApi( 'post', '/api/respondent/sector' )

  const { mutate: addSector } = useApi(
    'post',
    `/api/respondent/${ contact?.uid }/add-sector`,
  )

  const { mutate: removeSector } = useApi(
    'post',
    `/api/respondent/${ contact?.uid }/remove-sector`,
  )

  const invalidateEndpoint = UseInvalidateEndpoint()

  const [ selectedSectors, setSelectedSectors ] = useState(
    entries?.respondent_sectors?.map( ( { uid } ) => uid ).join() ?? '',
  )

  useEffect( () => {
    if ( newSectorCreated ) {
      // initially the value is null, so no need to add `<old value>,`
      setSelectedSectors( ( old ) => `${ old ? `${ old },` : '' }${ data.uid }` )
      addSector( { sector_uid: data.uid } )
      invalidateEndpoint( '/api/respondent/sector' )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ newSectorCreated ] )

  const handleContinue = () => {
    if ( isContactCreated ) {
      goBack()
    }
  }

  const handleSectorCreate = ( newSector ) => {
    createSector( { name: newSector } )
  }

  return (
    <>
      <h4>{heading}</h4>

      <hr className='divider' />

      <FormLabel name='first_name' field='First Name' required>
        <input
          type='text'
          { ...register( 'first_name', { required: 'This field is required' } ) }
          placeholder='First Name'
          className={ formState.errors?.first_name && 'error' }
        />
      </FormLabel>

      <FormLabel name='last_name' field='Last Name' required>
        <input
          type='text'
          { ...register( 'last_name', { required: 'This field is required' } ) }
          placeholder='Last Name'
          className={ formState.errors?.last_name && 'error' }
        />
      </FormLabel>

      <FormLabel
        name='email'
        field='Email Address'
        error={ formState.errors?.email }
        required
      >
        <input
          type='email'
          { ...register( 'email', { required: 'This field is required' } ) }
          placeholder='Email Address'
          className={ formState.errors?.email && 'error' }
        />
      </FormLabel>

      <FormLabel name='city' field='City' error={ formState.errors?.city }>
        <input
          type='city'
          { ...register( 'city' ) }
          placeholder='City'
          className={ formState.errors?.city && 'error' }
        />
      </FormLabel>

      <FormLabel name='state' field='State' error={ formState.errors?.state }>
        <select
          name='state'
          { ...register( 'state' ) }
          className={ formState.errors?.state && 'error' }
        >
          <option value=''>
            {!!country ? 'Select State' : 'No country selected'}
          </option>
          {Object.entries( states[country] ?? {} )?.map( ( [ key, value ] ) => (
            <option value={ key } key={ key }>
              {value}
            </option>
          ) )}
        </select>
      </FormLabel>

      <FormLabel
        name='country'
        field='Country'
        error={ formState.errors?.country }
      >
        <select
          name='country'
          { ...register( 'country' ) }
          className={ formState.errors?.country && 'error' }
        >
          <option value=''>Select Country</option>
          {Object.entries( countries ).map( ( [ key, value ] ) => (
            <option value={ key } key={ key }>
              {value}
            </option>
          ) )}
        </select>
      </FormLabel>

      <FormLabel
        name='zipcode'
        field='Zipcode'
        error={ formState.errors?.zipcode }
      >
        <input
          type='zipcode'
          { ...register( 'zipcode', { setValueAs: ( val ) => ( !!val ? val : null ) } ) }
          placeholder='Zipcode'
          className={ formState.errors?.zipcode && 'error' }
        />
      </FormLabel>

      {isContactCreated && (
        <FormLabel name='segment' field='Choose Segments'>
          <MultipleChoiceFilterFromApi
            endpoint='/api/respondent/segment'
            selected={ selectedSegments }
            setChoice={ ( val ) => setSelectedSegments( val ) }
            placeholder='Segments'
            labelFields={ [ 'name' ] }
            onAddChoice={ ( val ) => addSegment( { segment_uid: val } ) }
            onRemoveChoice={ ( val ) => removeSegment( { segment_uid: val } ) }
          />
        </FormLabel>
      )}

      {isContactCreated && (
        <FormLabel name='sector' field='Choose Sectors'>
          <MultipleChoiceFilterFromApi
            endpoint='/api/respondent/sector'
            selected={ selectedSectors }
            setChoice={ ( val ) => setSelectedSectors( val ) }
            placeholder='Sectors'
            labelFields={ [ 'name' ] }
            onAddChoice={ ( val ) => addSector( { sector_uid: val } ) }
            onRemoveChoice={ ( val ) => removeSector( { sector_uid: val } ) }
            isCreatable={ true }
            handleCreate={ handleSectorCreate }
          />
        </FormLabel>
      )}

      {'create' === type ? (
        <>
          <button
            className='primary'
            disabled={ isLoading || !formState.isValid }
            onClick={ handleContinue }
          >
            Continue
          </button>

          <button className='tertiary' onClick={ goBack } type='button'>
            <Icon IconComponent={ ReturnIcon } className='icon-md icon-green' />
            Return
          </button>
        </>
      ) : (
        <button
          className='primary full-width'
          disabled={ isLoading || !formState.isValid }
        >
          Save Contact
        </button>
      )}
    </>
  )
}

export default ContactForm

import React, { useContext, useEffect } from 'react'

import './MasterSurvey.scss'

import { ReactComponent as EditorIcon } from '../../assets/icons/tab-icons/edit.svg'
import { ReactComponent as PreviewIcon } from '../../assets/icons/tab-icons/preview.svg'

import UiContext from '../../common/UiContext'
import Preview from '../../components/SurveyResponse'

import View from './View'

const MasterSurvey = () => {
  const { setPageTabsData, activePageTab, removePageTabs }
    = useContext( UiContext )

  useEffect( () => {
    const pageTabsData = [
      {
        Component: View,
        Icon: EditorIcon,
        label: 'Editor',
      },
      {
        Component: () => Preview( { isPreview: true } ),
        Icon: PreviewIcon,
        label: 'Preview',
      },
    ]

    setPageTabsData( pageTabsData )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setPageTabsData ] )

  useEffect(
    () => () => {
      removePageTabs()
    },
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [],
  )

  if ( activePageTab ) {
    return <activePageTab.Component />
  }

  return null
}

export default MasterSurvey

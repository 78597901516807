import { useFormContext } from 'react-hook-form'

import FormLabel from './Helpers/FormLabel/FormLabel'

const CreateSurveyForm = ( { isLoading, showHeading = true } ) => {
  const { register, formState } = useFormContext()

  return (
    <>
      {showHeading && (
        <>
          <h4>Create a new survey</h4>

          <hr className='divider' />
        </>
      )}

      <FormLabel name='name' field='Survey Name' required>
        <input
          type='text'
          { ...register( 'name', { required: 'This field is required' } ) }
          placeholder='Survey Name'
          className={ formState.errors?.name && 'error' }
        />
      </FormLabel>

      <FormLabel name='description' field='Survey Description'>
        <textarea
          { ...register( 'description' ) }
          placeholder='Survey Description'
        />
      </FormLabel>

      <button className='primary' disabled={ isLoading || !formState.isValid }>
        Create Survey
      </button>
    </>
  )
}

export default CreateSurveyForm

import { useEffect, useState } from 'react'

const useCheckboxes = ( fieldName = 'uid' ) => {
  const [ selectedRows, setSelectedRows ] = useState( [] )

  const toggleCheckbox = ( row ) => {
    if ( selectedRows.find( ( r ) => r.uid === row.uid ) ) {
      setSelectedRows( ( old ) => old.filter( ( r ) => r.uid !== row.uid ) )
    } else {
      setSelectedRows( ( old ) => [ ...old, row ] )
    }
  }

  const isCheckboxSelected = ( row ) =>
    selectedRows.find( ( r ) => r.uid === row.uid )

  const selectAll = ( allRows ) => {
    if ( selectedRows.length === allRows.length ) {
      setSelectedRows( [] )
    } else {
      setSelectedRows( allRows )
    }
  }

  const reset = () => {
    setSelectedRows( [] )
  }

  useEffect( () => () => {
    reset()
  }, [] )

  return {
    fieldName,
    isCheckboxSelected,
    reset,
    selectAll,
    selectedRows,
    toggleCheckbox,
  }
}

export default useCheckboxes

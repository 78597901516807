import classNames from 'classnames'
import React, { useState } from 'react'
import { useParams } from 'react-router'

import useApi from '../../../../common/useApi/useApi'
import DoughnutChart from '../../../../components/Charts/DoughnutChart/DoughnutChart'
import VerticalBarChart from '../../../../components/Charts/VerticalBarChart/VerticalBarChart'
import Loader from '../../../../components/Loader'

import './Insights.scss'

const Insights = () => {
  const { uid } = useParams()

  const [ chartFilter, setChartFilter ] = useState( 7 )

  const { data, isLoading } = useApi( 'get', `/api/survey/${ uid }/insights/`, { period: chartFilter } )

  const handleChange = ( e ) => {
    setChartFilter( e.target.value )
  }

  const ChartFilterInput = () => (
    <select name='period' value={ chartFilter } onChange={ handleChange }>
      <option value='7'>Weekly</option>
      <option value='30'>Monthly</option>
    </select>
  )

  const calculateYAxisLabels = ( el ) => {
    const yAxisLabels = []
    const maxValue = Math.max( ...el.map( ( e ) => e.value ) )

    if ( 0 === maxValue ) {
      // if there's no data, every value is 0
      return [ 1, 2, 3, 4, 5, 6, 7 ]
    }

    const step = maxValue / 5

    for ( let i = step; i <= maxValue + step; i += step ) {
      yAxisLabels.push( i.toPrecision( 2 ) )
    }

    return yAxisLabels
  }

  const makeChartDataIntoPercents = ( el ) => {
    const maxValue = Math.max( ...el.map( ( e ) => e.value ) )
    const padding = maxValue / 5

    return el.map( ( e, index ) => ( {
      label: e.label.split( '|' )[1],
      percent: ( e.value / ( maxValue + padding ) ) * 100,
      value: e.value,
    } ) )
  }

  if ( isLoading ) {
    return <Loader />
  }

  return (
    <div className='insights-container'>
      <div className='tile full-width title-section'>
        <h3>{data.survey_name}</h3>

        <p className='subtle'>{data.client_name}</p>
      </div>

      <div className='responsive'>
        <div className='stat-card'>
          <h4 className='stat primary'>{data.total_responses}</h4>
          <h4>Responses</h4>
        </div>

        <div className='stat-card'>
          <h4 className='stat secondary'>{data.total_recipients}</h4>
          <h4>Recepients</h4>
        </div>

        <div className='stat-card'>
          <DoughnutChart
            data={ [
              {
                color: '#5dc744',
                percent: data.open_rate,
              },
              {
                color: 'rgba(64, 153, 94, 0.25)',
                percent: 100 - data.open_rate,
              },
            ] }
            centerNumber={ `${ data.open_rate }%` }
            width='100px'
            height='100px'
            strokeWidth='8'
            viewBoxDimension='40'
          />
          <h4>Open Rate</h4>
        </div>

        <div className='stat-card'>
          <DoughnutChart
            data={ [
              {
                color: '#f89828',
                percent: data.completed_rate,
              },
              {
                color: 'rgba(248, 152, 40, 0.25)',
                percent: 100 - data.completed_rate,
              },
            ] }
            centerNumber={ `${ data.completed_rate }%` }
            width='100px'
            height='100px'
            strokeWidth='8'
            viewBoxDimension='40'
          />
          <h4>Completed Rate</h4>
        </div>
      </div>

      <div
        className={ classNames( {
          'full-width': '30' === chartFilter,
          responsive: true,
        } ) }
      >
        <div className='chart-card'>
          <VerticalBarChart
            heading='Open'
            data={ makeChartDataIntoPercents( data.open_counts ) }
            ControlledInput={ ChartFilterInput }
            yAxisLabels={ calculateYAxisLabels( data.open_counts ) }
          />
        </div>

        <div className='chart-card'>
          <VerticalBarChart
            heading='Completed'
            data={ makeChartDataIntoPercents( data.completed_counts ) }
            ControlledInput={ ChartFilterInput }
            yAxisLabels={ calculateYAxisLabels( data.completed_counts ) }
          />
        </div>
      </div>
    </div>
  )
}

export default Insights

import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import useApi from '../../common/useApi'
import CreateSurveyForm from '../../components/Forms/CreateSurveyForm'

import Form from '../../components/Forms/Form'
import Loader from '../../components/Loader'
import SurveyForm from '../SurveyBuilder/View/SurveyForm'

const View = () => {
  const { uid } = useParams()
  const history = useHistory()

  const { data, isSuccess, isLoading, isError } = useApi(
    'get',
    '/api/survey/master-survey',
    null,
    { retry: 1 },
  )

  useEffect( () => {
    if ( isSuccess ) {
      history.push( `/master-survey/${ data.uid }` )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isSuccess ] )

  const onSuccess = ( responseData ) => {
    history.push( `/master-survey/${ responseData.uid }` )
  }

  if ( isLoading ) {
    return <Loader message='Fetching Master Survey...' />
  }

  return (
    <div className='master-survey-container'>
      <h2>Master Survey</h2>

      {isError && !uid && (
        <div className='page-content'>
          <Form
            method='post'
            endpoint='/api/survey'
            FormBody={ ( props ) =>
              CreateSurveyForm( {
                ...props,
                showHeading: false,
              } )
            }
            onSuccess={ onSuccess }
          />
        </div>
      )}

      {isError && uid && <h5>UID: {uid}</h5>}

      {isSuccess && <SurveyForm surveyUid={ uid } />}
    </div>
  )
}

export default View

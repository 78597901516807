import React from 'react'
import DataTable from '../../../components/DataTable/DataTable'

const List = () => {
  const columns = [
    { key: 'name' },
    {
      extractData: ( obj ) => obj.length,
      key: 'respondents',
    },
    {
      extractData: ( obj ) => obj.length,
      key: 'questions',
    },
  ]

  const filters = { is_published: true }

  return (
    <DataTable
      endpoint='/api/survey'
      heading='Surveys'
      columns={ columns }
      filters={ filters }
    />
  )
}

export default List

import React from 'react'

import './VerticalBarChart.scss'

const VerticalBarChart = ( {
  data,
  heading,
  ControlledInput = null,
  yAxisLabels,
} ) => (
  <div className='chart-container'>
    <div className='header-container'>
      <h3>{heading}</h3>

      {ControlledInput && <ControlledInput />}
    </div>
    <div className='row'>
      <div className='y-axis'>
        <div>0</div>
        {yAxisLabels.map( ( n, index ) => (
          <div key={ n + index }>{n}</div>
        ) )}
      </div>

      <div className='vertical-bar-chart'>
        {data.map( ( stat ) => (
          <div
            key={ stat.label }
            className='bar'
            data-value={ stat.value }
            style={ { height: `${ stat.percent }%` } }
          ></div>
        ) )}
      </div>
    </div>

    <div className='label-container'>
      {data.map( ( stat ) => (
        <span className='label subtle' key={ stat.label }>
          {stat.label}
        </span>
      ) )}
    </div>
  </div>
)

export default VerticalBarChart

/*
data = [
  {
    label: string
    percent: number
    value: number ( used for showing value on hover above bar )
  }
]
heading = ''
ControlledInput = ReactComponent
*/

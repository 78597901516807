import React, { useContext } from 'react'

import UiContext from '../../../common/UiContext/UiContext'
import Form from '../../Forms/Form/Form'
import SignUpForm from '../../Forms/SignUpForm'

const SignUp = ( { goToLogin } ) => {
  const { setToast } = useContext( UiContext )

  const onSuccess = () => {
    setToast( {
      message:
        'Account created successfully! Email verification sent to your email',
      timeout: 15000,
      type: 'info',
    } )
    goToLogin()
  }

  const onError = ( error ) => {
    // TODO: make this error better
    setToast( {
      message: error.data?.message ?? 'Something went wrong',
      type: 'error',
    } )
  }

  return (
    <Form
      method='post'
      endpoint='/api/auth/registration'
      onSuccess={ onSuccess }
      onError={ onError }
      FormBody={ ( props ) =>
        SignUpForm( {
          ...props,
          goToLogin,
        } )
      }
    />
  )
}

export default SignUp

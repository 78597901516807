/* eslint-disable max-len */
import React from 'react'

const Reports = () => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        d='M14.4889 1.03226C14.4889 0.72727 14.3538 0.437911 14.1201 0.241894C13.8864 0.0458762 13.5775 -0.0375885 13.2772 0.0158116C10.7431 0.466456 8.4141 1.50928 6.43787 2.99601C6.19909 3.17564 6.04996 3.45013 6.02912 3.74828C6.00828 4.04643 6.11812 4.33872 6.32944 4.55004L11.6522 9.87279C11.9776 10.1982 12.4798 10.269 12.882 10.0454C13.1795 9.87996 13.4909 9.73658 13.814 9.61762C14.2196 9.46828 14.4889 9.08189 14.4889 8.64969V1.03226Z'
        fill='#40995E'
      />
      <path
        d='M17.745 8.6497C17.745 9.082 18.0147 9.46828 18.4205 9.61752C20.2535 10.2916 21.7085 11.7468 22.3827 13.5798C22.5319 13.9855 22.9184 14.255 23.3507 14.255H30.9689C31.2742 14.255 31.5637 14.1197 31.7596 13.8856C31.9555 13.6515 32.0375 13.3427 31.984 13.0422C30.806 6.41818 25.5818 1.19398 18.9578 0.0159254C18.6575 -0.0374747 18.349 0.0449047 18.1151 0.240705C17.8812 0.436505 17.745 0.725973 17.745 1.03107V8.6497Z'
        fill='#5DC744'
      />
      <path
        d='M23.3504 17.5111C22.9181 17.5111 22.5317 17.7808 22.3826 18.1866C21.4445 20.7378 18.9936 22.5577 16.1169 22.5577C12.4306 22.5577 9.44226 19.5694 9.44226 15.8831C9.44226 15.06 9.59172 14.2722 9.86436 13.5443C10.0062 13.1657 9.91353 12.7387 9.62764 12.4529L4.16206 6.98727C3.94173 6.76694 3.63359 6.65819 3.32372 6.69108C3.01385 6.72396 2.73469 6.89491 2.5657 7.1567C0.942533 9.67171 0 12.6671 0 15.8831C0 24.7842 7.21585 32 16.1169 32C24.0487 32 30.642 26.2702 31.984 18.724C32.0374 18.4237 31.9551 18.1152 31.7593 17.8813C31.5635 17.6474 31.274 17.5112 30.9689 17.5112H23.3504V17.5111Z'
        fill='#F89828'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='32' height='32' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default Reports

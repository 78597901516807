/* eslint-disable sort-keys */
import React, { useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'

import './DataTable.scss'

import MenuButton from '../MenuButton'
import TablePagination from './TablePagination'
import useApi from '../../common/useApi'

import {
  MoreIcon,
  ArrowDownIcon as SortIcon,
  FilterIcon,
} from '../../assets/icons'

import TableLoading from './TableLoading'
import TableSearch from './TableSearch'
import Icon from '../Icon'
import classNames from 'classnames'
import capitalize from '../../utils/capitalize'
import Checkbox from '../Checkbox/Checkbox'
import UiContext from '../../common/UiContext'

const DataTable = ( {
  actionsContent = null,
  columns,
  small = false,
  heading,
  endpoint,
  Buttons = null,
  onRowClick = null,
  withCheckboxes = false,
  filters = {},
  defaultSorting = '',
  onViewAllClick = () => {},
  filterButtonConfig = null,
  isSearchable = true,
  config = {},
} ) => {
  const history = useHistory()
  const location = useLocation()

  const { filterModal: { openFilterModal } } = useContext( UiContext )

  const [ page, setPage ] = useState( 1 )
  const [ search, setSearch ] = useState( '' )
  const [ sorting, setSorting ] = useState( defaultSorting )

  const { data, isLoading, isError, isSuccess } = useApi(
    'getList',
    endpoint,
    {
      limit: 10,
      page,
      search,
      ordering: sorting,
      ...filters,
    },
    config,
  )

  const getValue = ( col, row ) => {
    if ( !!col.extractData ) {
      return col.extractData( row[col.key], row )
    }
    return row[col.key]
  }

  const makeTitle = ( name, title ) =>
    title
    ?? name
      .split( '_' )
      .map( ( chunk ) => capitalize( chunk ) )
      .join( ' ' )

  const view = ( e, row ) => {
    if ( 'TD' !== e.target.nodeName && e.target !== e.currentTarget ) {
      return
    }
    if ( !onRowClick ) {
      history.push( `${ location.pathname }/edit/${ row.uid }` )
    } else {
      onRowClick( row )
    }
  }

  const getActionsContent = ( rowData ) => {
    if ( 'function' === typeof actionsContent ) {
      return actionsContent( rowData )
    }
    return actionsContent // is an array of objects
  }

  return (
    <div
      className='data-table-container'
      style={ { height: !small ? '100%' : '' } }
    >
      {small ? (
        <div className='small-table-heading'>
          <h3>{heading}</h3>
          <button className='primary' onClick={ onViewAllClick }>
            View All
          </button>
        </div>
      ) : (
        <div className='table-heading'>
          <h2>{heading}</h2>

          <div className='data-table-buttons'>
            {isSearchable && (
              <TableSearch
                handleSearch={ setSearch }
                value={ search }
                endpoint={ endpoint }
              />
            )}

            {null !== filterButtonConfig && (
              <button
                className={ classNames( {
                  badge: !isEmpty( filters ),
                  secondary: true,
                } ) }
                onClick={ () =>
                  openFilterModal( () => () => (
                    <filterButtonConfig.FilterComponent
                      apply={ filterButtonConfig.setFilters }
                      filters={ filters }
                      resetFilters={ () => {
                        filterButtonConfig.setFilters( {} )
                        history.replace( { search: '' } )
                      } }
                    />
                  ) )
                }
              >
                <FilterIcon className='primary size-1' />
                Filters
              </button>
            )}

            {Buttons && <Buttons />}
          </div>
        </div>
      )}

      <table className='data-table'>
        <thead>
          <tr>
            {withCheckboxes && (
              <th className='make-inline'>
                <Checkbox
                  name='selectAll'
                  checked={
                    withCheckboxes.selectedRows.length === data?.results.length
                  }
                  small={ true }
                  onChange={ () => withCheckboxes.selectAll( data.results ) }
                  label=''
                />
                Select All
              </th>
            )}
            {columns.map( ( { title, key } ) => (
              <th
                key={ key }
                onClick={ () =>
                  setSorting( ( old ) => ( '-' === old[0] ? key : `-${ key }` ) )
                }
              >
                {title ?? makeTitle( key )}
                <Icon
                  IconComponent={ SortIcon }
                  className={ classNames( {
                    desc: sorting === `-${ key }`,
                    primary: true,
                    show:
                      !!sorting && ( sorting === key || `-${ key }` === sorting ),
                    'size-1': true,
                  } ) }
                />
              </th>
            ) )}
            {actionsContent && <th className='actions-header'></th>}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <TableLoading
              numColumns={ columns.length + ( actionsContent ? 1 : 0 ) }
            />
          ) : (
            <>
              {isError && <p>An error occured</p>}
              {isSuccess
                && data.results.map( ( row, r ) => (
                  <tr key={ `row_${ r }` } onClick={ ( e ) => view( e, row ) }>
                    {withCheckboxes && (
                      <td className='make-inline'>
                        <Checkbox
                          small={ true }
                          checked={
                            withCheckboxes.isCheckboxSelected( row ) ?? false
                          }
                          onChange={ () => withCheckboxes.toggleCheckbox( row ) }
                          label=''
                        />
                      </td>
                    )}
                    {columns.map( ( col, index ) => (
                      <td key={ `${ row[col.key] }${ index }` }>
                        {getValue( col, row )}
                      </td>
                    ) )}
                    {actionsContent && (
                      <td className='actions'>
                        <MenuButton
                          IconComponent={ MoreIcon }
                          dropdownContent={ getActionsContent( row ) }
                          data={ row }
                        />
                      </td>
                    )}
                  </tr>
                ) )}
            </>
          )}
        </tbody>
      </table>

      {!small && isSuccess && (
        <TablePagination
          total={ data.count }
          currentPageTotal={ data.results.length }
          hasNext={ data.next }
          hasPrevious={ data.previous }
          nextPage={ () => setPage( page + 1 ) }
          previousPage={ () => setPage( page - 1 ) }
          activePage={ page }
          changePage={ ( val ) => setPage( val ) }
        />
      )}
    </div>
  )
}

export default DataTable

/*
columns: [
  {
    key: string
    title?: string  ( default will make title from key)
    extractData: ( obj ) => { ... }   ( obj is make from [key] )
  },
]
small?: boolean
endpoint: string
heading: string
actionsContent: [
  {
    IconComponent
    color: 'primary' | 'accent' | 'danger' ... ( classNames on Icon )
    func: () => {}    ( onClick function )
    label: string
  }
]
*/

import React, { useEffect } from 'react'

import { AddIcon } from '../../../assets/icons'
import useApi, { UseInvalidateEndpoint } from '../../../common/useApi'

import FormLabel from '../../../components/Forms/Helpers/FormLabel/FormLabel'
import Icon from '../../../components/Icon'

const AddContactsToSectorsButton = ( {
  showBtn,
  setShowBtn,
  sectorUid,
  setSectorUid,
  withCheckboxes,
} ) => {
  const invalidateEndpoint = UseInvalidateEndpoint()
  const { data: sectors } = useApi( 'get', '/api/respondent/sector', { limit: 999 } )

  const { mutate, isSuccess: bulkEditSuccess } = useApi(
    'post',
    '/api/respondent/bulk-edit-sector',
  )

  useEffect( () => {
    if ( bulkEditSuccess ) {
      cancelAddContactsToSector()
      invalidateEndpoint( '/api/respondent' )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ bulkEditSuccess ] )

  const bulkEdit = () => {
    mutate( {
      respondent_uids: withCheckboxes.selectedRows.map( ( r ) => r.uid ),
      sector_uid: sectorUid,
    } )
  }

  const cancelAddContactsToSector = () => {
    setShowBtn( false )
    withCheckboxes.reset()
  }

  if ( !showBtn ) {
    return (
      <button className='primary' onClick={ () => setShowBtn( !showBtn ) }>
        <Icon IconComponent={ AddIcon } className='icon-md icon-white' /> Add
        contacts to sector
      </button>
    )
  }

  return (
    <>
      <FormLabel name='sector' field='Sector' required>
        <select
          name='sector'
          value={ sectorUid }
          onChange={ ( e ) => setSectorUid( e.target.value ) }
        >
          <option value=''>Select a Sector</option>
          {sectors.results.map( ( sector ) => (
            <option key={ sector.uid } value={ sector.uid }>
              {sector.name}
            </option>
          ) )}
        </select>
      </FormLabel>
      <button className='primary' onClick={ bulkEdit }>
        Save
      </button>
      <button className='secondary' onClick={ cancelAddContactsToSector }>
        Cancel
      </button>
    </>
  )
}

export default AddContactsToSectorsButton

import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import classNames from 'classnames'

import { UserIcon, MoreIcon, LogoutIcon } from '../assets/icons'

import Icon from '../components/Icon'
import MenuButton from '../components/MenuButton'
import useLogout from '../common/auth/useLogout'
import AuthContext from '../common/auth/auth'

const UserMenu = () => {
  const history = useHistory()

  const { userData, isImpersonatedUser } = useContext( AuthContext )

  const { logout } = useLogout()

  const dropdownContent = [
    ...( !isImpersonatedUser
      ? [
        {
          IconComponent: UserIcon,
          color: 'dark',
          func: () => history.push( '/profile' ),
          label: 'Edit Profile',
        },
      ]
      : [] ),
    {
      IconComponent: LogoutIcon,
      color: 'dark',
      func: logout,
      label: 'Log out',
    },
  ]

  return (
    <div className='user-menu'>
      {!isImpersonatedUser && (
        <div className='user-icon'>
          <Icon IconComponent={ UserIcon } className='light size-4' />
        </div>
      )}

      <p className={ classNames( { impersonated: isImpersonatedUser } ) }>{`${
        userData?.first_name ?? ''
      } ${ userData?.last_name ?? '' }`}</p>

      <MenuButton
        IconComponent={ MoreIcon }
        dropdownContent={ dropdownContent }
        position='top'
      />
    </div>
  )
}

export default UserMenu

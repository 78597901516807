/* eslint-disable max-len */
import React, { useContext, useState } from 'react'

import './IndividualResponses.scss'

import { ReactComponent as PreviousIcon } from '../../../../assets/icons/previous-arrow.svg'
import { ReactComponent as NextIcon } from '../../../../assets/icons/next-arrow.svg'
import Icon from '../../../../components/Icon/Icon'
import { useParams } from 'react-router'
import useApi from '../../../../common/useApi/useApi'
import dateFormatter from '../../../../common/Formatters/dateFormatter'
import Loader from '../../../../components/Loader'
import LeaderContext from '../../LeaderContext'
import SelectLeader from '../QuestionSummaries/SelectLeader'

const IndividualResponses = () => {
  const { uid } = useParams()

  const { leader } = useContext( LeaderContext )

  const [ page, setPage ] = useState( 1 )

  const { data, isLoading } = useApi(
    'get',
    `/api/survey/${ uid }/individual-responses/`,
    {
      leader: leader.uid,
      limit: 1,
      page,
    },
  )

  const handleChange = ( e ) => {
    if ( e.target.value > data.count || 1 > e.target.value ) return

    setPage( parseInt( e.target.value ) )
  }

  return (
    <div className='individual-responses'>
      <div className='title-section tile'>
        <div className='info'>
          <h4>Respondent #{page}</h4>

          <p className='subtle'>
            Completed on {dateFormatter( data?.results[0]?.modified )}
          </p>
        </div>

        <div className='pagination'>
          <SelectLeader />

          <p>
            <span>
              <input
                type='number'
                value={ page }
                onChange={ handleChange }
                className='page-number-input'
              />
            </span>{' '}
            of {data?.count}
          </p>
          <button
            className='secondary icon-button'
            disabled={ !data?.previous }
            onClick={ () => setPage( ( old ) => old - 1 ) }
          >
            <Icon IconComponent={ PreviousIcon } className='primary size-1' />
          </button>

          <button
            className='secondary icon-button'
            disabled={ !data?.next }
            onClick={ () => setPage( ( old ) => old + 1 ) }
          >
            <Icon IconComponent={ NextIcon } className='primary size-1' />
          </button>
        </div>
      </div>

      {isLoading && <Loader />}

      {0 === data?.results.length && (
        <div className='tile'>
          <p>No Responses </p>
        </div>
      )}

      {0 < data?.results.length
        && data.results[0].individual_responses.map(
          ( { uid, order, question, answer } ) => (
            <div className='tile question-container' key={ uid }>
              <h5 className='question-title' data-number={ `Q${ order }` }>
                {question}
              </h5>

              <hr className='divider' />

              <p>{answer}</p>
            </div>
          ),
        )}
    </div>
  )
}

export default IndividualResponses

import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'

import UiContext from '../../../common/UiContext'
import useApi, { UseInvalidateEndpoint } from '../../../common/useApi'
import Icon from '../../../components/Icon'
import { CheckIcon } from '../../../assets/icons'

const PublishSurveyButton = ( { surveyUid } ) => {
  const { removePageTabButton, setToast } = useContext( UiContext )

  const invalidateEndpoint = UseInvalidateEndpoint()

  const { data: survey, isSuccess: getSurveySuccess } = useApi(
    'get',
    `/api/survey/${ surveyUid }`,
    null,
    {
      refetch: false,
      retry: false,
    },
  )

  const [ isPublished, setIsPublished ] = useState( false )

  const {
    data,
    isSuccess: publishSuccess,
    isLoading,
    mutate: publish,
    isError,
    error,
  } = useApi( 'post', `/api/survey/${ surveyUid }/publish`, null )

  useEffect( () => {
    if ( publishSuccess ) {
      setIsPublished( data.is_published )
      invalidateEndpoint( `/api/survey/${ surveyUid }` )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ publishSuccess ] )

  useEffect( () => {
    if ( getSurveySuccess ) {
      setIsPublished( survey.is_published )
    }
  }, [ getSurveySuccess, survey ] )

  useEffect( () => {
    if ( isError ) {
      if ( 400 === error.status ) {
        setToast( {
          message: error.data.message,
          type: 'info',
        } )
      } else {
        setToast( {
          message: 'An error occured',
          type: 'error',
        } )
      }
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [ isError ] )

  useEffect(
    () => () => {
      removePageTabButton()
    },
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [],
  )

  return (
    <button
      className={ classNames( {
        accent: !isPublished,
        primary: isPublished,
      } ) }
      onClick={ () => publish() }
      disabled={ isLoading }
    >
      {isPublished && (
        <Icon IconComponent={ CheckIcon } className='light size-1' />
      )}
      {!isPublished ? 'Publish Survey' : 'Published'}
    </button>
  )
}

export default PublishSurveyButton

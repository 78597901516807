/* eslint-disable sort-keys */
import React from 'react'
import { useHistory } from 'react-router'

import './ImportedContacts.scss'

const ImportedContacts = ( { contacts } ) => {
  const history = useHistory()

  const done = () => {
    history.push( '/email-manager' )
  }

  return (
    <div className='data-table-container bulk-edit-contacts'>
      <div className='table-heading'>
        <h2>
          Created Contacts <span className='subtle'>({contacts.length})</span>
        </h2>

        <div className='data-table-buttons'>
          <button className='primary' onClick={ done }>
            Continue
          </button>
        </div>
      </div>

      <table className='data-table'>
        <thead>
          <tr>
            <th>Email</th>
            <th>First Name</th>
            <th>Last Name</th>
          </tr>
        </thead>

        <tbody>
          {contacts.map( ( contact ) => (
            <tr key={ contact.uid }>
              <td>{contact.email}</td>
              <td>{contact.first_name}</td>
              <td>{contact.last_name}</td>
            </tr>
          ) )}
        </tbody>
      </table>
    </div>
  )
}

export default ImportedContacts

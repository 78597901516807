import React, { useState } from 'react'
import { useParams } from 'react-router'

import { AddIcon } from '../../../../assets/icons'
import Icon from '../../../../components/Icon/Icon'
import Question from './Question'

const AddQuestion = ( { questionNumber, getAllMcqs } ) => {
  const { uid } = useParams()

  const [ add, setAdd ] = useState( false )

  const defaultValues = {
    choices: null,
    order: questionNumber,
    question_type: '',
    survey: uid,
    title: '',
  }

  return (
    <>
      {add && (
        <Question
          create={ true }
          defaultValues={ defaultValues }
          questionNumber={ questionNumber }
          handleCreated={ () => setAdd( false ) }
          cancelCreate={ () => setAdd( false ) }
          getAllMcqs={ getAllMcqs }
        />
      )}

      <button className='tertiary' onClick={ () => setAdd( true ) }>
        <Icon IconComponent={ AddIcon } className='primary size-1' />
        Add Question
      </button>
    </>
  )
}

export default AddQuestion

import React, { useContext, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import UiContext from '../../../common/UiContext'
import Form from '../../../components/Forms/Form/Form'
import SegmentForm from '../../../components/Forms/SegmentForm'

const SegmentModal = ( { segment: defaultSegment } ) => {
  const history = useHistory()
  const queryClient = useQueryClient()

  const { setToast, closeModal } = useContext( UiContext )

  const [ segment, setSegment ] = useState( defaultSegment )

  // if there is a segment then it is in edit segment mode
  // else in edit mode
  const getEndpoint = () =>
    !segment
      ? '/api/respondent/segment'
      : `/api/respondent/segment/${ segment?.uid }`

  const onSuccess = ( responseData ) => {
    setToast( {
      message: 'Segment saved successfully',
      type: 'success',
    } )
    queryClient.invalidateQueries( '/api/respondent/segment' )
    if ( !segment ) {
      // create mode
      setSegment( responseData )
    } else {
      // edit mode
      closeModal()
      history.push( '/email-manager/contacts' )
    }
  }

  return (
    <div>
      <Form
        method={ !segment ? 'post' : 'put' }
        endpoint={ getEndpoint() }
        onSuccess={ onSuccess }
        entries={ segment }
        FormBody={ ( props ) =>
          SegmentForm( {
            ...props,
            segment,
          } )
        }
      />
    </div>
  )
}

export default SegmentModal

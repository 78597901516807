import React from 'react'
import { useRouteMatch } from 'react-router'

import CrudRouterSwitch from '../../common/CrudRouter/CrudRouter'

import List from './List'
import View from './View'

const SurveyBuilder = () => {
  const { url } = useRouteMatch()

  const components = {
    list: List,
    view: View,
  }

  return <CrudRouterSwitch url={ url } components={ components } />
}

export default SurveyBuilder

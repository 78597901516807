import React from 'react'

const Icon = ( {
  className = 'default size-1', // used for filling color
  IconComponent,
  ...rest
} ) => <IconComponent className={ className } { ...rest } />

export default Icon

/*
className = primary | danger | accent | light | dark
size = size-1 | size-2 | size-3 | size-4
*/

import React, { useContext } from 'react'
import { useParams } from 'react-router'

import './QuestionSummaries.scss'

import useApi from '../../../../common/useApi/useApi'
import RadarChart from '../../../../components/Charts/RadarChart/RadarChart'
import Loader from '../../../../components/Loader'
import COLORS from '../../../../utils/statColor'

import StatsTable from './StatsTable'
import QuestionContainer from './QuestionContainer'
import LeaderContext from '../../LeaderContext'

const QuestionSummaries = () => {
  const { uid } = useParams()

  const { leader } = useContext( LeaderContext )

  const { data, isLoading } = useApi(
    'get',
    `/api/survey/${ uid }/summaries`,
    { leader: leader.uid },
    { enabled: !!leader.uid },
  )

  const formatDataForRadarChart = ( data ) => {
    // TODO: Currently the scale of matrix is fixed to 5
    // this might be made dynamic in the future ( question.scale maybe )
    // let the maxValue = 5
    const maxValue = 5
    const oneRadarStatSize = data[0].length

    const formattedData = []

    for ( let i = 0; i < oneRadarStatSize; i++ ) {
      const stats = data.map( ( arr ) => ( arr[i] / maxValue ).toPrecision( 2 ) )
      const obj = {
        color: COLORS[i],
        stats,
      }

      formattedData.push( obj )
    }

    return formattedData
  }

  const reorderAreaRows = ( radarData ) =>
    /**
     * This is to re-order the rows of the Radar chart table according to the client
     * only on the stats table not on the Radar chart
     * Maybe there is a way to this better
     */
    // desired order = ['Meaning', 'Relationship', 'Leadership', 'Creativity', 'Agility', 'Decision Making', 'Delivery']
    ( {
      ...radarData,
      area: [
        ...radarData.area.slice( 0, 3 ).reverse(),
        ...radarData.area.slice( 3 ).reverse(),
      ],
      stats: [
        ...radarData.stats.slice( 0, 3 ).reverse(),
        ...radarData.stats.slice( 3 ).reverse(),
      ],
    } )

  if ( isLoading ) {
    return <Loader />
  }

  return (
    <div className='question-summaries-container'>
      {data.questions.map( ( question ) => (
        <QuestionContainer question={ question } key={ question.order } />
      ) )}

      {data.radar_charts.map( ( radarData, index ) => {
        const formattedRadarData = reorderAreaRows( radarData )

        return (
          <div className='tile' key={ `radar-chart${ index }` }>
            <h5>The 7 areas of health overview</h5>

            <RadarChart
              statsData={ formatDataForRadarChart( radarData.stats ) }
              labels={ radarData.area }
              maxValue={ 5 }
            />

            <StatsTable
              columnHeaders={ [ 'Area', ...formattedRadarData.column_headers ] }
              rows={ formattedRadarData.stats.map( ( stat, index ) => [
                formattedRadarData.area[index],
                ...stat,
              ] ) }
            />
          </div>
        )
      } )}
    </div>
  )
}

export default QuestionSummaries

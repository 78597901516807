import React from 'react'
import classNames from 'classnames'

import Mcq from '../../components/QuestionTypes/Mcq'
import Oneliner from '../../components/QuestionTypes/Oneliner'
import Descriptive from '../../components/QuestionTypes/Descriptive/Descriptive'
import Matrix from '../../components/QuestionTypes/Matrix/Matrix'
import useElementOnScreen from '../../common/useElementOnScreen'

const SingleQuestion = ( {
  question,
  questionNumber,
  isPreview,
  survey_response,
  showRequiredError,
  handleAnswerSubmitted,
} ) => {
  const getThresholdValue = ( question ) => {
    if ( 'MCQ' === question.question_type && 4 < question.choices?.length ) {
      return 0.5
    }

    if (
      'Matrix' === question.question_type
      && 4 < question.matrix_row?.length
    ) {
      return 0.4
    }

    return 1
  }

  const [ containerRef, isVisible ] = useElementOnScreen( {
    root: null,
    rootMargin: '-23% 0px -23% 0px',
    threshold: getThresholdValue( question ),
  } )

  const getQuestionComponent = ( questionType, props ) => {
    switch ( questionType ) {
    case 'Matrix':
      return <Matrix { ...props } />

    case 'Oneliner':
      return <Oneliner { ...props } />

    case 'Descriptive':
      return <Descriptive { ...props } />

    case 'MCQ':
      return <Mcq { ...props } />

    default:
      break
    }
  }

  const autoScroll = ( e ) => {
    if ( 'Matrix' === question.question_type && !isVisible ) {
      containerRef.current.scrollIntoView( {
        behavior: 'smooth',
        block: 'center',
      } )
    }
  }

  return (
    <div
      className={ classNames( {
        'question-container': true,
        'required-error': showRequiredError,
        visible: isPreview || isVisible,
      } ) }
      ref={ containerRef }
      id={ question.uid }
      onClick={ autoScroll }
    >
      <h5 className={ classNames( {
        'question-title': true,
        required: question.is_required,
      } ) }>
        {questionNumber}. {question.title}
      </h5>

      {getQuestionComponent( question.question_type, {
        handleAnswerSubmitted,
        isPreview,
        question: question,
        survey_response,
      } )}
    </div>
  )
}

export default SingleQuestion

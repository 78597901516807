import React, { useContext } from 'react'
import classNames from 'classnames'

import './PageTabs.scss'

import UiContext from '../../common/UiContext'
import Icon from '../Icon'

const PageTabs = () => {
  const {
    pageTabsData,
    setActivePageTab,
    activePageTab,
    showTabsLoading,
    pageTabButton,
  } = useContext( UiContext )

  return (
    <>
      <div className='page-tabs'>
        {pageTabsData
          && pageTabsData.map( ( tab ) => (
            <div
              className={ classNames( {
                active: activePageTab && activePageTab.label === tab.label,
                tab: true,
              } ) }
              key={ tab.label }
              onClick={ () => setActivePageTab( tab ) }
            >
              {tab?.Icon && (
                <Icon IconComponent={ tab.Icon } className='size-2' />
              )}
              {tab.label}
            </div>
          ) )}

        {pageTabButton && (
          <div className='tab page-tab-button'>
            {<pageTabButton.Component />}
          </div>
        )}

        {showTabsLoading && (
          <div className='tabs-loading'>
            <div className='indeterminate'></div>
          </div>
        )}
      </div>
    </>
  )
}

export default PageTabs

/*
pageTabsData = [
  {
    label: string
    Icon?: IconComponent
    Component: ReactComponent
  }
]

<activePageTab.Component />
*/

import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import './ContactsTab.scss'

import { AddIcon, EditIcon, TrashIcon } from '../../../assets/icons'

import UiContext from '../../../common/UiContext'
import useApi from '../../../common/useApi'
import useCheckboxes from '../../../common/useCheckboxes/useCheckboxes'
import Icon from '../../../components/Icon'
import PageContent from '../../../components/PageContent/PageContent'
import DataTable from '../../../components/DataTable/DataTable'

import ConfirmModal from '../../../components/ConfirmModal'
import ContactEditForm from './ContactAdd/ContactForms/ContactEditForm'
import ContactFilters from './ContactFilters'
import dateFormatter from '../../../common/Formatters/dateFormatter'
import states from '../../../common/Formatters/states'
import countries from '../../../common/Formatters/countries'
import AddContactsToSegmentsButton from './AddContactsToSegmentsButton'
import AddContactsToSectorsButton from './AddContactsToSectorButton'

const ContactsTab = () => {
  const history = useHistory()

  const { setToast, openModal } = useContext( UiContext )

  const { mutate: deleteContact } = useApi( 'delete', '/api/respondent' )

  const withCheckboxes = useCheckboxes( 'uid' )

  const [ showAddContactsToSegment, setshowAddContactsToSegment ]
    = useState( false )
  const [ showAddContactsToSector, setshowAddContactsToSector ] = useState( false )
  const [ segmentUid, setSegmentUid ] = useState( '' )
  const [ sectorUid, setSectorUid ] = useState( '' )
  const [ filters, setFilters ] = useState( {} )

  const actionsContent = [
    {
      IconComponent: EditIcon,
      color: 'primary',
      func: ( row ) => {
        openModal( () => () => <ContactEditForm contact={ row } /> )
      },
      label: 'Edit',
    },
    {
      IconComponent: TrashIcon,
      color: 'danger',
      func: ( row ) => {
        ConfirmModal( {
          message: 'Are you sure you want to delete this item?',
          successCallback: () => {
            deleteContact( row.uid, {
              onSuccess: () => {
                setToast( {
                  message: 'Contact deleted successfully',
                  type: 'success',
                } )
              },
            } )
          },
        } )
      },
      label: 'Delete',
    },
  ]

  const columns = [
    { key: 'first_name' },
    { key: 'last_name' },
    { key: 'email' },
    {
      extractData: ( value ) => dateFormatter( value ),
      key: 'created',
    },
    { key: 'city' },
    {
      extractData: ( val, row ) =>
        !!row.country ? states?.[row.country]?.[val] : '-',
      key: 'state',
    },
    {
      extractData: ( val ) => countries?.[val] ?? '-',
      key: 'country',
    },
    {
      extractData: ( field ) => field.map( ( sector ) => sector.name ).join( ', ' ),
      key: 'respondent_sectors',
      title: 'Sectors',
    },
    {
      extractData: ( field ) => field.map( ( segment ) => segment.name ).join( ', ' ),
      key: 'respondent_segments',
      title: 'Segments',
    },
  ]

  const openAddModal = () => {
    history.push( '/email-manager/add' )
  }

  const Buttons = () => (
    <>
      {!showAddContactsToSector && (
        <AddContactsToSegmentsButton
          showBtn={ showAddContactsToSegment }
          setShowBtn={ setshowAddContactsToSegment }
          withCheckboxes={ withCheckboxes }
          segmentUid={ segmentUid }
          setSegmentUid={ setSegmentUid }
        />
      )}
      {!showAddContactsToSegment && (
        <AddContactsToSectorsButton
          showBtn={ showAddContactsToSector }
          setShowBtn={ setshowAddContactsToSector }
          withCheckboxes={ withCheckboxes }
          sectorUid={ sectorUid }
          setSectorUid={ setSectorUid }
        />
      )}

      <button
        className='primary'
        onClick={ openAddModal }
        disabled={ showAddContactsToSegment || showAddContactsToSector }
      >
        <Icon IconComponent={ AddIcon } className='icon-md icon-white' /> Add new
        contact
      </button>
    </>
  )

  const filterButtonConfig = {
    FilterComponent: ContactFilters,
    setFilters,
  }

  return (
    <PageContent>
      <DataTable
        endpoint='/api/respondent'
        heading='Contacts'
        columns={ columns }
        actionsContent={ actionsContent }
        Buttons={ Buttons }
        onRowClick={ () => {} }
        withCheckboxes={
          ( showAddContactsToSegment || showAddContactsToSector )
          && withCheckboxes
        }
        defaultSorting='-modified'
        filters={ filters }
        filterButtonConfig={ filterButtonConfig }
      />
    </PageContent>
  )
}

export default ContactsTab

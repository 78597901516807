import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import './ContactAdd.scss'

import optionManualImg from '../../../../assets/images/email-manager/add-contact.png'
import optionCsvImg from '../../../../assets/images/email-manager/import-csv.png'

import PageContent from '../../../../components/PageContent'

const ContactAdd = () => {
  const history = useHistory()
  const [ mode, setMode ] = useState( 'manual' ) // pageTabsData element


  const nextStep = () => {
    if( 'manual' === mode ){
      history.push( '/email-manager/add-manual' )
    }else{
      history.push( '/email-manager/import-csv' )
    }
  }

  return ( <PageContent className='contact-add' isModal={ true }>
    <h2>Add New Contacts</h2>
    <div className= 'subtitle' >Please select an option to add new contacts</div>
    <div className='options' >
      <div className={ `option-csv ${ 'csv' === mode ? 'active' : '' }` } onClick={ () => setMode( 'csv' ) } >
        <div className='image'>
          <img alt='Import from CSV file' src={ optionCsvImg } />
        </div>
        <h5>Import from CSV file</h5>
      </div>
      <div className={ `option-manual ${ 'manual' === mode ? 'active' : '' }` } onClick={ () => setMode( 'manual' ) } >
        <div className='image'>
          <img alt='Enter Manually' src={ optionManualImg } />
        </div>
        <h5>Enter Manually</h5>
      </div>
      <button className='primary' onClick={ nextStep } >Continue</button>
    </div>
  </PageContent> )
}

export default ContactAdd

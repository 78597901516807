import React, { useContext, useState } from 'react'

import UiContext from '../../../../../common/UiContext/UiContext'
import MatrixStats from '../MatrixStats/MatrixStats'
import McqStats from '../McqStats'
import SelectLeader from '../SelectLeader'

const LEADER_QUESTION = 'Who is the Leader you are reviewing?'

const QuestionContainer = ( { question } ) => {
  const { pageTabsData, setActivePageTab } = useContext( UiContext )

  const [ chartType, setChartType ] = useState( 'Doughnut' )

  const handleChartTypeChange = ( e ) => {
    setChartType( e.target.value )
  }

  const viewIndividualResponses = () => {
    setActivePageTab(
      pageTabsData.find( ( el ) => 'Individual Responses' === el.label ),
    )
  }

  return (
    <div className='tile question-container'>
      <div className='row'>
        <h5 className='question-title' data-number={ `Q${ question.order }` }>
          {question.question_title}
        </h5>

        {( 'Oneliner' === question.question_type
          || 'Descriptive' === question.question_type ) && (
          <p>
            Charts don't apply to this question.{' '}
            <span
              className='view-responses-btn'
              onClick={ viewIndividualResponses }
            >
              View Individual Responses
            </span>
          </p>
        )}

        {'MCQ' === question.question_type
          && question.question_title !== LEADER_QUESTION && (
          <select
            name='chartType'
            value={ chartType }
            onChange={ handleChartTypeChange }
          >
            <option value='Bar'>Bar Chart</option>
            <option value='Doughnut'>Doughnut Chart</option>
          </select>
        )}
      </div>

      <div className='info subtle'>
        {'Matrix' !== question.question_type && (
          <span>Total Responses: {question.total_responses}</span>
        )}
        <span>Type of question: {question.question_type}</span>
      </div>

      {question.question_title === LEADER_QUESTION && <SelectLeader />}

      {'MCQ' === question.question_type
        && question.question_title !== LEADER_QUESTION && (
        <McqStats question={ question } chartType={ chartType } />
      )}

      {'Matrix' === question.question_type && (
        <MatrixStats question={ question } />
      )}
    </div>
  )
}

export default QuestionContainer

import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'

import useApi from '../../../../common/useApi'
import FormLabel from '../../../../components/Forms/Helpers/FormLabel'
import UiContext from '../../../../common/UiContext'
import useDebounce from '../../../../common/useDebounce'

const SurveyDetails = ( { defaultValues } ) => {
  const { uid } = useParams()
  const { setShowTabsLoading } = useContext( UiContext )

  const [ form, setForm ] = useState( {
    ...defaultValues,
    description: defaultValues.description,
    name: defaultValues.name,
  } )
  const [ isFormDirty, setIsFormDirty ] = useState( false )

  const { mutate, isLoading } = useApi( 'put', `/api/survey/${ uid }`, form, { enabled: false } )

  const debouncedSave = useDebounce( mutate )

  useEffect( () => {
    !isFormDirty && setIsFormDirty( true )

    if ( handleValidation() && isFormDirty ) {
      debouncedSave()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ form ] )

  useEffect( () => {
    setShowTabsLoading( isLoading )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isLoading ] )

  const handleChange = ( { name, value } ) => {
    setForm( ( old ) => ( {
      ...old,
      [name]: value,
    } ) )
  }

  const handleValidation = () => {
    if ( !form.name ) {
      return false
    }
    return true
  }

  return (
    <form>
      <fieldset>
        <legend>Survey Details</legend>
        <FormLabel name='name' field='Survey Name'>
          <input
            type='text'
            placeholder='Survey Name'
            name='name'
            value={ form.name }
            onChange={ ( e ) => handleChange( e.target ) }
          />
        </FormLabel>

        <FormLabel name='description' field='Survey Description'>
          <textarea
            name='description'
            value={ form.description }
            onChange={ ( e ) => handleChange( e.target ) }
            rows='4'
            placeholder='Survey Description'
            className='full-width'
          ></textarea>
        </FormLabel>
      </fieldset>
    </form>
  )
}

export default SurveyDetails

import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { CancelIcon } from '../../../../assets/icons'
import useApi, { UseInvalidateEndpoint } from '../../../../common/useApi'
import FormLabel from '../../../../components/Forms/Helpers/FormLabel/FormLabel'
import Icon from '../../../../components/Icon/Icon'
import UiContext from '../../../../common/UiContext'
import useDebounce from '../../../../common/useDebounce/useDebounce'

const MatrixRow = ( { defaultValues, create, handleCreated, cancelCreate } ) => {
  const { uid } = useParams()
  const { setShowTabsLoading } = useContext( UiContext )

  const invalidateEndpoint = UseInvalidateEndpoint()

  const [ form, setForm ] = useState( {
    ...defaultValues,
    title: defaultValues?.title ?? '',
  } )
  const [ isFormDirty, setIsFormDirty ] = useState( false )

  const method = create ? 'post' : 'put'
  const endpoint = create
    ? '/api/question/matrix'
    : `/api/question/matrix/${ defaultValues.uid }`

  const { mutate, isSuccess, isLoading } = useApi( method, endpoint, form )

  const debouncedSave = useDebounce( mutate )

  useEffect( () => {
    !isFormDirty && setIsFormDirty( true )

    if ( handleValidation() && isFormDirty ) {
      debouncedSave( form )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ form ] )

  useEffect( () => {
    if ( isSuccess ) {
      invalidateEndpoint( `/api/survey/${ uid }` )
      create && handleCreated()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isSuccess, create ] )

  useEffect( () => {
    setShowTabsLoading( isLoading )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isLoading ] )

  const handleChange = ( { name, value } ) => {
    setForm( ( old ) => ( {
      ...old,
      [name]: value,
    } ) )
  }

  const handleValidation = () => {
    if ( !form.title ) {
      return false
    }
    return true
  }

  const {
    mutate: remove,
    isSuccess: rowDeleted,
    isError,
  } = useApi( 'delete', '/api/question/matrix' )

  useEffect( () => {
    if ( rowDeleted ) {
      invalidateEndpoint( `/api/survey/${ uid }` )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ rowDeleted ] )

  useEffect( () => {
    if ( isError && !defaultValues.uid ) {
      cancelCreate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isError ] )

  return (
    <>
      <FormLabel className='indented' field='Row Label' name='title'>
        <Icon
          IconComponent={ CancelIcon }
          className='size-2 danger array-field-remove'
          onClick={ () => remove( defaultValues.uid ) }
        />
        <input
          name='title'
          onChange={ ( e ) => handleChange( e.target ) }
          type='text'
          value={ form.title }
        />
      </FormLabel>
    </>
  )
}

export default MatrixRow

import React, { useState } from 'react'

import { AddIcon } from '../../../../assets/icons'
import Icon from '../../../../components/Icon/Icon'
import MatrixRow from './MatrixRow'

const AddMatrixRow = ( { order, question } ) => {
  const [ add, setAdd ] = useState( false )

  const defaultValues = {
    is_row_header: true,
    order,
    question,
    title: '',
  }

  return (
    <>
      {add && (
        <MatrixRow
          create={ true }
          defaultValues={ defaultValues }
          handleCreated={ () => setAdd( false ) }
          cancelCreate={ () => setAdd( false ) }
        />
      )}

      <button className='tertiary indented' onClick={ () => setAdd( true ) }>
        <Icon IconComponent={ AddIcon } className='primary size-1' />
        Add Matrix Row
      </button>
    </>
  )
}

export default AddMatrixRow

import Dashboard from './Dashboard'
import EmailManager from './EmailManager'
import MasterSurvey from './MasterSurvey'
import Reports from './Reports'
import SurveyBuilder from './SurveyBuilder'

export default {
  Dashboard,
  EmailManager,
  MasterSurvey,
  Reports,
  SurveyBuilder,
}

import React, { useContext, useState } from 'react'

import { DocxIcon, CsvIcon } from '../../../assets/icons'

import UiContext from '../../../common/UiContext'
import MenuButton from '../../../components/MenuButton/MenuButton'

import httpClient from '../../../common/useApi/httpClient'
import { UseInvalidateEndpoint } from '../../../common/useApi'

import SelectLeaderModal from './SelectLeaderModal'

const ExportSurveyButton = ( { surveyUid, leader, setLeader } ) => {
  const invalidateEndpoint = UseInvalidateEndpoint()

  const { setToast, openModal } = useContext( UiContext )

  const [ isExporting, setIsExporting ] = useState( false )

  const exportSurvey = async ( leader, format ) => {
    // format = csv | docx
    setIsExporting( true )

    try {
      const response = await httpClient.get(
        `/api/survey/${ surveyUid }/export-${ format }/`,
        { params: { leader: leader.uid } },
      )

      setIsExporting( false )
      setToast( {
        message: 'Your report is queued',
        type: 'success',
      } )

      invalidateEndpoint( `/api/survey/${ surveyUid }/reports` )

      return response
    } catch ( error ) {
      if ( 400 === error.response.status ) {
        setToast( {
          message: error.data.message,
          type: 'info',
        } )
      } else {
        setToast( {
          message: 'An Error Occured',
          type: 'error',
        } )
      }
    }
    setIsExporting( false )
  }

  const handleClick = ( format ) => {
    if ( !leader ) {
      openModal( () => () => (
        <SelectLeaderModal
          updateLeaderContext={ setLeader }
          surveyUid={ surveyUid }
          successCallback={ ( leader ) => exportSurvey( leader, format ) }
        />
      ) )
    } else {
      exportSurvey( leader, format )
    }
  }

  const dropdownContent = [
    {
      IconComponent: CsvIcon,
      color: 'primary',
      func: () => handleClick( 'csv' ),
      label: 'CSV',
    },
    {
      IconComponent: DocxIcon,
      color: 'primary',
      func: () => handleClick( 'docx' ),
      label: 'Word',
    },
  ]

  return (
    <MenuButton
      title={ isExporting ? 'Exporting...' : 'Export Survey Report' }
      dropdownContent={ dropdownContent }
      color={ isExporting ? 'accent' : 'primary' }
      isLoading={ isExporting }
    />
  )
}

export default ExportSurveyButton

import { useContext } from 'react'
import Cookie from 'js-cookie'
import { useHistory } from 'react-router-dom'

import useApi from '../../common/useApi'
import httpClient from '../useApi/httpClient'
import AuthContext from './auth'

const useLogout = () => {
  const history = useHistory()

  const { setUserData } = useContext( AuthContext )

  const { mutateAsync, isError } = useApi( 'post', '/api/auth/logout' )

  const deleteAuthorizationCookie = () => {
    Cookie.remove( 'Authorization' )

    // for handling logout when used a impersonated login, the cookie's domain is different ( eg. .ac.com)
    const index = window.location.hostname.indexOf( '.' )
    const domain = window.location.hostname.slice( index )

    // cookie has to removed with options object
    Cookie.remove( 'Authorization', {
      domain,
      path: '/',
    } )
  }

  const deleteCookies = () => {
    delete httpClient.defaults?.headers['Authorization']
    // if suppose AuthContext is not available
    !!setUserData && setUserData( null )

    deleteAuthorizationCookie()

    Cookie.remove( 'csrftoken' )
    Cookie.remove( 'sessionid' )

    history.push( '/login' )
  }

  const logout = () => {
    mutateAsync().then( ( data ) => {
      if ( data?.redirect_url ) {
        // this session is impersonated
        // so we only want to delete the Authorization cookie, the rest are used for django admin
        window.location.href = data.redirect_url
        deleteAuthorizationCookie()
        return
      }
      deleteCookies()
    } )
  }

  return {
    deleteCookies,
    error: isError,
    logout,
  }
}

export default useLogout

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'

import './SurveyForm.scss'

import useApi from '../../../../common/useApi'
import Loader from '../../../../components/Loader'
import Toggle from '../../../../components/Toggle'

import Question from './Question'
import AddQuestion from './AddQuestion'
import SurveyDetails from './SurveyDetails'

const SurveyForm = ( { surveyUid } ) => {
  const { uid } = useParams()

  const {
    data: survey,
    isLoading,
    isSuccess,
  } = useApi( 'get', `/api/survey/${ surveyUid ?? uid }`, null, { cacheTime: 0 } )

  const { mutate } = useApi(
    'post',
    `/api/survey/${ surveyUid ?? uid }/make-questions-required`,
  )

  const [ makeAllMandatory, setMakeAllMandatory ] = useState( false )

  useEffect( () => {
    if ( isSuccess ) {
      // survey loaded
      // check if all questions are required change the mandatory toggle to true
      const allQuestionCount = survey.questions.length

      const requiredQuestionsCount = survey.questions.filter(
        ( { is_required } ) => is_required,
      ).length

      if ( allQuestionCount === requiredQuestionsCount ) {
        setMakeAllMandatory( true )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isSuccess ] )

  const handleMandatoryToggle = ( val ) => {
    setMakeAllMandatory( val )

    if ( val ) mutate()
  }

  // used for linked question field in matrix type question
  const getAllMcqs = () =>
    survey.questions.filter( ( q ) => 'MCQ' === q.question_type )

  // this is to pass the correct props and re-render once the survey questions update
  // question.uid remains same so the props received are the old ones
  const getQuestionKey = ( question ) =>
    question.uid
      + ( question.matrix_row?.length ?? 0 )
      + question.choices?.length ?? 0

  if ( isLoading ) {
    return <Loader />
  }

  return (
    <div className='page-container'>
      <SurveyDetails defaultValues={ survey } />

      <fieldset>
        <legend>
          Questions
          <Toggle
            label='Make all questions mandatory'
            labelPosition='left'
            checked={ makeAllMandatory }
            onChange={ ( e ) => handleMandatoryToggle( e.target.checked ) }
          />
        </legend>

        {survey.questions.map( ( question, index ) => (
          <Question
            defaultValues={ {
              ...question,
              order: index + 1,
            } }
            questionNumber={ index + 1 }
            makeAllMandatory={ makeAllMandatory }
            setMakeAllMandatory={ setMakeAllMandatory }
            key={ getQuestionKey( question ) }
            getAllMcqs={ getAllMcqs }
          />
        ) )}

        <AddQuestion
          questionNumber={ survey.questions.length + 1 }
          getAllMcqs={ getAllMcqs }
        />
      </fieldset>
    </div>
  )
}

export default SurveyForm

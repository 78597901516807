import React from 'react'
import { useFormContext } from 'react-hook-form'
import FormLabel from './Helpers/FormLabel/FormLabel'

const UserProfileForm = () => {
  const { register, formState } = useFormContext()

  return (
    <>
      <FormLabel
        name='last_name'
        field='Email Address'
        error={ formState.errors?.email }
        required
      >
        <input
          type='email'
          { ...register( 'email', { required: 'This field is required' } ) }
          placeholder='Email Address'
          className={ formState.errors?.email && 'error' }
        />
      </FormLabel>

      <FormLabel
        name='first_name'
        field='First Name'
        error={ formState.errors?.first_name }
        required
      >
        <input
          type='text'
          { ...register( 'first_name', { required: 'This field is required' } ) }
          placeholder='First Name'
          className={ formState.errors?.first_name && 'error' }
        />
      </FormLabel>

      <FormLabel
        name='last_name'
        field='Last Name'
        error={ formState.errors?.last_name }
        required
      >
        <input
          type='last_name'
          { ...register( 'last_name', { required: 'This field is required' } ) }
          placeholder='Last Name'
          className={ formState.errors?.last_name && 'error' }
        />
      </FormLabel>

      <FormLabel name='phone' field='Phone' error={ formState.errors?.phone } required>
        <input
          type='phone'
          { ...register( 'phone', { required: 'This field is required' } ) }
          placeholder='Phone'
          className={ formState.errors?.phone && 'error' }
        />
      </FormLabel>

      <button className='primary' type='submit'>
        Save Profile
      </button>
    </>
  )
}

export default UserProfileForm

import React, { useContext } from 'react'

import UiContext from '../../../common/UiContext'
import CreateSurveyForm from '../../../components/Forms/CreateSurveyForm'
import Form from '../../../components/Forms/Form/Form'

const CreateSurvey = () => {
  const { closeModal } = useContext( UiContext )

  return (
    <Form
      method='post'
      endpoint='/api/survey'
      FormBody={ ( props ) => CreateSurveyForm( props ) }
      onSuccess={ closeModal }
    />
  )
}

export default CreateSurvey

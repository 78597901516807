import React from 'react'
import { uniqueId } from 'lodash'

import './Toggle.scss'

const Toggle = ( { label, labelPosition = 'right', size = 'md', ...rest } ) => {
  const id = uniqueId()

  return (
    <div className='toggle-container'>
      {'left' === labelPosition && <span>{label}</span>}
      <input className='toggle' id={ id } type='checkbox' { ...rest } />
      <label className={ `toggle-btn ${ size }` } htmlFor={ id } />
      {'right' === labelPosition && <span>{label}</span>}
    </div>
  )
}

export default Toggle

/**
 * size = sm | md
 */

import React, { useEffect, useRef } from 'react'

import './RadarChart.scss'

const gridStrokeColor = '#E6E9F1',
      gridStrokeWidth = 1,
      labelsColor = '#6C7B9B'

const RadarChart = ( { statsData, size = 200, labels, maxValue = 5 } ) => {
  const canvas = useRef()

  useEffect( () => {
    if ( canvas.current ) {
      const dimensions = getObjectFitSize(
        true,
        canvas.current.clientWidth,
        canvas.current.clientHeight,
        canvas.current.width,
        canvas.current.height,
      )

      canvas.current.width = dimensions.width
      canvas.current.height = dimensions.height

      const ctx = canvas.current.getContext( '2d' )

      const radiusStep = size / maxValue

      const Xcenter = dimensions.width / 2,
            Ycenter = dimensions.height / 2
      let circleValue = 0

      ctx.fillText( circleValue, Xcenter, Ycenter - 5 )

      for ( let r = radiusStep; r <= size; r += radiusStep ) {
        drawCircle(
          ctx,
          dimensions.width / 2,
          dimensions.height / 2,
          r,
          gridStrokeColor,
          gridStrokeWidth,
        )
        circleValue++
        ctx.fillText( circleValue, Xcenter + r + 2, Ycenter - 5 )
      }

      drawRadarLabels( ctx, dimensions, labels )

      drawGridLines( ctx, dimensions, statsData[0].stats.length )

      statsData.forEach( ( { stats, color } ) => {
        drawRadar( ctx, dimensions, stats, color )
      } )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ canvas, statsData ] )

  const drawRadar = ( ctx, dimensions, stats, strokeColor ) => {
    // draws 1 radar
    const Xcenter = dimensions.width / 2,
          Ycenter = dimensions.height / 2,
          numberOfSides = stats.length

    ctx.strokeStyle = strokeColor
    ctx.lineWidth = 2
    ctx.beginPath()

    // first point
    const firstX = Xcenter + size * Math.cos( 0 ) * stats[0]
    const firstY = Ycenter + size * Math.sin( 0 ) * stats[0]

    ctx.moveTo( firstX, firstY )

    for ( let i = 0; i <= numberOfSides; i += 1 ) {
      // multiplying by -1 to go anti-clockwise
      const x
        = Xcenter + size * Math.cos( ( i * 2 * Math.PI * -1 ) / numberOfSides ) * stats[i]
      const y
        = Ycenter + size * Math.sin( ( i * 2 * Math.PI * -1 ) / numberOfSides ) * stats[i]

      ctx.lineTo( x, y )
      ctx.stroke()

      // ctx.beginPath()
      ctx.arc( x, y, 3, 0, 2 * Math.PI, false )
      ctx.fillStyle = strokeColor
      // ctx.fill()
    }

    // connect last point to first point
    ctx.lineTo(
      Xcenter + size * Math.cos( 0 ) * stats[0],
      Ycenter + size * Math.sin( 0 ) * stats[0],
    )
    ctx.stroke()
  }

  const drawRadarLabels = ( ctx, dimensions, labels ) => {
    // draws radar labels
    const _size = size + 25,
          numberOfSides = labels.length
    const Xcenter = dimensions.width / 2,
          Ycenter = dimensions.height / 2

    ctx.font = '15px Arial'
    ctx.fillStyle = labelsColor
    ctx.direction = 'ltr'
    ctx.strokeStyle = 'transparent'
    ctx.beginPath()

    // first point
    ctx.moveTo( Xcenter + _size * Math.cos( 0 ), Ycenter + _size * Math.sin( 0 ) )

    for ( let i = 1; i <= numberOfSides; i += 1 ) {
      // multiplying by -1 to go anti-clockwise
      const x = Xcenter + _size * Math.cos( ( i * 2 * Math.PI * -1 ) / numberOfSides )
      const y = Ycenter + _size * Math.sin( ( i * 2 * Math.PI * -1 ) / numberOfSides )

      ctx.moveTo( x, y )

      if ( 0 > Math.cos( ( i * 2 * Math.PI ) / numberOfSides ) ) {
        // 90 -> 270 cos is negative
        // left half of the circle
        ctx.direction = 'rtl'
      } else {
        ctx.direction = 'ltr'
      }
      ctx.fillText( labels[i] ?? labels[0], x, y )
    }
  }

  const drawGridLines = ( ctx, dimensions, numberOfSides ) => {
    const Xcenter = dimensions.width / 2,
          Ycenter = dimensions.height / 2

    ctx.strokeStyle = gridStrokeColor
    ctx.lineWidth = gridStrokeWidth
    ctx.beginPath()

    // first point
    ctx.moveTo( Xcenter + size * Math.cos( 0 ), Ycenter + size * Math.sin( 0 ) )
    ctx.lineTo( Xcenter, Ycenter )

    for ( let i = 1; i <= numberOfSides; i += 1 ) {
      const x = Xcenter + size * Math.cos( ( i * 2 * Math.PI ) / numberOfSides )
      const y = Ycenter + size * Math.sin( ( i * 2 * Math.PI ) / numberOfSides )

      ctx.moveTo( x, y )
      ctx.lineTo( Xcenter, Ycenter )
    }

    ctx.stroke()
  }

  const getObjectFitSize = (
    contains /* true = contain, false = cover */,
    containerWidth,
    containerHeight,
    width,
    height,
  ) => {
    // NOTE: this function is experimental
    // canvas was getting pixelated, because css was strecthing the canvas to fit it into the parent container
    const doRatio = width / height
    const cRatio = containerWidth / containerHeight
    let targetWidth = 0
    let targetHeight = 0
    const test = contains ? doRatio > cRatio : doRatio < cRatio

    if ( test ) {
      targetWidth = containerWidth
      targetHeight = targetWidth / doRatio
    } else {
      targetHeight = containerHeight
      targetWidth = targetHeight * doRatio
    }

    return {
      height: targetHeight,
      width: targetWidth,
      x: ( containerWidth - targetWidth ) / 2,
      y: ( containerHeight - targetHeight ) / 2,
    }
  }

  const drawCircle = ( ctx, x, y, radius, stroke, strokeWidth ) => {
    ctx.beginPath()
    ctx.arc( x, y, radius, 0, 2 * Math.PI, false )
    if ( stroke ) {
      ctx.lineWidth = strokeWidth
      ctx.strokeStyle = stroke
      ctx.stroke()
    }
  }

  return (
    <>
      <div className='radar-chart-container'>
        <canvas ref={ canvas } id='canvas' height='500' width='1000'></canvas>
      </div>
    </>
  )
}

export default RadarChart

/**
 * statsData = [
  {
    color: 'coral',
    stats: [ 0.25, 0.45, 0.55, 0.4, 0.6, 1 ],
  },
  {
    color: 'green',
    stats: [ 0.3, 0.35, 0.65, 0.1, 0.9, 0.2 ],
  },
  {
    color: 'cyan',
    stats: [ 0.5, 0.15, 0.15, 0.2, 0.3, 0.4 ],
  },
]
labels = [
    'Leadership',
    'Agility',
    'Strength',
    'Meaning',
    'Delivery',
    'Creativity',
  ]
 */

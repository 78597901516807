import { useState } from 'react'

const useFilterModal = () => {
  const [ isOpen, setIsOpen ] = useState( false )
  const [ FilterComponent, setFilterComponent ] = useState( null )

  const openFilterModal = ( Component ) => {
    setFilterComponent( Component )
    setIsOpen( true )
  }

  const closeFilterModal = () => {
    setFilterComponent( null )
    setIsOpen( false )
  }

  return {
    FilterComponent,
    closeFilterModal,
    isOpen,
    openFilterModal,
  }
}

export default useFilterModal

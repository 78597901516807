const COLORS = [
  '#40995e',
  '#06262d',
  '#5dc744',
  '#d1550f',
  '#dbb335',
  '#f89828',
  '#fdc500',
  '#00a89c',
  '#3c8bc9',
  '#8b57be',
  '#ff7373',
]

export default COLORS

import React, { forwardRef } from 'react'
import { useFormContext } from 'react-hook-form'

// import './FormLabel.scss'

const FormLabel = forwardRef(
  ( { children, name, field, className, required }, ref ) => {
    const { formState } = useFormContext() || { formState: null }

    return (
      <label
        htmlFor={ name }
        className={ `form-label ${ className } ${ required && 'required' }` }
      >
        <span>{field}</span>
        {children}
        <span className='helper-text error'>
          {formState?.errors[name]?.message}
        </span>
      </label>
    )
  },
)

export default FormLabel

import React from 'react'
import { useFormContext } from 'react-hook-form'

import FormLabel from './Helpers/FormLabel/FormLabel'

const SegmentForm = ( { isLoading, segment, entries } ) => {
  const { register, formState } = useFormContext()

  return (
    <>
      <h4>Save Segment</h4>

      <hr className='divider' />

      <FormLabel name='name' field='Segment Name' required>
        <input
          type='text'
          { ...register( 'name', { required: 'This field is required' } ) }
          placeholder='Segment Name'
          className={ formState.errors?.name && 'error' }
        />
      </FormLabel>

      <button
        className='primary full-width'
        disabled={ isLoading || !formState.isValid }
      >
        {!segment ? 'Create Segment' : 'Save'}
      </button>
    </>
  )
}

export default SegmentForm

import React, { useState, useEffect, useRef, useCallback } from 'react'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'

import './MultipleChoiceFilter.scss'

import { AddIcon, CloseIcon, CheckIcon } from '../../assets/icons'
import useOnClickOutside from '../../common/useOnClickOutside/useOnClickOutside'
import Icon from '../Icon'
import FormLabel from '../Forms/Helpers/FormLabel/FormLabel'

const MultipleChoiceFilter = ( {
  choices,
  setChoice,
  placeholder = 'Select',
  selected,
  onAddChoice = ( val ) => {},
  onRemoveChoice = ( val ) => {},
  handleCreate = () => {},
  isCreatable = !false,
} ) => {
  const [ internalChoices, setInternalChoices ] = useState(
    selected ? selected.split( ',' ) : [],
  )
  const [ open, setOpen ] = useState( false )
  const [ showCreateInput, setShowCreateInput ] = useState( false )
  const [ newOne, setNewOne ] = useState( '' )

  const ref = useRef( null )
  const clickOutside = useRef( null )

  useOnClickOutside( clickOutside, () => setOpen( false ) )

  useEffect( () => {
    // if the `selected` prop changes update internal choices
    // for handling the newly created item ( creatable mode )
    setInternalChoices( selected ? selected.split( ',' ) : [] )
  }, [ selected ] )

  useEffect( () => {
    setNewOne( '' )
  }, [ showCreateInput ] )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _setChoiceMemoCallback = useCallback( setChoice, [] )

  const hasSelectedChoices = 0 < internalChoices.length
  const selectedValues = hasSelectedChoices
    ? internalChoices.map( ( choiceKey ) => choices[choiceKey] ).join( ',' )
    : placeholder

  const toggleOpen = ( e ) => {
    if ( 'SPAN' !== e.target.nodeName && e.target !== e.currentTarget ) {
      return
    }
    setOpen( ( old ) => !old )
  }

  const addOrRemoveChoice = ( e ) => {
    e.stopPropagation()
    const value = e.target.value ?? e.target.getAttribute( 'data' )

    if ( e.target.checked ) {
      // adding a choice
      setInternalChoices( ( old ) => [ ...old, value ] )
      onAddChoice( value )
    } else {
      // removing a choice
      setInternalChoices( ( old ) =>
        old.filter( ( internalChoice ) => internalChoice !== value ),
      )
      onRemoveChoice( value )
    }
  }

  useEffect( () => {
    if ( !open ) {
      _setChoiceMemoCallback(
        hasSelectedChoices ? internalChoices.join( ',' ) : null,
      )
    }
  }, [ hasSelectedChoices, internalChoices, _setChoiceMemoCallback, open ] )

  return (
    <div className='multipleChoice' ref={ clickOutside }>
      <div
        className={ classNames( {
          empty: !hasSelectedChoices,
          'multiple-select': true,
        } ) }
        onClick={ toggleOpen }
      >
        {!hasSelectedChoices
          ? placeholder
          : selectedValues.split( ',' ).map( ( val, index ) => (
            <span
              key={ index }
              onClick={ addOrRemoveChoice }
              checked={ false }
              data={ internalChoices[index] }
              className='chip'
            >
              {val}
            </span>
          ) )}

        {isCreatable && (
          <span
            className='add-new'
            onClick={ ( e ) => {
              e.stopPropagation()
              setShowCreateInput( !showCreateInput )
            } }
          >
            <Icon
              IconComponent={ !showCreateInput ? AddIcon : CloseIcon }
              className='size-1 primary'
            />
          </span>
        )}
      </div>

      <CSSTransition
        appear
        classNames='slide-down'
        in={ open }
        mountOnEnter
        unmountOnExit
        nodeRef={ ref }
        timeout={ 50 }
      >
        <div ref={ ref } className='filterMultipleChoiceChoices'>
          {Object.entries( choices )
            .sort( ( e1, e2 ) => {
              if ( e1[1].toLowerCase() > e2[1].toLowerCase() ) return 1
              if ( e1[1].toLowerCase() < e2[1].toLowerCase() ) return -1
              return 0
            } )
            .map( ( [ key, value ] ) => (
              <label key={ key }>
                <input
                  type='checkbox'
                  value={ key }
                  checked={ internalChoices.includes( key ) }
                  onChange={ addOrRemoveChoice }
                />
                <span>{value}</span>
              </label>
            ) )}
        </div>
      </CSSTransition>

      {showCreateInput && (
        <div className='create-input'>
          <FormLabel>
            <input
              type='text'
              value={ newOne }
              onChange={ ( e ) => setNewOne( e.target.value ) }
              placeholder='Create new one'
              autoFocus
            />
            {Object.values( choices ).find( ( val ) => val === newOne.trim() ) && (
              <span className='helper-text error'>
                This item already exists
              </span>
            )}
          </FormLabel>

          <button
            className='primary icon-button rounded'
            onClick={ () => {
              handleCreate( newOne )
              setShowCreateInput( false )
            } }
            type='button'
          >
            <Icon IconComponent={ CheckIcon } className='light size-2' />
          </button>
          <button
            className='secondary icon-button rounded'
            onClick={ () => setShowCreateInput( false ) }
            type='button'
          >
            <Icon IconComponent={ CloseIcon } className='dark size-1' />
          </button>
        </div>
      )}
    </div>
  )
}

export default MultipleChoiceFilter

import React, { useContext, useState } from 'react'
import { useQueryClient } from 'react-query'

import { AddIcon, EditIcon, TrashIcon } from '../../../assets/icons'

import PageContent from '../../../components/PageContent/PageContent'
import Icon from '../../../components/Icon'
import DataTable from '../../../components/DataTable/DataTable'
import UiContext from '../../../common/UiContext'

import SegmentModal from './SegmentModal'
import useApi from '../../../common/useApi'
import ConfirmModal from '../../../components/ConfirmModal'
import SegmentFilters from './SegmentFilters'

const SegmentsTab = () => {
  const { setToast, openModal } = useContext( UiContext )

  const queryClient = useQueryClient()

  const { mutate: deleteSegment } = useApi( 'delete', '/api/respondent/segment' )

  const [ filters, setFilters ] = useState( {} )

  const actionsContent = [
    {
      IconComponent: EditIcon,
      color: 'primary',
      func: ( row ) => {
        openModal( () => () => <SegmentModal segment={ row } /> )
      },
      label: 'Edit',
    },
    {
      IconComponent: TrashIcon,
      color: 'danger',
      func: ( row ) => {
        ConfirmModal( {
          message: 'Are you sure you want to delete this item?',
          successCallback: () => {
            deleteSegment( row.uid, {
              onSuccess: () => {
                setToast( {
                  message: 'Segment deleted successfully',
                  type: 'success',
                } )
                queryClient.invalidateQueries( '/api/respondent/segment' )
              },
            } )
          },
        } )
      },
      label: 'Delete',
    },
  ]

  const columns = [
    { key: 'name' },
    {
      extractData: ( field ) => field?.length ?? 0,
      key: 'respondents',
      title: 'Total Contacts',
    },
  ]

  const openAddModal = () => {
    openModal( () => () => <SegmentModal /> )
  }

  const Buttons = () => (
    <button className='primary' onClick={ openAddModal }>
      <Icon IconComponent={ AddIcon } className='icon-md icon-white' /> Add new
      segment
    </button>
  )

  const filterButtonConfig = {
    FilterComponent: SegmentFilters,
    setFilters,
  }

  return (
    <PageContent>
      <DataTable
        endpoint='/api/respondent/segment'
        heading='Segments'
        columns={ columns }
        actionsContent={ actionsContent }
        Buttons={ Buttons }
        onRowClick={ () => {} }
        defaultSorting='-modified'
        filters={ filters }
        filterButtonConfig={ filterButtonConfig }
      />
    </PageContent>
  )
}

export default SegmentsTab

import React, { useState, useEffect, useContext } from 'react'
import UiContext from '../../../common/UiContext'
// import useApi from '../../../common/useApi/useApi'

// import FormLabel from '../../../components/Forms/Helpers/FormLabel/FormLabel'

// intentional duplication
// could not access context from the SelectLeader component
// tried to re-use that component, but later realised it's not worth the pain
const SelectLeaderModal = ( {
  updateLeaderContext,
  surveyUid,
  successCallback,
} ) => {
  const { closeModal, setToast } = useContext( UiContext )
  // const { data } = useApi( 'get', `/api/survey/${ surveyUid }/leaders` )

  const [ leader ] = useState( '' )
  const [ isLoading, setIsLoading ] = useState( false )

  useEffect( () => {
    updateLeaderContext( leader )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ leader ] )

  const handleClick = async () => {
    setIsLoading( true )
    try {
      await successCallback( leader )
      closeModal()
    } catch ( error ) {
      setToast( {
        message: 'An Error Occured',
        type: 'error',
      } )
    }
    setIsLoading( false )
  }

  return (
    <>
      <h3>Select a leader to review</h3>

      {/* <FormLabel name='leader' field='Leader'>
        <select
          name='segment'
          value={ leader }
          onChange={ ( e ) => setLeader( e.target.value ) }
        >
          <option value=''>Select Leader</option>
          {data?.results.map( ( leader ) => (
            <option value={ leader.uid } key={ leader.uid }>
              {leader.label}
            </option>
          ) )}
        </select>
      </FormLabel> */}

      <div className='modal-buttons-row'>
        <button
          className={ isLoading ? 'accent' : 'primary' }
          onClick={ handleClick }
          disabled={ isLoading }
        >
          {isLoading ? 'Loading...' : 'Confirm'}
        </button>
        <button className='secondary' onClick={ closeModal }>
          Cancel
        </button>
      </div>
    </>
  )
}

export default SelectLeaderModal

import React from 'react'

import './DoughnutChart.scss'

const DoughnutChart = ( {
  data,
  oneData = false,
  centerNumber = null,
  width = '25%',
  height = '300px',
  strokeWidth = '10',
  viewBoxDimension = '50',
} ) => {
  // circumference = 100, c = 2 * PI * r, to make sections of the doughnut with percents
  const RADIUS = 100 / ( 2 * Math.PI ),
        cx = '50%',
        cy = '50%'

  let c = 0

  return (
    <svg
      width={ width }
      height={ height }
      viewBox={ `0 0 ${ viewBoxDimension } ${ viewBoxDimension }` }
      className='donut'
    >
      <circle
        className='donut-hole'
        cx={ cx }
        cy={ cy }
        r={ RADIUS }
        fill='#fff'
      ></circle>

      {oneData && (
        <circle
          className='donut-ring'
          cx={ cx }
          cy={ cy }
          r={ RADIUS }
          fill='transparent'
          stroke='#d2d3d4'
          strokeWidth={ strokeWidth }
        ></circle>
      )}

      {data.map( ( stat, index ) => {
        const Arc = (
          <circle
            key={ `doughnut-arc_${ index }` }
            className='donut-segment'
            cx={ cx }
            cy={ cy }
            r={ RADIUS }
            fill='transparent'
            stroke={ stat.color }
            strokeWidth={ strokeWidth }
            strokeDasharray={ `${ stat.percent } ${ 100 - stat.percent }` }
            strokeDashoffset={ 0 === c ? 25 : 100 + 25 - c }
          ></circle>
        )

        c += stat.percent
        return Arc
      } )}

      {centerNumber && (
        <g className='doughnut-chart-text'>
          <text x='50%' y='50%' className='doughnut-chart-number'>
            {centerNumber}
          </text>
        </g>
      )}
    </svg>
  )
}

export default DoughnutChart

/*
data = [
  {
    color: 'coral',
    percent: 20,
  },
  {
    color: 'blue',
    percent: 10,
  },
  {
    color: 'purple',
    percent: 70,
  },
*/

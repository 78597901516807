import React, { useState } from 'react'

import { AddIcon } from '../../../../assets/icons'
import Icon from '../../../../components/Icon/Icon'
import Choice from './Choice'

const AddChoice = ( { order, question } ) => {
  const [ add, setAdd ] = useState( false )

  const defaultValues = {
    label: '',
    order,
    question,
  }

  return (
    <>
      {add && (
        <Choice
          create={ true }
          defaultValues={ defaultValues }
          handleCreated={ () => setAdd( false ) }
          cancelCreate={ () => setAdd( false ) }
        />
      )}

      <button className='tertiary indented' onClick={ () => setAdd( true ) }>
        <Icon IconComponent={ AddIcon } className='primary size-1' />
        Add Choice
      </button>
    </>
  )
}

export default AddChoice

/* eslint-disable sort-keys */
import React, { createContext, useEffect, useState } from 'react'
import useFilterModal from './useFilterModal'

const UiContext = createContext( {} )

export const UiContextProvider = ( { children } ) => {
  const [ pageTabsData, setPageTabsData ] = useState( null )

  const [ activePageTab, setActivePageTab ] = useState( null ) // pageTabsData element

  const [ showTabsLoading, setShowTabsLoading ] = useState( false )

  const [ pageTabButton, setPageTabButton ] = useState( null )

  const [ toast, setToast ] = useState( {} ) // takes toast object

  const removePageTabs = () => {
    setActivePageTab( null )
    setPageTabsData( null )
    removePageTabButton()
  }

  const removePageTabButton = () => {
    setPageTabButton( null )
  }

  const removeToast = () => setToast( {} )

  useEffect( () => {
    if ( pageTabsData ) {
      setActivePageTab( pageTabsData[0] )
    }
  }, [ pageTabsData ] )

  const [ modal, setModal ] = useState( null )

  const openModal = ( component ) => {
    setModal( component )
  }

  const closeModal = ( component ) => {
    setModal( null )
  }

  const filterModal = useFilterModal()

  return (
    <UiContext.Provider
      value={ {
        activePageTab,
        pageTabsData,
        setActivePageTab,
        setPageTabsData,
        removePageTabs,

        pageTabButton,
        setPageTabButton,
        removePageTabButton,

        toast,
        setToast,
        removeToast,

        modal,
        openModal,
        closeModal,

        showTabsLoading,
        setShowTabsLoading,

        filterModal,
      } }
    >
      {children}
    </UiContext.Provider>
  )
}

export default UiContext

/**
pageTabsData = [{
  Component:
  label:
  Icon:
}]

pageTabButton = {
  Component:
}

toast = {
  message: ''
  type: success | warning | info | error
  timeout?: number ( in ms )
}

modal = Component
 *
 */

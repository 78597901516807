import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import Form from '../../../../../components/Forms/Form/Form'
import ContactForm from '../../../../../components/Forms/ContactForm'
import UiContext from '../../../../../common/UiContext'

const ContactEditForm = ( { contact } ) => {
  const history = useHistory()

  const { closeModal } = useContext( UiContext )

  const queryClient = useQueryClient()

  const goBack = () => history.push( '/email-manager/contacts' )

  const onSuccess = () => {
    queryClient.invalidateQueries( '/api/respondent' )
    closeModal()
  }

  return (
    <>
      <Form
        method='put'
        endpoint={ `/api/respondent/${ contact.uid }` }
        onSuccess={ onSuccess }
        entries={ contact }
        FormBody={ ( props ) =>
          ContactForm( {
            ...props,
            contact,
            goBack,
            heading: 'Edit Contact',
            isContactCreated: true,
            type: 'edit',
          } )
        }
      />
    </>
  )
}

ContactEditForm.propTypes = {
  model: PropTypes.object,
  onSuccess: PropTypes.func,
}

export default ContactEditForm

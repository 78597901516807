import components from '../pages'

import capitalize from '../utils/capitalize'

const generateRouterTree = ( routerEntries ) => {
  if ( !routerEntries ) {
    return null
  }

  return routerEntries.map( ( entry ) => {
    const { title, subLinks } = entry
    let { link, componentName, showInMenu } = entry

    // if no link is given, defaults to kebab case of the title
    link = link ?? title.split( ' ' ).join( '-' ).toLowerCase()
    // if no component name is given, defaults to PascalCase of the link
    componentName
      = componentName
      ?? link
        .split( '-' )
        .map( ( chunk ) => capitalize( chunk ) )
        .join( '' )

    showInMenu = showInMenu ?? true

    // warning: recursion ahead
    return {
      component: components[componentName],
      componentName,
      link,
      showInMenu,
      subLinks: generateRouterTree( subLinks ),
      title,
    }
  } )
}

const router = generateRouterTree( [
  { title: 'Dashboard' },
  { title: 'Reports' },
  { title: 'Survey Builder' },
  { title: 'Email Manager' },
] )

export default router

export const masterClientRoutes = generateRouterTree( [
  { title: 'Master Survey' },
] )

import React, { useContext, useEffect, useState } from 'react'

import UiContext from '../../../common/UiContext/UiContext'
import useApi from '../../../common/useApi/useApi'
import FormLabel from '../../Forms/Helpers/FormLabel/FormLabel'

const ForgotPassword = ( { goToLogin, goToSuccessPage } ) => {
  const { setToast } = useContext( UiContext )

  const { mutate, isSuccess, isLoading, isError, error } = useApi(
    'post',
    '/api/auth/password/reset',
    null,
  )

  const [ email, setEmail ] = useState( '' )

  useEffect( () => {
    if ( isSuccess ) {
      goToSuccessPage()
    }
  }, [ isSuccess, goToSuccessPage ] )

  useEffect( () => {
    if ( isError ) {
      setToast( {
        message: error.data?.email ?? 'An error occurred',
        type: 'error',
      } )
    }
  }, [ isError, error, setToast ] )

  const handleSubmit = () => {
    mutate( { email } )
  }

  return (
    <form>
      <h1>Forgot Your Password?</h1>
      <h5 className='subtle'>
        Enter the email associated with your account and we’ll send and email
        with instructions to reset your password
      </h5>

      <FormLabel name='email' field='Email Address'>
        <input
          type='email'
          value={ email }
          onChange={ ( e ) => setEmail( e.target.value ) }
          placeholder='Email Address'
        />
      </FormLabel>

      <button
        className='primary'
        onClick={ handleSubmit }
        disabled={ !email || isLoading }
      >
        Submit
      </button>

      <button className='tertiary' onClick={ goToLogin } type='button'>
        Back To Login
      </button>
    </form>
  )
}

export default ForgotPassword

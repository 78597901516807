import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from 'react-query'

import './App.scss'
import 'react-datepicker/dist/react-datepicker.css'

import router from './common/router'
import Auth from './pages/Auth'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import { UiContextProvider } from './common/UiContext'
import ToastMessage from './components/ToastMessage'
import { AuthContextProvider } from './common/auth'
import NotFound from './pages/404'
import SurveyResponse from './components/SurveyResponse'
import Modal from './components/Modal'
import SurveyInviteRedirect from './pages/SurveyInviteRedirect'
import UserProfile from './pages/UserProfile'
import FilterModal from './components/FilterModal/FilterModal'
import MasterSurvey from './pages/MasterSurvey'

function App() {
  const queryClient = new QueryClient( { defaultOptions: { queries: { refetchOnWindowFocus: false } } } )

  return (
    <HelmetProvider>
      <QueryClientProvider client={ queryClient }>
        <Router>
          <AuthContextProvider>
            <UiContextProvider>
              <Switch>
                <PrivateRoute path='/' exact={ true }>
                  <Redirect to='/dashboard' />
                </PrivateRoute>

                <PrivateRoute
                  path='/master-survey/:uid'
                  component={ MasterSurvey }
                />
                <PrivateRoute
                  path='/master-survey'
                  component={ MasterSurvey }
                />

                {router.map( ( routeEntry ) => (
                  <PrivateRoute
                    component={ routeEntry.component }
                    path={ `/${ routeEntry.link }` }
                    key={ routeEntry.link }
                  />
                ) )}

                <Route
                  path='/survey/i/:survey_invite_uid'
                  component={ SurveyInviteRedirect }
                  exact
                />
                <PrivateRoute path='/profile' component={ UserProfile } />

                <Route
                  path='/survey/:survey_uid/r/:respondent_uid'
                  component={ SurveyResponse }
                  exact
                />

                <Route
                  path='/login'
                  component={ () => Auth( { defaultPage: 'Login' } ) }
                />
                <Route
                  path='/sign-up'
                  component={ () => Auth( { defaultPage: 'SignUp' } ) }
                />
                <Route
                  path='/change-password'
                  component={ () => Auth( { defaultPage: 'ChangePassword' } ) }
                />

                <PrivateRoute path='*'>
                  <NotFound />
                </PrivateRoute>
              </Switch>

              <ToastMessage />

              <Modal />

              <FilterModal />
            </UiContextProvider>
          </AuthContextProvider>
        </Router>
      </QueryClientProvider>
    </HelmetProvider>
  )
}

export default App

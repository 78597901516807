import React from 'react'

const ChangePasswordSuccess = ( { goToLogin } ) => (
  <section>
    <h1>Password Updated</h1>
    <h5 className='subtle'>
      Your password has been updated successfully!
    </h5>

    <button className='primary' onClick={ goToLogin }>
      Back To Login
    </button>
  </section>
)

export default ChangePasswordSuccess

import React, { useState } from 'react'

import './Auth.scss'

import logo from '../../assets/images/logo.svg'

// Side images
import loginPageSideImage from '../../assets/images/login-page.svg'
import forgotPasswordSideImage from '../../assets/images/forgot-password-page.svg'
import forgotPasswordSuccessSideImage from '../../assets/images/forgot-password-success-page.svg'
import changePasswordSideImage from '../../assets/images/change-password-page.svg'
import changePasswordSuccessSideImage from '../../assets/images/change-password-success-page.svg'

// Auth components
import LoginComponent from '../../components/Auth/Login'
import ForgotPasswordComponent from '../../components/Auth/ForgotPassword'
import ForgotPasswordSuccessComponent from '../../components/Auth/ForgotPasswordSuccess'
import SignUpComponent from '../../components/Auth/SignUp'
import ChangePasswordComponent from '../../components/Auth/ChangePassword'
import ChangePasswordSuccessComponent from '../../components/Auth/ChangePasswordSuccess'
import TwoFAComponent from '../../components/Auth/TwoFA'

const Auth = ( { defaultPage = 'Login' } ) => {
  // Login | TwoFA | ForgotPassword | ForgotPasswordSuccess | ChangePassword | ChangePasswordSuccess | SignUp
  const [ currentPage, setCurrentPage ] = useState( defaultPage )
  const getComponent = {
    ChangePassword() {
      return (
        <ChangePasswordComponent
          goToLogin={ () => setCurrentPage( 'Login' ) }
          goToSuccessPage={ () => setCurrentPage( 'ChangePasswordSuccess' ) }
        />
      )
    },
    ChangePasswordSuccess() {
      return (
        <ChangePasswordSuccessComponent
          goToLogin={ () => setCurrentPage( 'Login' ) }
        />
      )
    },
    ForgotPassword() {
      return (
        <ForgotPasswordComponent
          goToLogin={ () => setCurrentPage( 'Login' ) }
          goToSuccessPage={ () => setCurrentPage( 'ForgotPasswordSuccess' ) }
        />
      )
    },
    ForgotPasswordSuccess() {
      return (
        <ForgotPasswordSuccessComponent
          goToLogin={ () => setCurrentPage( 'Login' ) }
        />
      )
    },
    Login() {
      return (
        <LoginComponent
          goToForgotPassword={ () => setCurrentPage( 'ForgotPassword' ) }
          goToSuccessPage={ () => setCurrentPage( 'TwoFA' ) }
          goToSignUp={ () => setCurrentPage( 'SignUp' ) }
        />
      )
    },
    SignUp() {
      return <SignUpComponent goToLogin={ () => setCurrentPage( 'Login' ) } />
    },
    TwoFA() {
      return <TwoFAComponent goToLogin={ () => setCurrentPage( 'Login' ) } />
    },
  }

  const sideImages = {
    ChangePassword: changePasswordSideImage,
    ChangePasswordSuccess: changePasswordSuccessSideImage,
    ForgotPassword: forgotPasswordSideImage,
    ForgotPasswordSuccess: forgotPasswordSuccessSideImage,
    Login: loginPageSideImage,
    SignUp: loginPageSideImage,
    TwoFA: forgotPasswordSuccessSideImage,
  }

  return (
    <div className='auth-container'>
      <div className='content'>
        <img src={ logo } className='logo' alt='logo'></img>

        {getComponent[currentPage]()}
      </div>

      <div className='side-image-container'>
        <img src={ sideImages[currentPage] } className='logo' alt='logo'></img>
      </div>
    </div>
  )
}

export default Auth

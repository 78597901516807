import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useLocation } from 'react-router'

import Password from '../Password'
import FormLabel from './Helpers/FormLabel/FormLabel'

const SignUpForm = ( { goToLogin, isLoading } ) => {
  const { register, formState, setValue } = useFormContext()

  const location = useLocation()

  const queryParams = new URLSearchParams( location.search )

  const inviteCode = queryParams.get( 'invite_code' )

  setValue( 'invite_code', inviteCode )

  return (
    <>
      <h1>Create account</h1>
      <h5 className='subtle'>
        Enter your details here to create your account.
      </h5>

      <FormLabel name='invite_code' field='Invite Code' required>
        <input
          type='text'
          { ...register( 'invite_code', { required: 'This field is required' } ) }
          placeholder='Invite Code'
          className={ formState.errors.invite_code && 'error' }
          readOnly
        />
      </FormLabel>

      <FormLabel name='email' field='Email Address' required>
        <input
          type='email'
          { ...register( 'email', { required: 'This field is required' } ) }
          placeholder='Email Address'
          className={ formState.errors.email && 'error' }
        />
      </FormLabel>

      <Password />

      <FormLabel name='first_name' field='First Name' required>
        <input
          type='text'
          { ...register( 'first_name', { required: 'This field is required' } ) }
          placeholder='First Name'
          className={ formState.errors.first_name && 'error' }
        />
      </FormLabel>

      <FormLabel name='last_name' field='Last Name' required>
        <input
          type='text'
          { ...register( 'last_name', { required: 'This field is required' } ) }
          placeholder='Last Name'
          className={ formState.errors.last_name && 'error' }
        />
      </FormLabel>

      <FormLabel name='phone' field='Phone' required>
        <input
          type='text'
          { ...register( 'phone', { required: 'This field is required' } ) }
          className={ formState.errors.phone && 'error' }
          placeholder='Phone'
        />
        <span className='helper-text'>With area code +AAxxxxxxxxxx</span>
      </FormLabel>

      <button className='primary' disabled={ isLoading }>
        Sign Up
      </button>

      <button className='tertiary' onClick={ goToLogin } type='button'>
        Already have an account? Login
      </button>
    </>
  )
}

export default SignUpForm

import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { CancelIcon } from '../../../../assets/icons'

import useApi, { UseInvalidateEndpoint } from '../../../../common/useApi'
import FormLabel from '../../../../components/Forms/Helpers/FormLabel/FormLabel'
import Icon from '../../../../components/Icon/Icon'
import UiContext from '../../../../common/UiContext'
import useDebounce from '../../../../common/useDebounce/useDebounce'
import { leaderQuestionTitle } from '../../../../utils/constants'
import Toggle from '../../../../components/Toggle'

import Choice from './Choice'
import AddChoice from './AddChoice'
import MatrixRow from './MatrixRow'
import AddMatrixRow from './AddMatrixRow'

const Question = ( {
  defaultValues,
  questionNumber,
  create = false,
  handleCreated = () => {},
  getAllMcqs,
  cancelCreate,
  makeAllMandatory,
  setMakeAllMandatory,
} ) => {
  const { uid } = useParams()
  const { setShowTabsLoading } = useContext( UiContext )

  const invalidateEndpoint = UseInvalidateEndpoint()

  // NOTE: form is 1 question object
  const [ form, setForm ] = useState( {
    ...defaultValues,
    area: defaultValues?.area ?? '',
    is_required: defaultValues.is_required,
    linked_question: defaultValues?.linked_question,
    question_type: defaultValues?.question_type ?? '',
    scale: 5,
    title: defaultValues?.title ?? '',
  } )
  const [ isFormDirty, setIsFormDirty ] = useState( false )
  // when makeAllMandatory goes true we don't need to call the API for question
  // otherwise we will make API call for every question
  const [ makeAllMandatoryChanged, setMakeAllMandatoryChanged ] = useState( false )

  const method = create ? 'post' : 'put'
  const endpoint = create
    ? '/api/question'
    : `/api/question/${ defaultValues.uid }`

  const { mutate, isSuccess, isLoading } = useApi( method, endpoint, form, { enabled: false } )

  const debouncedSave = useDebounce( mutate )

  useEffect( () => {
    !isFormDirty && setIsFormDirty( true )

    if ( handleValidation() && isFormDirty && !makeAllMandatoryChanged ) {
      // dont call the API if makeAllMandatory flag changed
      debouncedSave( form )
    }

    // reset the flag
    makeAllMandatoryChanged && setMakeAllMandatoryChanged( false )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ form ] )

  useEffect( () => {
    if ( isSuccess ) {
      invalidateEndpoint( `/api/survey/${ uid }` )
      create && handleCreated()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isSuccess, create ] )

  useEffect( () => {
    setShowTabsLoading( isLoading )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isLoading ] )

  useEffect( () => {
    if ( makeAllMandatory ) {
      // makeAllMandatory changed here
      setMakeAllMandatoryChanged( true )

      setForm( ( oldForm ) => ( {
        ...oldForm,
        is_required: true,
      } ) )
    }
    // else {
    //   setForm( ( oldForm ) => ( {
    //     ...oldForm,
    //     is_required: defaultValues.is_required,
    //   } ) )
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ makeAllMandatory ] )

  useEffect( () => {
    if ( !form.is_required ) {
      makeAllMandatory && setMakeAllMandatory( false )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ form.is_required ] )

  const handleChange = ( { name, value } ) => {
    setForm( ( old ) => ( {
      ...old,
      [name]: value,
    } ) )
  }

  const handleValidation = () => {
    if ( !form.title ) {
      return false
    }
    if ( !form.question_type ) {
      return false
    }
    if (
      'Matrix' === form.question_type
      && ( !form.linked_question || !form.area )
    ) {
      return false
    }
    return true
  }

  const {
    mutate: remove,
    isSuccess: questionDeleted,
    isError,
  } = useApi( 'delete', '/api/question' )

  useEffect( () => {
    if ( questionDeleted ) {
      invalidateEndpoint( `/api/survey/${ uid }` )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ questionDeleted ] )

  useEffect( () => {
    if ( isError && !defaultValues.uid ) {
      cancelCreate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isError ] )

  /**
   * FIXME:
   * This is hardcoded in the frontend to restrict 1 leader per survey.
   * ( You cannot create more than 1 leader choices in leader choices for the leader question )
   * Client asked for having only 1 leader per survey.
   * This is a quick fix because there might be new type of surveys which have some different requirements.
   * So did it quickly from the frontend.
   */
  const isLeaderQuestion
    = -1 !== form.title.toLowerCase().indexOf( leaderQuestionTitle )

  return (
    <>
      <span className='question-number'>Q{questionNumber}</span>
      <FormLabel name='title' field='Question Title'>
        <Icon
          className='size-2 danger array-field-remove'
          IconComponent={ CancelIcon }
          onClick={ () => remove( defaultValues.uid ) }
        />
        <input
          type='text'
          name='title'
          value={ form.title }
          onChange={ ( e ) => handleChange( e.target ) }
        />
      </FormLabel>

      <FormLabel name='question_type' field='Question Type'>
        <select
          name='question_type'
          value={ form.question_type }
          onChange={ ( e ) => handleChange( e.target ) }
          disabled={ !create }
        >
          <option value=''>Select Question Type</option>
          <option value='Oneliner'>One Liner</option>
          <option value='Descriptive'>Descriptive</option>
          <option value='MCQ'>MCQ</option>
          <option value='Matrix'>Matrix</option>
        </select>
      </FormLabel>

      <Toggle
        size='sm'
        label='Is Required'
        checked={ form.is_required }
        name='is_required'
        onChange={ ( e ) =>
          handleChange( {
            name: e.target.name,
            value: e.target.checked,
          } )
        }
      />

      {'MCQ' === defaultValues.question_type
        && defaultValues.choices
        && defaultValues.choices.map( ( choice, index ) => (
          <Choice
            key={ choice?.uid ?? `choice-${ index }` }
            defaultValues={ {
              ...choice,
              order: index + 1,
              question: defaultValues.uid,
            } }
            isLeaderQuestion={ isLeaderQuestion }
          />
        ) )}

      {'MCQ' === form.question_type
        && ( !isLeaderQuestion || 0 === defaultValues?.choices?.length ) && (
        <AddChoice
          order={ form.choices ? form.choices.length + 1 : 1 }
          question={ form.uid }
        />
      )}

      {'Matrix' === form.question_type && (
        <FormLabel field='Linked Question' name='linked_question'>
          <select
            name='linked_question'
            value={ form.linked_question }
            onChange={ ( e ) => handleChange( e.target ) }
          >
            <option value=''>Select</option>
            {getAllMcqs().map( ( q, index ) => (
              <option value={ q.uid } key={ `lq-${ index }-${ q.uid }` }>
                Q {q.order} - {q.title}
              </option>
            ) )}
          </select>
          <span className='helper-text'>This field is used for linking</span>
        </FormLabel>
      )}

      {'Matrix' === form.question_type && (
        <FormLabel field='Area' name='area'>
          <input
            type='text'
            name='area'
            value={ form.area }
            onChange={ ( e ) => handleChange( e.target ) }
          />
          <span className='helper-text'>
            This field is used for denoting the whole matrix property
          </span>
        </FormLabel>
      )}

      {'Matrix' === defaultValues.question_type
        && defaultValues.matrix_row
        && defaultValues.matrix_row.map( ( row, index ) => (
          <MatrixRow
            key={ row?.uid ?? `row-${ index }` }
            defaultValues={ {
              ...row,
              is_row_header: true,
              order: index + 1,
              question: defaultValues.uid,
            } }
          />
        ) )}

      {'Matrix' === form.question_type && (
        <AddMatrixRow
          order={ form.matrix_row ? form.matrix_row.length + 1 : 1 }
          question={ form.uid }
        />
      )}

      <hr className='divider' />
    </>
  )
}

export default Question

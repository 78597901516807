import React from 'react'

import HorizontalBarChart from '../../../../../components/Charts/HorizontalBarChart/HorizontalBarChart'
import StatsTable from '../StatsTable'

const MatrixStats = ( { question } ) => {
  const makePercent = ( stat, maxValue ) => {
    // don't want to show 1.0e+2% when we get 100%
    // don't want to show 76.233333%
    const val = ( stat / maxValue ) * 100

    if ( 100 === val ) {
      return val
    }

    return val.toPrecision( 2 )
  }

  const formatDataForMatrix = ( data ) => {
    // TODO: Currently the scale of matrix is fixed to 5
    // this might be made dynamic in the future ( question.scale maybe )
    // let the maxValue = 5
    const maxValue = 5

    // have to use only the last array data ( the averages ) on the bar chart
    return data.stats[data.stats.length - 1].map( ( stat, index ) => ( {
      barValue: `${ stat }`,
      label: data.column_headers[index],
      percent: makePercent( stat, maxValue ),
    } ) )
  }

  return (
    <>
      <HorizontalBarChart data={ formatDataForMatrix( question.stats ) } />

      <StatsTable
        columnHeaders={ [ 'Label (Y)', ...question.stats.column_headers ] }
        rows={ question.stats.stats.map( ( statArr, index ) => [
          question.stats.row_titles[index] ?? 'Averages',
          ...statArr,
        ] ) }
        emphasisOnLastRow={ true }
      />
    </>
  )
}

export default MatrixStats

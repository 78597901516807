import React, { createContext, useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { useHistory, useLocation, useRouteMatch } from 'react-router'

import useApi from '../useApi/useApi'
import useLogout from './useLogout'

const AuthContext = createContext( {} )

// TODO: Create a better private route component

export const AuthContextProvider = ( { children } ) => {
  const history = useHistory()
  const location = useLocation()
  const isSurveyResponsePage
    = useRouteMatch( '/survey/:survey_uid/r/:respondent_uid' )?.isExact ?? false
  const isSurveyRedirectPage
    = useRouteMatch( '/survey/i/:survey_invite_uid' )?.isExact ?? false

  const { deleteCookies } = useLogout()

  const getAuthCookie = () => Cookies.get( 'Authorization' )

  const [ userData, setUserData ] = useState( null )
  const [ rememberMe, setRememberMe ] = useState( true )

  const { data, refetch, isSuccess, isError, isLoading } = useApi(
    'get',
    '/api/auth/profile/info/',
    null,
    {
      enabled: false,
      retry: false,
    },
  )

  const authRoutes = [ '/login', '/change-password/', '/sign-up' ]

  useEffect( () => {
    if ( isSuccess ) {
      if ( data.workspace_url.toLowerCase() !== window.location.origin.toLowerCase() ) {
        window.location.href = data.workspace_url.toLowerCase()
      }
      setUserData( data )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isSuccess, data ] )

  useEffect( () => {
    if (
      !authRoutes.includes( location.pathname )
      && !getAuthCookie()
      && !isSurveyResponsePage
      && !isSurveyRedirectPage
    ) {
      history.push( '/login' )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ history, location.pathname ] )

  useEffect( () => {
    if (
      !userData
      && !authRoutes.includes( location.pathname )
      && !isSurveyResponsePage
      && !isSurveyRedirectPage
    ) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ userData, refetch, location ] )

  useEffect( () => {
    if ( isError && !isSurveyResponsePage && !isSurveyRedirectPage ) {
      deleteCookies()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ history, isError ] )

  const isLoggedIn = () => !!getAuthCookie()

  return (
    <AuthContext.Provider
      value={ {
        fetchingUserInfoLoading: isLoading,
        fetchingUserInfoSuccess: isSuccess,
        isImpersonatedUser: userData?.is_impersonated_user ?? false,
        isLoggedIn,
        rememberMe,
        setRememberMe,
        setUserData,
        userData,
      } }
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext

/*
userData = {
  uid
  first_name
  last_name
  email
  phone
  is_ac_owner
  is_ac_admin
  is_ac_editor
  is_client_admin
  client_uid
}
*/

import classNames from 'classnames'
import React, { Fragment } from 'react'

import DoughnutChart from '../../../../../components/Charts/DoughnutChart/DoughnutChart'
import HorizontalBarChart from '../../../../../components/Charts/HorizontalBarChart/HorizontalBarChart'
import COLORS from '../../../../../utils/statColor'

const McqStats = ( { question, chartType } ) => {
  const formatDataForDoughnutChart = ( data ) =>
    data.map( ( el, index ) => ( {
      color: COLORS[index],
      percent: el.percent,
    } ) )

  const formatDataForBarChart = ( data ) =>
    data.map( ( { responses, ...rest } ) => ( {
      ...rest,
      barValue: responses,
    } ) )

  return (
    <div className='doughnut-chart-container'>
      {'Bar' === chartType && (
        <HorizontalBarChart
          data={ formatDataForBarChart( question.choice_answers ) }
        />
      )}

      {'Doughnut' === chartType && (
        <DoughnutChart
          data={ formatDataForDoughnutChart( question.choice_answers ) }
        />
      )}

      {'Doughnut' === chartType && (
        <div className='labels-container'>
          {question.choice_answers.map(
            ( { label, percent, responses }, index ) => (
              <Fragment key={ `choice-label-${ label }-${ index }` }>
                <div>
                  <span
                    className={ classNames( {
                      'label-color': true,
                      [`stat-color-${ index }`]: true,
                    } ) }
                  ></span>
                  {label}
                </div>
                <div>
                  <span>{responses}</span>({percent}%)
                </div>
              </Fragment>
            ),
          )}
        </div>
      )}
    </div>
  )
}

export default McqStats

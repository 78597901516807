import React from 'react'
import { Switch, Route } from 'react-router-dom'

const CrudRouterSwitch = ( { url, components: { add, edit, list, view } } ) => (
  <Switch>
    <Route exact path={ url } component={ list }></Route>

    {add && <Route path={ `${ url }/add/` } component={ add } />}

    {view && <Route path={ `${ url }/edit/:uid` } component={ view } />}
  </Switch>
)

export default CrudRouterSwitch

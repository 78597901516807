import React, { useContext, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'

import './Modal.scss'

import UiContext from '../../common/UiContext'
import { CloseIcon } from '../../assets/icons'

const Modal = () => {
  const { modal: Component, closeModal } = useContext( UiContext )

  const ref = useRef()

  if ( !Component ) {
    return null
  }

  return (
    <CSSTransition
      in={ null !== Component }
      classNames='modal'
      className='modal'
      appear={ true }
      timeout={ 500 }
      nodeRef={ ref }
    >
      <div ref={ ref }>
        <div className='modal-content'>
          <div className='close-btn' onClick={ closeModal }>
            <CloseIcon className='dark size-1' />
          </div>
          <Component />
        </div>
      </div>
    </CSSTransition>
  )
}

export default Modal

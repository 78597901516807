/* eslint-disable max-len */
import React from 'react'

const MasterSurvey = () => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.25 1H9.375V31H11.25V29.125H28.25V21.625H11.25V19.75H20.75V12.25H11.25V10.375H32V2.875H11.25V1Z'
      fill='#F89828'
    />
    <path
      d='M3.75 10.375C5.82107 10.375 7.5 8.69607 7.5 6.625C7.5 4.55393 5.82107 2.875 3.75 2.875C1.67893 2.875 0 4.55393 0 6.625C0 8.69607 1.67893 10.375 3.75 10.375Z'
      fill='#5DC744'
    />
    <path
      d='M3.75 19.75C5.82107 19.75 7.5 18.0711 7.5 16C7.5 13.9289 5.82107 12.25 3.75 12.25C1.67893 12.25 0 13.9289 0 16C0 18.0711 1.67893 19.75 3.75 19.75Z'
      fill='#5DC744'
    />
    <path
      d='M3.75 29.125C5.82107 29.125 7.5 27.4461 7.5 25.375C7.5 23.3039 5.82107 21.625 3.75 21.625C1.67893 21.625 0 23.3039 0 25.375C0 27.4461 1.67893 29.125 3.75 29.125Z'
      fill='#5DC744'
    />
  </svg>
)

export default MasterSurvey

import React from 'react'
import { useHistory } from 'react-router'

import './404.scss'

// import NotFoundImg from '../../assets/images/404.svg'

const NotFound = () => {
  const history = useHistory()

  return (
    <div className='not-found'>
      <button className='primary' onClick={ () => history.push( '/dashboard' ) }>
        Go to Dashboard
      </button>
    </div>
  )
}

export default NotFound

import Login from './Auth'
import Dashboard from './Dashboard'
import Reports from './Reports'
import SurveyBuilder from './SurveyBuilder'
import EmailManager from './EmailManager'
import NotFound from './404'
import ContactAdd from './EmailManager/ContactsTab/ContactAdd'
import MasterSurvey from './MasterSurvey'

export default {
  Dashboard,
  EmailManager,
  EmailManagerAdd: ContactAdd,
  Login,
  MasterSurvey,
  NotFound,
  Reports,
  SurveyBuilder,
}

import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import './ContactAddForm.scss'

import bgImg from '../../../../../assets/images/email-manager/add-manual.png'

import PageContent from '../../../../../components/PageContent'
import Form from '../../../../../components/Forms/Form/Form'
import ContactForm from '../../../../../components/Forms/ContactForm'

const ContactAddForm = () => {
  const history = useHistory()

  // NOTE: we won't show segment adding right away, click `continue` to save contact then we can add segments
  // bcoz we need the contact UID to add segments
  const [ isContactCreated, setIsContactCreated ] = useState( false )
  const [ newContact, setNewContact ] = useState( null )

  const goBack = () => history.push( '/email-manager/contacts' )

  const handleResponse = ( responseData ) => {
    setIsContactCreated( true )
    // responseData is the newly created contact, we can get the UID from here
    setNewContact( responseData )
  }

  return (
    <PageContent className='contact-add-form' isModal={ true }>
      <div className='column content'>
        <div>
          <Form
            method='post'
            endpoint='/api/respondent'
            onSuccess={ handleResponse }
            FormBody={ ( props ) =>
              ContactForm( {
                ...props,
                contact: newContact,
                goBack,
                heading: 'Add Contact',
                isContactCreated,
              } )
            }
          />
        </div>
      </div>
      <div className='column image'>
        <img alt='Add contact' src={ bgImg } />
      </div>
    </PageContent>
  )
}

export default ContactAddForm

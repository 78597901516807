import { useContext, useEffect } from 'react'

import { ReactComponent as ContactsIcon } from '../../assets/icons/tab-icons/contacts.svg'
import { ReactComponent as SegmentsIcon } from '../../assets/icons/tab-icons/segments.svg'

import UiContext from '../../common/UiContext'

import ContactsTab from './ContactsTab/ContactsTab'
import SegmentsTab from './SegmentsTab/SegmentsTab'

const tabs = [
  {
    Component: ContactsTab,
    Icon: ContactsIcon,
    label: 'Contacts',
  },
  {
    Component: SegmentsTab,
    Icon: SegmentsIcon,
    label: 'Segments',
  },
]

const EmailManagerTabs = () => {
  const { setPageTabsData, activePageTab, removePageTabs }
    = useContext( UiContext )

  useEffect( () => {
    setPageTabsData( tabs )

    return () => {
      removePageTabs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  if ( activePageTab ) {
    return <activePageTab.Component />
  }

  return null
}

export default EmailManagerTabs

import React, { useState } from 'react'

import './TableSearch.scss'

import { SearchIcon, CancelIcon } from '../../assets/icons'

import Icon from '../Icon'

const TableSearch = ( { handleSearch, value, endpoint } ) => {
  const [ showSearchBar, setShowSearchBar ] = useState( false )

  const toggle = () => {
    if ( showSearchBar ) {
      handleSearch( '' )
    }
    setShowSearchBar( !showSearchBar )
  }

  return (
    <div className='table-search-container'>
      {showSearchBar && (
        <input
          type='text'
          placeholder='Search'
          onChange={ ( e ) => handleSearch( e.target.value ) }
          value={ value }
          autoFocus
        />
      )}
      <button className='tertiary search-button' onClick={ toggle }>
        <Icon
          IconComponent={ showSearchBar ? CancelIcon : SearchIcon }
          className='primary size-3'
        />
      </button>
    </div>
  )
}

export default TableSearch

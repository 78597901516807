import React, { useContext, useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'

import './FilterModal.scss'

import UiContext from '../../common/UiContext'
import Icon from '../Icon'
import { CloseIcon } from '../../assets/icons'

const FilterModal = () => {
  const ref = useRef()

  const { filterModal: { isOpen, closeFilterModal, FilterComponent } } = useContext( UiContext )

  useEffect( () => {
    if ( isOpen ) {
      document.querySelector( 'body' ).style.overflowY = 'hidden'
    } else {
      document.querySelector( 'body' ).style.overflowY = 'auto'
    }

    return () => {
      document.querySelector( 'body' ).style.overflowY = 'auto'
    }
  }, [ isOpen ] )

  if ( isOpen ) {
    return (
      <div className='filter-modal-container'>
        <CSSTransition
          classNames='slide-in'
          appear={ true }
          timeout={ 50 }
          nodeRef={ ref }
          in={ isOpen }
          mountOnEnter
        >
          <div className='filter-modal-content' ref={ ref }>
            <div className='heading'>
              <h3>Filters</h3>

              <Icon
                IconComponent={ CloseIcon }
                className='dark size-2 close-modal'
                onClick={ closeFilterModal }
              />
            </div>

            <div className='content'>
              <FilterComponent />
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }

  return null
}

export default FilterModal

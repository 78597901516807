import React, { createContext, useState } from 'react'

const LeaderContext = createContext( {} )

// to avoid prop drilling
export const LeaderContextProvider = ( { children } ) => {
  const [ leader, setLeader ] = useState( '' )

  return (
    <LeaderContext.Provider
      value={ {
        leader,
        setLeader,
      } }
    >
      {children}
    </LeaderContext.Provider>
  )
}

export default LeaderContext

import React from 'react'

import './SuccessPage.scss'

import illustration from '../../assets/images/survey-success.svg'
import logo from '../../assets/images/logo.svg'

const SuccessPage = ( { clientLogo } ) => (
  <div className='survey-success-page'>
    <div className='logo-container'>
      <img
        className='client-logo'
        src={ clientLogo }
        width='150px'
        alt='logo'
      ></img>
      <img src={ logo } width='150px' alt='logo'></img>
    </div>

    <h1>Thanks for completing the survey!</h1>

    <img src={ illustration } alt='logo'></img>
  </div>
)

export default SuccessPage

import React from 'react'
import FormLabel from '../../components/Forms/Helpers/FormLabel/FormLabel'

import './ClientInfo.scss'

const ClientInfo = ( { data } ) => (
  <div className='client-info'>
    <img className='logo' src={ data.logo } alt="client's logo" />

    <div>
      <FormLabel field="Client's Name">
        <input type='text' value={ data.name } readOnly/>
      </FormLabel>

      <FormLabel field="Client's Email">
        <input type='text' value={ data.email } readOnly/>
      </FormLabel>
    </div>
  </div>
)

export default ClientInfo

import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { EditIcon, TrashIcon, CheckIcon, UndoIcon } from '../../../assets/icons'

import BooleanFieldFormatter from '../../../common/Formatters/BooleanFieldFormatter'
import dateTimeFormatter from '../../../common/Formatters/dateTimeFormatter'
import UiContext from '../../../common/UiContext'
import useApi, { UseInvalidateEndpoint } from '../../../common/useApi'
import ConfirmModal from '../../../components/ConfirmModal'
import DataTable from '../../../components/DataTable/DataTable'

import CreateSurvey from './CreateSurvey'
import SurveyFilters from './SurveyFilters'

const List = () => {
  const history = useHistory()
  const location = useLocation()
  let initFilters = {}

  const invalidateEndpoint = UseInvalidateEndpoint()

  const params = new URLSearchParams( location.search )

  for ( const [ key, value ] of params.entries() ) {
    // handling 'true' and 'false' values from query params
    if ( 'true' === value ) {
      initFilters = { [key]: true }
    } else if ( 'false' === value ) {
      initFilters = { [key]: false }
    } else {
      initFilters = { [key]: value }
    }
  }

  const { openModal, setToast } = useContext( UiContext )

  const [ filters, setFilters ] = useState( initFilters )

  const { mutate: deleteSurvey } = useApi( 'delete', '/api/survey' )

  const { mutate: markAsComplete, isSuccess } = useApi( 'put', '/api/survey' )

  const {
    data,
    refetch: createDefaultSurvey,
    isLoading: creatingDefaultSurvey,
    isSuccess: defaultSurveyCreated,
  } = useApi( 'get', '/api/survey/clone', null, {
    cacheTime: 0,
    enabled: false,
  } )

  useEffect( () => {
    if ( isSuccess ) {
      setToast( {
        message: 'Survey Updated',
        type: 'success',
      } )
    }
  }, [ isSuccess, setToast ] )

  useEffect( () => {
    if ( defaultSurveyCreated ) {
      setToast( {
        message: 'Default Survey Created Successfully',
        type: 'success',
      } )
      invalidateEndpoint( '/api/survey' )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ defaultSurveyCreated, data ] )

  const actionsContent = ( rowData ) => [
    {
      IconComponent: EditIcon,
      color: 'primary',
      func: ( obj ) => {
        history.push( `${ history.location.pathname }/edit/${ obj.uid }` )
      },
      label: 'Edit',
    },
    {
      IconComponent: rowData.is_completed ? UndoIcon : CheckIcon,
      color: 'primary',
      func: ( survey ) => {
        markAsComplete( {
          ...survey,
          is_completed: !survey.is_completed,
          useId: survey.uid,
        } )
      },
      label: rowData.is_completed ? 'Undo Complete' : 'Mark as Complete',
    },
    {
      IconComponent: TrashIcon,
      color: 'danger',
      func: ( obj ) => {
        ConfirmModal( {
          message: 'Are you sure you want to delete this item?',
          successCallback: () => {
            deleteSurvey( obj.uid )
          },
        } )
      },
      label: 'Delete',
    },
  ]

  const columns = [
    { key: 'name' },
    {
      extractData: ( obj ) => obj.length,
      key: 'respondents',
      title: 'Total Respondents',
    },
    {
      extractData: ( obj ) => obj.length,
      key: 'questions',
      title: 'Total Questions',
    },
    {
      extractData: ( val ) => dateTimeFormatter( val ),
      key: 'created',
    },
    {
      extractData: ( val ) => BooleanFieldFormatter( val ),
      key: 'is_completed',
      title: 'Completed',
    },
    {
      extractData: ( val ) => BooleanFieldFormatter( val ),
      key: 'is_published',
      title: 'Published',
    },
  ]

  const open = () => {
    openModal( () => () => <CreateSurvey /> )
  }

  const Buttons = () => (
    <>
      <button className='primary' onClick={ open }>
        Add New Survey
      </button>

      <button
        className='accent'
        onClick={ createDefaultSurvey }
        disabled={ creatingDefaultSurvey }
      >
        {creatingDefaultSurvey ? 'In Progress...' : 'Create Default Survey'}
      </button>
    </>
  )

  const filterButtonConfig = {
    FilterComponent: SurveyFilters,
    setFilters,
  }

  return (
    <DataTable
      endpoint='/api/survey'
      heading='Surveys'
      columns={ columns }
      actionsContent={ actionsContent }
      Buttons={ Buttons }
      filters={ filters }
      defaultSorting='-created'
      filterButtonConfig={ filterButtonConfig }
    />
  )
}

export default List

import React, { useContext } from 'react'

import UiContext from '../../../common/UiContext'
import ChangePasswordForm from '../../Forms/ChangePasswordForm'
import Form from '../../Forms/Form/Form'

const ChangePassword = ( { goToLogin, goToSuccessPage } ) => {
  const { setToast } = useContext( UiContext )

  const onError = () => {
    setToast( {
      message: 'An error occurred',
      type: 'error',
    } )
  }

  return (
    <Form
      method='post'
      endpoint='/api/auth/password/reset/confirm'
      onSuccess={ () => goToSuccessPage() }
      onError={ onError }
      FormBody={ ( props ) =>
        ChangePasswordForm( {
          ...props,
          goToLogin,
        } )
      }
    />
  )
}

export default ChangePassword

import React, { useContext } from 'react'
import { useParams } from 'react-router'

import './GeneratedReports.scss'

import {
  DownloadIcon,
  DocxIcon,
  CsvIcon,
  RefreshIcon,
} from '../../../../assets/icons'

import DataTable from '../../../../components/DataTable'
import dateTimeFormatter from '../../../../common/Formatters/dateTimeFormatter'
import { UseInvalidateEndpoint } from '../../../../common/useApi'
import UiContext from '../../../../common/UiContext'
import Icon from '../../../../components/Icon'

const GeneratedReports = () => {
  const { uid } = useParams()

  const invalidateEndpoint = UseInvalidateEndpoint()

  const { setToast } = useContext( UiContext )

  const columns = [
    {
      extractData: ( value ) => dateTimeFormatter( value ),
      key: 'created',
      title: 'Report Ran On',
    },
    {
      extractData: ( value ) => (
        <span>
          <Icon
            IconComponent={ 'csv' !== value.toLowerCase() ? DocxIcon : CsvIcon }
            className='primary size-3 centered'
          />
          {'  '}
          {value}
        </span>
      ),
      key: 'type',
    },
    { key: 'status' },
    {
      extractData: ( value, row ) =>
        'success' === row.status.toLowerCase() ? (
          <a
            className='link-btn tertiary'
            href={ value }
            target='_blank'
            rel='noopener noreferrer'
          >
            <Icon IconComponent={ DownloadIcon } className='primary size-2' />
            Download
          </a>
        ) : null,
      key: 'file',
    },
  ]

  const refresh = () => {
    invalidateEndpoint( `/api/survey/${ uid }/report` )
    setToast( {
      message: 'Reports updated',
      type: 'success',
    } )
  }

  const Buttons = () => (
    <button className='secondary icon-button' onClick={ refresh }>
      <Icon IconComponent={ RefreshIcon } className='primary size-4' />
    </button>
  )

  return (
    <div className='generated-reports-container'>
      <DataTable
        endpoint={ `/api/survey/${ uid }/report` }
        heading='Reports'
        columns={ columns }
        onRowClick={ () => {} }
        isSearchable={ false }
        config={ { refetchInterval: 30000 } }
        Buttons={ Buttons }
      />
    </div>
  )
}

export default GeneratedReports

import React, { useContext, useEffect, useState } from 'react'

import { CancelIcon } from '../../../../assets/icons'
import useApi, { UseInvalidateEndpoint } from '../../../../common/useApi'
import FormLabel from '../../../../components/Forms/Helpers/FormLabel/FormLabel'
import Icon from '../../../../components/Icon/Icon'
import { useParams } from 'react-router'
import UiContext from '../../../../common/UiContext'
import useDebounce from '../../../../common/useDebounce/useDebounce'

const Choice = ( {
  defaultValues,
  create,
  handleCreated,
  cancelCreate,
  isLeaderQuestion,
} ) => {
  const { uid } = useParams()
  const { setShowTabsLoading, setToast } = useContext( UiContext )

  const invalidateEndpoint = UseInvalidateEndpoint()

  const [ form, setForm ] = useState( {
    ...defaultValues,
    label: defaultValues?.label ?? '',
  } )
  const [ isFormDirty, setIsFormDirty ] = useState( false )

  const method = create ? 'post' : 'put'
  const endpoint = create
    ? '/api/question/choice'
    : `/api/question/choice/${ defaultValues.uid }`

  const { mutate, isSuccess, isLoading } = useApi( method, endpoint, form )

  const debouncedSave = useDebounce( mutate )

  useEffect( () => {
    !isFormDirty && setIsFormDirty( true )

    if ( handleValidation() && isFormDirty ) {
      debouncedSave( form )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ form ] )

  useEffect( () => {
    if ( isSuccess ) {
      invalidateEndpoint( `/api/survey/${ uid }` )
      create && handleCreated()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isSuccess, create ] )

  useEffect( () => {
    setShowTabsLoading( isLoading )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isLoading ] )

  const handleChange = ( { name, value } ) => {
    setForm( ( old ) => ( {
      ...old,
      [name]: value,
    } ) )
  }

  const handleValidation = () => {
    if ( !form.label ) {
      return false
    }
    return true
  }

  const {
    mutate: remove,
    isSuccess: choiceDeleted,
    isError,
    error,
  } = useApi( 'delete', '/api/question/choice' )

  useEffect( () => {
    if ( choiceDeleted ) {
      invalidateEndpoint( `/api/survey/${ uid }` )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ choiceDeleted ] )

  useEffect( () => {
    if ( isError ) {
      if ( !defaultValues.uid ) {
        // was creating a choice
        cancelCreate()
      } else {
        if ( 400 === error.status ) {
          // we cannot delete the leader after the survey has been published
          setToast( {
            message: error.data.message,
            type: 'info',
          } )
        } else {
          setToast( {
            message: 'An error occured',
            type: 'error',
          } )
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isError ] )

  return (
    <FormLabel name='label' field='Choice Label' className='indented'>
      <Icon
        className='size-2 danger array-field-remove'
        IconComponent={ CancelIcon }
        onClick={ () => remove( defaultValues.uid ) }
      />
      <input
        type='text'
        name='label'
        value={ form.label }
        onChange={ ( e ) => handleChange( e.target ) }
      />
      {isLeaderQuestion && (
        <span className='helper-text'>
          This is a leader question. You can only specify a single leader.
        </span>
      )}
    </FormLabel>
  )
}

export default Choice

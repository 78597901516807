import React, { useContext, useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { useHistory } from 'react-router'

import AuthContext from '../../../common/auth'
import UiContext from '../../../common/UiContext/UiContext'
import useApi from '../../../common/useApi/useApi'
import FormLabel from '../../Forms/Helpers/FormLabel/FormLabel'
import httpClient from '../../../common/useApi/httpClient'

const TwoFA = ( { goToLogin } ) => {
  const history = useHistory()

  const { setUserData, userData, rememberMe } = useContext( AuthContext )

  const {
    isLoading: otpSending,
    isSuccess: otpSent,
    refetch: resendOtp,
  } = useApi(
    'getList',
    '/api/auth/resend-otp',
    { user: userData.user_id },
    {
      enabled: false,
      retry: false,
    },
  )
  const [ otp, setOtp ] = useState( '' )

  const {
    mutate: checkOtp,
    isLoading: checkingOtp,
    isSuccess: otpSuccess,
    isError: wrongOtp,
    data,
    error,
  } = useApi(
    'post',
    '/api/auth/2FA',
    {
      otp,
      user: userData.user_id,
    },
    {
      cacheTime: 0,
      enabled: false,
      retry: 0,
    },
  )

  const { setToast } = useContext( UiContext )

  useEffect( () => {
    if ( otpSent ) {
      setToast( {
        message: 'OTP Sent',
        type: 'success',
      } )
    }
  }, [ otpSent, setToast ] )

  useEffect( () => {
    if ( otpSuccess ) {
      // default is 2 hours, else 7 days
      const expires = rememberMe
        ? 7
        : parseInt( process.env.REACT_APP_AUTH_COOKIE_EXPIRATION_DAYS )

      Cookies.remove( 'Authorization' )
      let rootDomain = ''
      const domain = window.location.hostname.split( '.' )

      if ( 3 === domain.length ) {
        // client1.ac.com ( has tenant )
        rootDomain = `.${ domain.slice( 1 ).join( '.' ) }`
      } else {
        // ac.com
        rootDomain = `.${ domain.join( '.' ) }`
      }

      Cookies.set( 'Authorization', `Token ${ data.token }`, {
        domain: `${ rootDomain }`,
        expires,
      } )
      httpClient.defaults.headers['Authorization'] = `Token ${ data.token }`
      setUserData( data )
    }
    if ( wrongOtp ) {
      let message = 'Wrong OTP'

      if ( 400 === error.status ) {
        message = error.data.message
      }
      setToast( {
        message,
        type: 'error',
      } )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ otpSuccess, data, setToast, wrongOtp, setUserData ] )

  useEffect( () => {
    if ( userData?.token ) {
      if ( data.workspace_url !== window.location.origin ) {
        window.location.href = userData.workspace_url
      } else {
        history.push( '/dashboard' )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ userData, history ] )

  return (
    <section>
      <h1>Two Factor Authentication Required</h1>
      <h5 className='subtle'>
        We have send you an OTP on your phone ending with {userData.phone_helper}. Enter the OTP to
        login.
      </h5>

      <FormLabel name='otp' field='OTP'>
        <input
          type='text'
          value={ otp }
          onChange={ ( e ) => setOtp( e.target.value ) }
          placeholder='Enter OTP Here'
        />
      </FormLabel>

      <button
        className='primary'
        disabled={ checkingOtp }
        type='button'
        onClick={ () =>
          checkOtp( {
            otp,
            user: userData.user_id,
          } )
        }
      >
        Submit
      </button>

      <button
        className='secondary'
        type='button'
        onClick={ resendOtp }
        disabled={ otpSending }
      >
        Resend OTP
      </button>

      <button className='tertiary' type='button' onClick={ goToLogin }>
        Back To Login
      </button>
    </section>
  )
}

export default TwoFA

import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'

import { ReturnIcon } from '../../../../assets/icons'
import folderImg from '../../../../assets/images/email-manager/folder.png'
import csvImg from '../../../../assets/images/email-manager/csv.png'
import Icon from '../../../../components/Icon'

import PageContent from '../../../../components/PageContent'

import './ContactImport.scss'
import httpClient from '../../../../common/useApi/httpClient'
import ImportedContacts from './ImportedContacts'
import useApi from '../../../../common/useApi'
import FormLabel from '../../../../components/Forms/Helpers/FormLabel/FormLabel'
import downloadFromApi from '../../../../common/downloadFromApi'
import UiContext from '../../../../common/UiContext'

const ContactImport = () => {
  const history = useHistory()

  const { setToast } = useContext( UiContext )

  const { data: segments } = useApi( 'get', '/api/respondent/segment', { limit: 999 } )

  const [ dragging, setDragging ] = useState( false )
  const [ file, setFile ] = useState( false )
  const [ newContacts, setNewContacts ] = useState( [] )
  const [ csvImported, setCsvImported ] = useState( false )
  const [ segmentUid, setSegmentUid ] = useState( '' )
  const [ downloadingSample, setDownloadingSample ] = useState( false )

  useEffect( () => {
    if ( file ) {
      ;( async function upload() {
        try {
          const formData = new FormData()

          formData.append( 'respondents', file )
          formData.append( 'segment_uid', segmentUid )
          const response = await httpClient.post(
            '/api/respondent/bulk-add/',
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } },
          )

          setNewContacts( response.data.respondents_created )
          setCsvImported( true )
        } catch ( error ) {
          // eslint-disable-next-line
          console.error(error)
        }
      } )()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ file ] )

  const onDrop = useCallback( ( acceptedFiles ) => {
    setFile( acceptedFiles[0] )
    setDragging( false )
  }, [] )

  const onDragEnter = useCallback( ( acceptedFiles ) => {
    setDragging( true )
  }, [] )

  const onDragLeave = useCallback( ( acceptedFiles ) => {
    setDragging( false )
  }, [] )

  const { getRootProps, getInputProps, open } = useDropzone( {
    accept: [ '.csv' ],
    maxFiles: 1,
    multiple: false,
    noClick: true,
    onDragEnter,
    onDragLeave,
    onDrop,
  } )

  const nextStep = () => {
    history.push( '' )
  }

  const removeFile = () => setFile( false )

  const activeFileElements = (
    <>
      <p className='filename'>{file.name}</p>
      <span className='remove-file' onClick={ removeFile }>
        Remove File
      </span>
    </>
  )

  const downloadSample = async () => {
    setDownloadingSample( true )
    try {
      await downloadFromApi(
        'import-contacts-sample.csv',
        '/api/respondent/sample',
      )
    } catch ( error ) {
      setToast( {
        message: 'An Error Occured',
        type: 'error',
      } )
    }
    setDownloadingSample( false )
  }

  const inactiveFileElements = (
    <>
      <h5>Drag & drop a file</h5>
      <div className='separator'>or</div>
      <button className='primary' onClick={ open }>
        Upload from computer
      </button>
      <button
        className='accent'
        onClick={ downloadSample }
        disabled={ downloadingSample }
      >
        {!downloadingSample ? 'Download Sample CSV' : 'Downloading...'}
      </button>
      <span>Only .CSV format</span>
    </>
  )

  return (
    <PageContent className='contact-import' isModal={ true }>
      {!csvImported ? (
        <>
          <h2>Add Contacts from a CSV file</h2>

          <FormLabel name='segment' field='Segment (Optional)'>
            <select
              name='segement'
              value={ segmentUid }
              onChange={ ( e ) => setSegmentUid( e.target.value ) }
            >
              <option value=''>Select a segment</option>
              {segments?.results.map( ( segment ) => (
                <option value={ segment.uid } key={ segment.uid }>{segment.name}</option>
              ) )}
            </select>
          </FormLabel>

          <div
            { ...getRootProps( { className: `drop-zone ${ dragging ? 'active' : '' }` } ) }
          >
            <input { ...getInputProps() } />
            <div>
              <div className='image'>
                <img
                  alt='Add Contacts from a CSV file'
                  src={ file ? csvImg : folderImg }
                />
              </div>
              {file ? activeFileElements : inactiveFileElements}
            </div>
          </div>
          <button className='primary' disabled={ !file } onClick={ nextStep }>
            Continue
          </button>
          <button className='tertiary' onClick={ () => history.goBack() }>
            <Icon IconComponent={ ReturnIcon } className='icon-md icon-green' />
            Return
          </button>
        </>
      ) : (
        <ImportedContacts contacts={ newContacts } />
      )}
    </PageContent>
  )
}

export default ContactImport

import React from 'react'

import './PageContent.scss'
import Icon from '../Icon'
import { CloseIcon } from '../../assets/icons'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

const PageContent = ( props ) => {
  const history = useHistory()
  const goBack = () => {
    if( props.onClose ){
      props.onClose()
    }else{
      history.goBack()
    }
  }

  const renderClose = () => {
    if( props.isModal ){
      return (
        <div className='close-button' onClick={ goBack }>
          <Icon IconComponent={ CloseIcon } className='size-2 ' />
        </div>
      )
    }
  }

  return (
    <div className={ `page-content ${ props.className }` }>
      {renderClose()}
      {props.children}
    </div>
  )
}

PageContent.propTypes = {
  className: PropTypes.string,
  isModal: PropTypes.bool,
  onClose: PropTypes.func,
}

export default PageContent

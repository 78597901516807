import classNames from 'classnames'
import React from 'react'

import './HorizontalBarChart.scss'

const HorizontalBarChart = ( { data } ) => (
  <div>
    {data.map( ( stat, index ) => (
      <div className='bar-graph-container' key={ stat.label }>
        <p className='bar-label'>{stat.label}</p>

        {0 === parseFloat( stat.percent ) ? (
          <div className='bar zero-bar'>0%</div>
        ) : (
          <div
            className={ classNames( {
              bar: true,
              'with-custom-value': !!stat.barValue,
              [`stat-color-${ index }`]: true,
            } ) }
            data-percentage={
              stat.barValue ? `(${ stat.percent }%)` : `${ stat.percent }`
            }
            data-value={ stat.barValue }
            style={ { width: `calc(${ stat.percent }% - ${ 400 * ( stat.percent / 100 ) }px)` } }
          ></div>
        )}
      </div>
    ) )}

    <div className='x-axis'>
      <div></div>
      {[ 0, 10, 20, 30, 40, 50, 60, 70, 80, 90 ].map( ( e ) => (
        <div key={ `x-axis_${ e }` }>{e}%</div>
      ) )}
    </div>
  </div>
)

export default HorizontalBarChart

/*
data = [
  {
    label: string
    percent: number
    barValue?: string | number
  }
]
*/

/* eslint-disable max-len */
import React from 'react'

const EmailManager = () => (
  <svg fill='none' height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0)'>
      <path d='M27 12.8H26V16C26 17.4145 25.4732 18.771 24.5355 19.7712C23.5979 20.7714 22.3261 21.3333 21 21.3333H6V28.8C6 29.6487 6.31607 30.4626 6.87868 31.0627C7.44129 31.6629 8.20435 32 9 32H27C27.7956 32 28.5587 31.6629 29.1213 31.0627C29.6839 30.4626 30 29.6487 30 28.8V16C30 15.1513 29.6839 14.3374 29.1213 13.7373C28.5587 13.1371 27.7956 12.8 27 12.8Z' fill='#F89828'/>
      <path d='M3 19.2H21C21.7956 19.2 22.5587 18.8629 23.1213 18.2627C23.6839 17.6626 24 16.8487 24 16V3.2C24 2.35131 23.6839 1.53737 23.1213 0.937258C22.5587 0.337142 21.7956 0 21 0H3C2.20435 0 1.44129 0.337142 0.87868 0.937258C0.31607 1.53737 0 2.35131 0 3.2V16C0 16.8487 0.31607 17.6626 0.87868 18.2627C1.44129 18.8629 2.20435 19.2 3 19.2ZM2.94 4.704C3.0173 4.5903 3.1149 4.494 3.22718 4.42063C3.33946 4.34726 3.46422 4.29827 3.5943 4.27647C3.72438 4.25466 3.85722 4.26048 3.98518 4.29358C4.11315 4.32667 4.23373 4.3864 4.34 4.46933L12 10.4107L19.66 4.46933C19.8748 4.30242 20.143 4.23338 20.4056 4.27739C20.6681 4.3214 20.9035 4.47485 21.06 4.704C21.2165 4.93315 21.2812 5.21921 21.24 5.49927C21.1987 5.77932 21.0548 6.03042 20.84 6.19733L12.59 12.5973C12.4187 12.7308 12.2121 12.8028 12 12.8028C11.7879 12.8028 11.5813 12.7308 11.41 12.5973L3.16 6.19733C3.0534 6.11488 2.96312 6.01078 2.89434 5.89101C2.82556 5.77124 2.77963 5.63816 2.75919 5.49941C2.73875 5.36066 2.7442 5.21897 2.77523 5.08247C2.80626 4.94597 2.86225 4.81736 2.94 4.704Z' fill='#5DC744'/>
      <path d='M26 6.4H29C29.2652 6.4 29.5196 6.28762 29.7071 6.08758C29.8946 5.88754 30 5.61623 30 5.33333C30 5.05044 29.8946 4.77912 29.7071 4.57909C29.5196 4.37905 29.2652 4.26667 29 4.26667H26C25.7348 4.26667 25.4804 4.37905 25.2929 4.57909C25.1054 4.77912 25 5.05044 25 5.33333C25 5.61623 25.1054 5.88754 25.2929 6.08758C25.4804 6.28762 25.7348 6.4 26 6.4Z' fill='#5DC744'/>
      <path d='M29 8.53333H26C25.7348 8.53333 25.4804 8.64571 25.2929 8.84575C25.1054 9.04579 25 9.3171 25 9.6C25 9.8829 25.1054 10.1542 25.2929 10.3542C25.4804 10.5543 25.7348 10.6667 26 10.6667H29C29.2652 10.6667 29.5196 10.5543 29.7071 10.3542C29.8946 10.1542 30 9.8829 30 9.6C30 9.3171 29.8946 9.04579 29.7071 8.84575C29.5196 8.64571 29.2652 8.53333 29 8.53333Z' fill='#5DC744'/>
      <path d='M4 21.3333H1C0.734784 21.3333 0.48043 21.4457 0.292893 21.6458C0.105357 21.8458 0 22.1171 0 22.4C0 22.6829 0.105357 22.9542 0.292893 23.1542C0.48043 23.3543 0.734784 23.4667 1 23.4667H4C4.26522 23.4667 4.51957 23.3543 4.70711 23.1542C4.89464 22.9542 5 22.6829 5 22.4C5 22.1171 4.89464 21.8458 4.70711 21.6458C4.51957 21.4457 4.26522 21.3333 4 21.3333Z' fill='#F89828'/>
      <path d='M4 25.6H1C0.734784 25.6 0.48043 25.7124 0.292893 25.9124C0.105357 26.1125 0 26.3838 0 26.6667C0 26.9496 0.105357 27.2209 0.292893 27.4209C0.48043 27.621 0.734784 27.7333 1 27.7333H4C4.26522 27.7333 4.51957 27.621 4.70711 27.4209C4.89464 27.2209 5 26.9496 5 26.6667C5 26.3838 4.89464 26.1125 4.70711 25.9124C4.51957 25.7124 4.26522 25.6 4 25.6Z' fill='#F89828'/>
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect fill='white' height='32' width='32'/>
      </clipPath>
    </defs>
  </svg>

)

export default EmailManager

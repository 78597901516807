import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router'

import useApi from '../../../../common/useApi/useApi'
import Loader from '../../../../components/Loader'
import LeaderContext from '../../LeaderContext'

const SelectLeader = () => {
  const { uid } = useParams()

  const { leader, setLeader } = useContext( LeaderContext )

  const { data, isSuccess, isLoading } = useApi(
    'get',
    `/api/survey/${ uid }/leaders`,
  )

  useEffect( () => {
    if ( isSuccess ) {
      setLeader( data.results[0] )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isSuccess, data ] )

  if ( isLoading ) {
    return <Loader />
  }

  return (
    <p>
      <b>{leader.label}</b>
    </p>
  )

  // NOTE: commented this intentionally, requirement might change in the future

  // return (
  //   <div className='select-leader'>
  //     <FormLabel name='leader' field='Leader'>
  //       <select
  //         name='segment'
  //         value={ leader }
  //         onChange={ ( e ) => setLeader( e.target.value ) }
  //       >
  //         <option value=''>Select Leader</option>
  //         {data?.results.map( ( leader ) => (
  //           <option value={ leader.uid } key={ leader.uid }>
  //             {leader.label}
  //           </option>
  //         ) )}
  //       </select>
  //     </FormLabel>
  //   </div>
  // )
}

export default SelectLeader

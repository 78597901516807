/* eslint-disable max-len */
import React from 'react'

const SurveyBuilder = () => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        d='M29.3124 9H22.7838C22.8435 9.49187 22.8749 9.99225 22.8749 10.5C22.8749 12.4932 22.4005 14.3772 21.5598 16.0466L22.9687 17.4555L25.3996 15.0247C25.7657 14.6586 26.3593 14.6586 26.7254 15.0247C27.0916 15.3908 27.0916 15.9844 26.7254 16.3505L24.2946 18.7812L26.7254 21.2121C27.0916 21.5781 27.0916 22.1717 26.7254 22.5379C26.5424 22.721 26.3024 22.8125 26.0625 22.8125C25.8226 22.8125 25.5826 22.721 25.3996 22.5379L22.9687 20.1071L20.5379 22.5379C20.3549 22.7209 20.1149 22.8125 19.875 22.8125C19.6351 22.8125 19.3951 22.721 19.2121 22.5379C18.846 22.1717 18.846 21.5782 19.2121 21.2121L21.643 18.7812L20.5598 17.6981C18.4604 20.6233 15.1185 22.5996 11.3126 22.8475V25.8123C11.3126 27.2942 12.5183 28.4999 14.0002 28.4999H25.0804L30.5731 31.862C30.7231 31.9538 30.8927 31.9999 31.0626 31.9999C31.2203 31.9999 31.3784 31.9601 31.5209 31.8803C31.8167 31.7144 32.0001 31.4016 32.0001 31.0624V11.6876C31.9999 10.2057 30.7942 9 29.3124 9Z'
        fill='#5DC744'
      />
      <path
        d='M20.9999 10.5C20.9999 4.71025 16.2897 0 10.5 0C4.71031 0 0 4.71025 0 10.5C0 12.4849 0.550563 14.3992 1.59681 16.0676L0.0581875 20.2307C-0.0686875 20.5741 0.0158125 20.9599 0.274625 21.2187C0.453625 21.3977 0.693313 21.4933 0.937688 21.4933C1.04669 21.4933 1.15663 21.4743 1.26256 21.4351L5.64888 19.814C7.1395 20.5911 8.80725 20.9999 10.5001 20.9999C16.2897 20.9999 20.9999 16.2896 20.9999 10.5ZM15.3817 8.56913L10.6629 13.2879C10.4799 13.471 10.2399 13.5625 10 13.5625C9.76006 13.5625 9.52012 13.471 9.33712 13.2879L6.86837 10.8191C6.50225 10.453 6.50225 9.85944 6.86837 9.49331C7.23444 9.12719 7.82806 9.12719 8.19419 9.49331L10 11.2992L14.0559 7.24331C14.422 6.87719 15.0156 6.87725 15.3817 7.24331C15.7478 7.60944 15.7478 8.20306 15.3817 8.56913Z'
        fill='#F89828'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='32' height='32' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default SurveyBuilder

/* eslint-disable max-len */
import React from 'react'

const Dashboard = () => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.9091 0H3.63636C1.62806 0 0 1.62806 0 3.63636V6.54545C0 8.55376 1.62806 10.1818 3.63636 10.1818H10.9091C12.9174 10.1818 14.5455 8.55376 14.5455 6.54545V3.63636C14.5455 1.62806 12.9174 0 10.9091 0Z'
      fill='#F89828'
    />
    <path
      d='M28.3636 21.8181H21.0909C19.0826 21.8181 17.4545 23.4462 17.4545 25.4545V28.3636C17.4545 30.3719 19.0826 31.9999 21.0909 31.9999H28.3636C30.3719 31.9999 32 30.3719 32 28.3636V25.4545C32 23.4462 30.3719 21.8181 28.3636 21.8181Z'
      fill='#F89828'
    />
    <path
      d='M10.9091 11.6362H3.63636C1.62806 11.6362 0 13.2643 0 15.2726V28.3635C0 30.3718 1.62806 31.9999 3.63636 31.9999H10.9091C12.9174 31.9999 14.5455 30.3718 14.5455 28.3635V15.2726C14.5455 13.2643 12.9174 11.6362 10.9091 11.6362Z'
      fill='#5DC744'
    />
    <path
      d='M28.3636 0H21.0909C19.0826 0 17.4545 1.62806 17.4545 3.63636V16.7273C17.4545 18.7356 19.0826 20.3636 21.0909 20.3636H28.3636C30.3719 20.3636 32 18.7356 32 16.7273V3.63636C32 1.62806 30.3719 0 28.3636 0Z'
      fill='#5DC744'
    />
  </svg>
)

export default Dashboard

import React, { useCallback, useEffect, useState } from 'react'

import useApi from '../../common/useApi'

// import './MultipleChoiceFilterFromApi.scss'
import MultipleChoiceFilter from './MultipleChoiceFilter'

const MultipleChoiceFilterFromApi = ( {
  setChoice,
  selected,
  placeholder,
  endpoint,
  labelFields,
  filters = {},
  refetchChoicesOnOpen = false,
  onAddChoice = ( val ) => {},
  onRemoveChoice = ( val ) => {},
  isCreatable = false,
  handleCreate = () => {},
} ) => {
  const { data, isSuccess, refetch } = useApi( 'getList', endpoint, {
    ...filters,
    limit: 9999,
  } )
  const [ choices, setChoices ] = useState( {} )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChoice = useCallback( setChoice, [] )

  useEffect( () => {
    if ( isSuccess ) {
      setChoices(
        data.results.reduce(
          ( choices, choice ) => ( {
            ...choices,
            [choice['uid']]: labelFields
              .map( ( field ) => choice[field] )
              .join( ' ' ),
          } ),
          {},
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isSuccess, data ] )

  const handleRefetchChoices = () => {
    if ( refetchChoicesOnOpen ) {
      refetch()
    }
  }

  return (
    <div onClick={ handleRefetchChoices }>
      {isSuccess && (
        <MultipleChoiceFilter
          setChoice={ handleChoice }
          selected={ selected }
          choices={ choices }
          placeholder={ placeholder }
          onAddChoice={ onAddChoice }
          onRemoveChoice={ onRemoveChoice }
          isCreatable={ isCreatable }
          handleCreate={ handleCreate }
        />
      )}
    </div>
  )
}

export default MultipleChoiceFilterFromApi

import React from 'react'

import { CheckIcon, CancelIcon } from '../../assets/icons'

import Icon from '../../components/Icon'

const BooleanFieldFormatter = ( state ) => {
  if ( state ) {
    return <Icon IconComponent={ CheckIcon } className='size-1 primary' />
  }
  return <Icon IconComponent={ CancelIcon } className='size-1 danger' />
}

export default BooleanFieldFormatter

import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'

import './Layout.scss'

import logo from '../assets/images/logo.svg'
import sidebarIcons from '../assets/icons/sidebar-icons'
import router, { masterClientRoutes } from '../common/router'
import PageTabs from '../components/PageTabs/PageTabs'
import UserMenu from './UserMenu'
import capitalize from '../utils/capitalize'
import AuthContext from '../common/auth'
import { masterClientSubdomain } from '../utils/constants'

const Layout = ( { children } ) => {
  const { userData } = useContext( AuthContext )

  const subdomain = window.location.host.split( '.' )[1]
    ? window.location.host.split( '.' )[0]
    : false

  const getIcon = ( link ) => {
    const icon = link
      .split( '-' )
      .map( ( chunk ) => capitalize( chunk ) )
      .join( '' )
    const Icon = sidebarIcons[icon]

    return <Icon />
  }

  return (
    <section className='layout-container'>
      <nav className='sidebar'>
        <div className='logo-container'>
          <img src={ logo } width='150' alt='logo'></img>
        </div>

        <hr className='divider spacing-4' />

        <div className='sidebar-nav-links-container'>
          {router
            .filter( ( routeEntry ) => routeEntry.showInMenu )
            .map( ( routeEntry ) => (
              <NavLink
                key={ routeEntry.link }
                activeClassName='active-link'
                to={ `/${ routeEntry.link }` }
                className='sidebar-nav-link'
              >
                <div className='content'>
                  {getIcon( routeEntry.link )}
                  <h5>{routeEntry.title}</h5>
                </div>
              </NavLink>
            ) )}

          {masterClientSubdomain === subdomain
            && userData?.is_client_admin
            && masterClientRoutes.map( ( routeEntry ) => (
              <NavLink
                key={ routeEntry.link }
                activeClassName='active-link'
                to={ `/${ routeEntry.link }` }
                className='sidebar-nav-link'
              >
                <div className='content'>
                  {getIcon( routeEntry.link )}
                  <h5>{routeEntry.title}</h5>
                </div>
              </NavLink>
            ) )}
        </div>

        <hr className='divider spacing-4' />

        <UserMenu />
      </nav>
      <main>
        <PageTabs />
        {children}
      </main>
    </section>
  )
}

export default Layout

/* eslint-disable sort-keys */
import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import './ConfirmModal.scss'

const ConfirmModal = ( {
  successCallback = () => {},
  message,
  subtext,
  customButtons = null,
  successButtonLabel = 'Confirm',
} ) => {
  confirmAlert( {
    customUI: ( { onClose } ) => (
      <div className='confirm-modal-container'>
        <h2>{message}</h2>
        {subtext && <h5>{subtext}</h5>}

        <div className='buttons'>
          <button
            className='primary'
            onClick={ () => {
              successCallback()
              onClose()
            } }
          >
            {successButtonLabel}
          </button>
          <button className='secondary' onClick={ onClose }>
            Cancel
          </button>
        </div>
      </div>
    ),
  } )
}

export default ConfirmModal

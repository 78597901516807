import React from 'react'
import { useRouteMatch } from 'react-router'

import './Reports.scss'

import CrudRouterSwitch from '../../common/CrudRouter/CrudRouter'
import List from './List'
import View from './View'
import { LeaderContextProvider } from './LeaderContext'

const Reports = () => {
  const { url } = useRouteMatch()

  const components = {
    list: List,
    view: View,
  }

  return (
    <LeaderContextProvider>
      <CrudRouterSwitch url={ url } components={ components } />
    </LeaderContextProvider>
  )
}

export default Reports

import React from 'react'
import classNames from 'classnames'

import './StatsTable.scss'

const StatsTable = ( { columnHeaders, rows, emphasisOnLastRow = false } ) => (
  <table className='stats-table'>
    <thead>
      <tr>
        {columnHeaders.map( ( el, index ) => (
          <th key={ el }>
            {0 < index && (
              <span
                className={ classNames( {
                  'label-color': true,
                  [`stat-color-${ index - 1 }`]: true,
                } ) }
              ></span>
            )}
            {el}
          </th>
        ) )}
      </tr>
    </thead>
    <tbody>
      {rows.map( ( row, index1 ) => (
        <tr
          key={ index1 }
          className={
            emphasisOnLastRow && index1 === rows.length - 1 ? 'emphasis' : ''
          }
        >
          {row.map( ( data, index2 ) => (
            <td key={ index1 + index2 }>{data}</td>
          ) )}
        </tr>
      ) )}
    </tbody>
  </table>
)

export default StatsTable

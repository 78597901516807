import React, { useState } from 'react'
import { useRouteMatch, useHistory } from 'react-router'

import './SurveyInviteRedirect.scss'

import logo from '../../assets/images/logo.svg'

const SurveyInviteRedirect = () => {
  const { url } = useRouteMatch()
  const history = useHistory()

  const [ redirectUrl, setRedirectUrl ] = useState( null )

  fetch( `/api${ url }`, {
    method: 'GET',
    redirect: 'follow',
  } )
    .then( ( response ) => {
      // HTTP 301 response
      const url = new URL( response.url )

      setRedirectUrl( url )
    } )
    .catch( function ( err ) {
      //eslint-disable-next-line
      console.info(`${err} url: ${url}`)
    } )

  if ( !!redirectUrl ) {
    history.push( {
      pathname: redirectUrl.pathname,
      state: { referrer: true },
    } )
    // return <Redirect to={ redirectUrl.pathname } />
  }

  return (
    <div className='survey-invite-redirect'>
      <img src={ logo } width='150px' alt='logo'></img>

      <h3>Redirecting to survey...</h3>
    </div>
  )
}

export default SurveyInviteRedirect

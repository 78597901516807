import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Route } from 'react-router-dom'

import AuthContext from '../../common/auth'
import Layout from '../../layout'
import Loader from '../Loader'

const PrivateRoute = ( props ) => {
  const history = useHistory()
  const {
    isLoggedIn,
    fetchingUserInfoLoading,
    fetchingUserInfoSuccess,
    userData,
  } = useContext( AuthContext )

  useEffect( () => {
    if ( !isLoggedIn() ) {
      history.push( '/login' )
    }
  } )

  // to not show the basic layout when the info API is getting called
  if ( !userData && ( fetchingUserInfoLoading || !fetchingUserInfoSuccess ) ) {
    return <Loader message='Fetching user info...' fullPage />
  }

  return (
    <Layout>
      <Route { ...props } />
    </Layout>
  )
}

export default PrivateRoute

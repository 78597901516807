import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import useApi from '../../../common/useApi/useApi'

const Form = ( {
  entries,
  method,
  endpoint,
  FormBody,
  onSuccess = ( responseData ) => {},
  onError = ( error ) => {},
} ) => {
  const formMethods = useForm( {
    defaultValues: entries,
    mode: 'onChange',
    shouldUnregister: false,
  } )

  const { handleSubmit, setError } = formMethods

  const { isLoading, isSuccess, mutate, data, isError, error } = useApi(
    method,
    endpoint,
  )

  useEffect( () => {
    if ( isError && null !== error ) {
      onError( error )
    }
    if ( error?.data ) {
      Object.entries( error?.data ).forEach( ( [ key, value ] ) => {
        if ( Array.isArray( value ) ) {
          setError( key, { message: value[0] } )
        } else {
          setError( key, value )
        }
      } )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ error, isError ] )

  useEffect( () => {
    if ( isSuccess ) {
      onSuccess( data )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isSuccess, data ] )

  const onSubmit = ( values ) => {
    mutate( values )
  }

  return (
    <FormProvider { ...formMethods }>
      <form onSubmit={ handleSubmit( onSubmit ) }>
        <FormBody entries={ entries } isLoading={ isLoading } />
      </form>
    </FormProvider>
  )
}

export default Form

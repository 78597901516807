import React from 'react'
import classNames from 'classnames'

import './TablePagination.scss'

import {
  NextArrowIcon as NextIcon,
  PreviousArrowIcon as PreviousIcon,
  FirstPageIcon,
  LastPageIcon,
} from '../../assets/icons'

import Icon from '../Icon'

const PAGE_LIMIT = 10

const TablePagination = ( {
  total,
  hasNext,
  hasPrevious,
  nextPage,
  previousPage,
  activePage,
  changePage,
} ) => (
  <div className='table-pagination-container'>
    <p>
      {activePage * PAGE_LIMIT - PAGE_LIMIT + 1} -{' '}
      {Math.min( activePage * PAGE_LIMIT, total )} of {total}
    </p>

    <div className='spacer' />

    <button
      className='tertiary'
      onClick={ () => changePage( 1 ) }
      disabled={ !hasPrevious }
    >
      <Icon IconComponent={ FirstPageIcon } className='accent size-1' />
    </button>

    <button className='tertiary' disabled={ !hasPrevious } onClick={ previousPage }>
      <Icon IconComponent={ PreviousIcon } className='accent size-1' />
    </button>

    <div
      className={ classNames( {
        'more-than-10': 10 < Math.ceil( total / PAGE_LIMIT ),
        'page-numbers': true,
      } ) }
    >
      {Array.from(
        { length: Math.ceil( total / PAGE_LIMIT ) },
        ( _, i ) => i + 1,
      ).map( ( pageNumber ) => (
        <div
          key={ `pagination-${ pageNumber }` }
          className={ classNames( {
            active: activePage === pageNumber,
            'page-button': true,
          } ) }
          onClick={ () => changePage( pageNumber ) }
        >
          {pageNumber}
        </div>
      ) )}
    </div>

    <button className='tertiary' disabled={ !hasNext } onClick={ nextPage }>
      <Icon IconComponent={ NextIcon } className='accent size-1' />
    </button>

    <button
      className='tertiary'
      onClick={ () => changePage( Math.ceil( total / PAGE_LIMIT ) ) }
      disabled={ !hasNext }
    >
      <Icon IconComponent={ LastPageIcon } className='accent size-1' />
    </button>
  </div>
)

export default TablePagination

import React, { useState } from 'react'
import classNames from 'classnames'

import shareEmailSvg from '../../../../../assets/images/share-email.svg'

import './SelectOption.scss'

const SelectOption = ( { goToNext } ) => {
  const [ selectedBtn, setSelectedBtn ] = useState( 'ShareEmail' )

  return (
    <div className='select-option'>
      <h2>Share Survey</h2>

      <p className='subtle'>Please select an option to share a survey</p>

      <div className='row'>
        <div
          className={ classNames( {
            active: 'ShareEmail' === selectedBtn,
            'block-button': true,
          } ) }
          onClick={ () => setSelectedBtn( 'ShareEmail' ) }
        >
          <img src={ shareEmailSvg } alt='share-email' />
          <h5>Send to Mail</h5>
        </div>
      </div>

      <button className='primary full-width' onClick={ goToNext }>
        Continue
      </button>
    </div>
  )
}

export default SelectOption

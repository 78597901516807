import React, { useContext, useState } from 'react'
import UiContext from '../../../common/UiContext'

import Checkbox from '../../../components/Checkbox/Checkbox'

const SurveyFilters = ( { apply, filters, resetFilters } ) => {
  const { filterModal: { closeFilterModal } } = useContext( UiContext )

  const [ isPublished, setisPublished ] = useState( filters?.is_published ?? null )
  const [ isCompleted, setIsCompleted ] = useState( filters?.is_completed ?? null )

  const handleClick = () => {
    apply( {
      ...( null !== isCompleted ? { is_completed: isCompleted } : {} ),
      ...( null !== isPublished ? { is_published: isPublished } : {} ),
    } )
    closeFilterModal()
  }

  const handleReset = () => {
    resetFilters()
    closeFilterModal()
  }

  return (
    <>
      <div className='filters'>
        <Checkbox
          name='isPublished'
          checked={ isPublished }
          onChange={ ( e ) => setisPublished( e.target.checked ) }
          label='Is Published'
        />

        <Checkbox
          name='isCompleted'
          checked={ isCompleted }
          onChange={ ( e ) => setIsCompleted( e.target.checked ) }
          label='Is Completed'
        />
      </div>

      <div className='filter-modal-buttons'>
        <button className='secondary full-width' onClick={ handleReset }>
          Reset Filters
        </button>
        <button className='primary full-width' onClick={ handleClick }>
          Apply Filters
        </button>
      </div>
    </>
  )
}

export default SurveyFilters

import React from 'react'

import './Matrix.scss'

import MatrixRow from './MatrixRow'

const Matrix = ( {
  question,
  isPreview,
  survey_response,
  handleAnswerSubmitted,
} ) => (
  <div className='overflow-table'>
    <table className='matrix-table'>
      <thead>
        <tr>
          <th className='matrix-labels'></th>
          <th>Strongly Disagree</th>
          <th>Disagree</th>
          <th>Neither Agree nor Disagree</th>
          <th>Agree</th>
          <th>Strongly Agree</th>
        </tr>
      </thead>

      <tbody>
        {question.matrix_row.map( ( m ) => (
          <tr key={ m.uid } id={ m.uid }>
            <td>{m.title}</td>
            <MatrixRow
              matrixUid={ m.uid }
              questionUid={ question.uid }
              survey_response={ survey_response }
              isPreview={ isPreview }
              handleAnswerSubmitted={ handleAnswerSubmitted }
            />
          </tr>
        ) )}
      </tbody>
    </table>
  </div>
)

export default Matrix

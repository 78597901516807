import React, { useContext, useState } from 'react'

import UiContext from '../../../common/UiContext'
import FormLabel from '../../../components/Forms/Helpers/FormLabel/FormLabel'
import MultipleChoiceFilterFromApi from '../../../components/MultipleChoiceFilter/MultipleChoiceFilterFromApi'

const SegmentFilters = ( { apply, filters, resetFilters } ) => {
  const { filterModal: { closeFilterModal } } = useContext( UiContext )

  const [ selectedSectors, setSelectedSectors ] = useState(
    filters.sectors__uid__in ?? '',
  )

  const handleClick = () => {
    apply( { ...( '' !== selectedSectors ? { sectors__uid__in: selectedSectors } : {} ) } )
    closeFilterModal()
  }

  const handleReset = () => {
    resetFilters()
    closeFilterModal()
  }

  return (
    <>
      <div className='filters'>
        <FormLabel name='sectors' field='Choose Sectors'>
          <MultipleChoiceFilterFromApi
            endpoint='/api/respondent/segment/sector/'
            selected={ selectedSectors }
            setChoice={ ( val ) => setSelectedSectors( val ) }
            placeholder='Segment Sectors'
            labelFields={ [ 'name' ] }
          />
        </FormLabel>
      </div>

      <div className='filter-modal-buttons'>
        <button className='secondary full-width' onClick={ handleReset }>
          Reset Filters
        </button>
        <button className='primary full-width' onClick={ handleClick }>
          Apply Filters
        </button>
      </div>
    </>
  )
}

export default SegmentFilters
